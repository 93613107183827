import { useSelector } from "react-redux";

import { Body1, H1 } from "components/typography";

import { getHomePageUrl } from "utils";

import { AuthWrapper } from "components/pageLayout/displays/AuthWrapper";
import AuthLayout from "layouts/AuthLayout";
import { RouterLink } from "components/links";
import { Box } from "@mui/material";

const Page404 = () => {
  const account = useSelector((state) => state.account);

  return (
    <AuthLayout>
      <AuthWrapper titleTag="notFound.title">
        <H1 content="notFound.subtitle" variant="h5" align="center" mb={1} />
        <Body1 content="notFound.description" align="center" mb={4} />
        <Box display="flex" justifyContent="center">
          <RouterLink to={getHomePageUrl(account)} variant="contained" content="notFound.goToHome" />
        </Box>
      </AuthWrapper>
    </AuthLayout>
  );
};

export default Page404;
