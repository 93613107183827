import React, { useReducer } from "react";
import { dispatchErrorPopup, dispatchPopup } from "redux/actions/popupActions";

type Options = {
  maxSize: number;
  name: string;
};

enum ActionKind {
  LOAD,
  FILE_UPLOADED,
  DELETE,
}

type UploadedFile = { file: File; url: string };

type Action =
  | { type: ActionKind.LOAD; payload: UploadedFile }
  | { type: ActionKind.FILE_UPLOADED; payload: UploadedFile }
  | { type: ActionKind.DELETE };

interface State {
  file: UploadedFile | null;
  loading: boolean;
}

const FILE_MAX_SIZE = 1;
const FILE_NAME = "photo";

const initialState: State = {
  file: null,
  loading: false,
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionKind.LOAD:
      return {
        ...state,
        file: action.payload,
        loading: true,
      };
    case ActionKind.FILE_UPLOADED:
      return {
        ...state,
        file: action.payload,
        loading: false,
      };
    case ActionKind.DELETE:
      return initialState;
    default:
      return state;
  }
};

export const useImageUploading = ({
  onUpload,
  onUploadSuccess,
  options = { maxSize: FILE_MAX_SIZE, name: FILE_NAME },
}: {
  onUploadSuccess: (url: string) => void;
  onUpload: (formData: FormData) => Promise<string>;
  options?: Options;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const mbLimit = options.maxSize ?? FILE_MAX_SIZE;
  const name = options.name ?? FILE_NAME;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0];
    if (!file) return;

    if (file.size > 1024 * 1024 * mbLimit) {
      dispatchPopup("error", {
        tag: "errorsList.FILE_TOO_LARGE",
        additionalData: { mbLimit },
      });
      return;
    }

    const url = URL.createObjectURL(file);
    dispatch({ type: ActionKind.LOAD, payload: { file, url } });

    const formData = new FormData();
    formData.append(name, file);

    onUpload(formData)
      .then((imageUrl: string) => {
        dispatch({ type: ActionKind.FILE_UPLOADED, payload: { file, url: imageUrl } });
        onUploadSuccess(imageUrl);
      })
      .catch((error) => {
        dispatch({ type: ActionKind.DELETE });
        dispatchErrorPopup(error);
      })
      .finally(() => {
        URL.revokeObjectURL(url);
      });
  };

  return {
    isLoading: state.loading,
    file: state.file,
    onChange,
  };
};
