import BaseService from "services/baseService";
import FetchService from "services/fetchService";
import {
  GrowEvidence,
  GrowEvidenceStatus,
  Nullable,
  OffsetBasedListResponse,
  PaginatedQueryParams,
} from "services/types";

class GrowEvidencesService extends BaseService {
  servicePath = "grow";

  getGrowEvidence({ evidenceId }: { evidenceId: number }) {
    return FetchService.get<GrowEvidence>({ url: this.getUrl("evidences", evidenceId) });
  }

  getGrowEvidences({
    schoolId,
    limit = 500,
    filter,
    page = 1,
    sort = "title,asc",
  }: PaginatedQueryParams & { schoolId: number }) {
    return FetchService.get<OffsetBasedListResponse<GrowEvidence>>({
      url: this.getUrl("schools", schoolId, "evidences"),
      data: { limit, filter, page, sort },
    });
  }

  createGrowSchoolEvidence(
    schoolId: number,
    { title, description, fileUrl }: { title: string; description?: Nullable<string>; fileUrl: string }
  ) {
    return FetchService.post<true>({
      url: this.getUrl("schools", schoolId, "evidences"),
      data: { title, description, fileUrl },
    });
  }

  updateGrowEvidence(
    id: number,
    { title, description, fileUrl }: { title?: string; description?: Nullable<string>; fileUrl?: string }
  ) {
    return FetchService.put<true>({
      url: this.getUrl("evidences", id),
      data: {
        title,
        description,
        fileUrl,
      },
    });
  }

  deleteGrowEvidence(evidenceId: number) {
    return FetchService.delete<true>({ url: this.getUrl("evidences", evidenceId) });
  }

  linkEvidenceWithCredits(evidenceId: number, credits: { id: number }[]) {
    return FetchService.post<true>({
      url: this.getUrl("evidences", evidenceId, "credits", "bulk"),
      data: { credits },
    });
  }

  linkEvidencesWithCredit({
    schoolId,
    creditId,
    evidenceIds,
  }: {
    schoolId: number;
    creditId: number;
    evidenceIds: number[];
  }) {
    return FetchService.post({
      url: this.getUrl("schools", schoolId, "evidences", "credits", creditId),
      data: { evidenceIds },
    });
  }

  unlinkCreditsFromEvidence({ evidenceId, credits }: { evidenceId: number; credits: { id: number }[] }) {
    return FetchService.delete<true>({
      url: this.getUrl("evidences", evidenceId, "credits", "bulk"),
      data: { credits },
    });
  }

  uploadEvidence(schoolId: number, file: FormData) {
    return FetchService.post<string>({
      url: this.getUrl("schools", schoolId, "evidences", "file"),
      data: file,
    });
  }

  updateStatus({ evidenceId, status, comment }: { evidenceId: number; status: GrowEvidenceStatus; comment?: string }) {
    return FetchService.put<true>({
      url: this.getUrl("evidences", evidenceId, "status"),
      data: { status, comment },
    });
  }

  getEvidenceList({ page, limit, sort, filter }: PaginatedQueryParams) {
    return FetchService.get<OffsetBasedListResponse<GrowEvidence>>({
      url: this.getUrl("evidences"),
      data: { page, limit, sort, filter },
    });
  }
}

export default new GrowEvidencesService();
