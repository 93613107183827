import { Fragment } from "react";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import Translate from "react-translate-component";
import { handleFieldData } from "components/form/actions";
import { Body2 } from "../../typography";

export const RadioList = (props) => {
  const fieldData = handleFieldData(props);
  const { label, name, options = [], value, handleChanges, disabled, marginDisabled } = fieldData;

  return (
    <FormControl component="fieldset" margin={marginDisabled ? "none" : "normal"} disabled={disabled}>
      {label !== null ? (
        <FormLabel component="legend">{label || <Translate content={`formLabels.${name}`} />}</FormLabel>
      ) : null}
      <RadioGroup aria-label={name} name={name} value={value} onChange={handleChanges}>
        {options.map((option) => (
          <Fragment key={option.value}>
            <FormControlLabel value={option.value} control={<Radio />} label={option.label} />
            {option.helperText && <Body2 mt={-1} color="textSecondary" content={option.helperText} />}
          </Fragment>
        ))}
      </RadioGroup>
    </FormControl>
  );
};
