import { StatusCircle } from "components/statuses/StatusCircle";
import { UserStatus } from "services/types";

export const UserStatusDisplay = ({ status, size }: { status: UserStatus; size?: "small" | "default" }) => {
  switch (status) {
    case UserStatus.INVITED:
      return <StatusCircle label={`userStatus.${status}`} color="primary.main" size={size} />;
    case UserStatus.CREATED:
      return <StatusCircle label={`userStatus.${status}`} color="success.main" size={size} empty />;
    case UserStatus.ACTIVE:
      return <StatusCircle label={`userStatus.${status}`} color="success.main" size={size} />;
    case UserStatus.INVITATION_ARCHIVED:
    case UserStatus.INVITATION_EXPIRED:
    case UserStatus.ACCOUNT_ARCHIVED:
    case UserStatus.ACCOUNT_EXPIRED:
      return <StatusCircle label={`userStatus.${status}`} color="neutral.main" size={size} />;
    default:
      if (process.env.NODE_ENV === "development") {
        console.error("Invalid user status: ", status);
      }
      return null;
  }
};
