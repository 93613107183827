import { useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";

import { closeModal } from "redux/actions/modalActions";
import { StyledDialog } from "../styles";

export const ModalWindow = () => {
  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const modal = useSelector((state) => state.modal);
  const { open, content, props } = modal;

  return (
    <StyledDialog
      onClose={closeModal}
      open={Boolean(open)}
      scroll="body"
      fullScreen={fullScreen}
      {...props}
      // aria-modal="true"
    >
      {content}
    </StyledDialog>
  );
};
