import Translate from "react-translate-component";

import { Box, Divider } from "@mui/material";
import { UserErrorCard } from "features/User/components/UserErrorCard";
import { ApiErrorData, ValidationErrorData } from "services/formError";
import { User } from "services/types";
import { PropertyPath } from "lodash";

export const mapApiError = (
  error: ApiErrorData
): { fieldName: PropertyPath; tag: string; data?: { details?: string; [key: string]: unknown } } => {
  switch (error.error) {
    case "VALIDATION_ERROR":
      const { field: fieldName, details } = error.details as ValidationErrorData;
      return { fieldName, tag: error.error, data: { details } };
    case "LABELS_DUPLICATION":
      return { fieldName: "name", tag: error.error };
    case "INVITE_NOT_ACCEPTED":
      return { fieldName: "email", tag: error.error };
    case "EMAIL_NOT_FOUND":
      return { fieldName: "email", tag: error.error };
    case "INVITE_CODE_EXPIRED":
      return { fieldName: "email", tag: error.error };
    case "INVITE_ARCHIVED":
      return { fieldName: "email", tag: error.error };
    case "INVITE_NOT_SENT":
      return { fieldName: "email", tag: error.error };
    case "INCORRECT_PASSWORD":
      return { fieldName: "password", tag: error.error };
    case "ACCOUNT_ARCHIVED":
      return { fieldName: "password", tag: error.error };
    case "ACCOUNT_EXPIRED":
      return { fieldName: "password", tag: error.error };
    case "ACCOUNT_SUSPENDED":
      return { fieldName: "password", tag: error.error };
    case "CREDIT_AREA_DUPLICATION":
      return { fieldName: "title", tag: error.error };
    case "EMAIL_ALREADY_EXISTS":
      return { fieldName: "email", tag: error.error };
    case "USER_ALREADY_EXISTS": {
      const { user } = error.details as { user: User };

      return {
        fieldName: "response",
        tag: error.error,
        data: {
          user: (
            <Box mt={4}>
              <Divider />
              <Box my={6}>
                <UserErrorCard user={user} />
              </Box>
              <Divider />
            </Box>
          ),
        },
      };
    }
    case "OLD_PASSWORD": {
      const { changedDaysAgo } = error.details as { changedDaysAgo: number };

      let descTag: string;
      switch (changedDaysAgo) {
        case 0:
          descTag = "today";
          break;
        case 1:
          descTag = "yesterday";
          break;
        default:
          descTag = "longAgo";
      }

      return {
        fieldName: "password",
        tag: error.error,
        data: { day: <Translate content={`day.${descTag}`} with={{ days: changedDaysAgo }} /> },
      };
    }

    default: {
      return { fieldName: "response", tag: error.error };
    }
  }
};
