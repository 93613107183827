import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import nudgesService from "services/nudgesService";
import learnerService from "services/learnerService";
import { useAsync } from "hooks";
import { useSelector } from "react-redux";
import { Body1, H1 } from "components/typography";
import { TooltipI18n } from "components/TooltipI18n";
import { InfoIcon } from "svg";
import Portal from "@mui/material/Portal";
import { StyledCollegeNudgePopup } from "./styles";
import { CollegeNudgePopupTable } from "./CollegeNudgePopupTable";
import { CollegeNudgeActions } from "./CollegeNudgeActions";

export const CollegeNudgePopup = () => {
  const { id: learnerId } = useSelector((state) => state.account);

  const [isOpen, setIsOpen] = useState(true);

  const { data: nudges, run: fetchNudges } = useAsync({ sleep: 2000 });
  const { data: colleges, run: fetchColleges, setData: mutateColleges, isLoading, isError } = useAsync();

  const activeNudgeId = nudges?.items[0]?.id;

  useEffect(() => {
    fetchNudges(nudgesService.getLearnerCollegeNudges(learnerId));
  }, [fetchNudges, learnerId]);

  useEffect(() => {
    if (activeNudgeId) {
      fetchColleges(
        learnerService.getLearnerColleges({ userId: learnerId }).then(async (response) => {
          await nudgesService.nudgeShown(learnerId, activeNudgeId);
          return response;
        })
      );
    }
  }, [activeNudgeId, fetchColleges, learnerId]);

  const handleCollegeChange = ({ id, ...changes }) => {
    const newColleges = colleges.map((college) => (college.id === id ? { ...college, ...changes } : college));
    mutateColleges(newColleges);
  };

  const closeReminder = () => setIsOpen(false);

  if (!isOpen || isLoading || isError) {
    return null;
  }

  const infoIcon = (
    <TooltipI18n content="learnerCollegeNudge.tooltip" wrap placement="top">
      <InfoIcon />
    </TooltipI18n>
  );

  return (
    <Portal>
      <StyledCollegeNudgePopup>
        <H1 content="learnerCollegeNudge.title" align="center" color="common.white" mb={6} endIcon={infoIcon} />
        <Box mb={6}>
          {colleges?.length > 0 ? (
            <Box overflow="hidden">
              <CollegeNudgePopupTable items={colleges} onChange={handleCollegeChange} />
            </Box>
          ) : (
            <Body1 color="common.white" py={4} content="learnerCollegeNudge.emptyCollegeList" />
          )}
        </Box>

        <CollegeNudgeActions onSuccess={closeReminder} nudgeId={activeNudgeId} colleges={colleges} />
      </StyledCollegeNudgePopup>
    </Portal>
  );
};
