import { paths, publicRoutes, useUserRoutes } from "routes";
import React from "react";
import { useSelector } from "react-redux";
import { generatePath, Navigate, Route, Routes } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { saveDataToLocalStorage } from "utils";
import { setModal } from "redux/actions/modalActions";
import { IdleTimeout } from "components/modal/displays/IdleTimeout";
import { FullPageSpinner } from "components/pageLoading/FullPageSpinner";
import Page404 from "screens/Page404";
import DashboardLayout from "layouts/DashboardLayout";
import { schoolStaff, UserRole } from "services/types";

export const AppDisplay = () => {
  const { id, role, schoolId } = useSelector((state) => state.account);
  const isLoggedIn = !!id;
  const privateRoutes = useUserRoutes();

  const handleOnIdle = () => {
    if (!isLoggedIn) return;
    const { pathname, search } = window.location;
    if (!pathname.includes("auth")) {
      saveDataToLocalStorage("redirectUrl", "pathname", pathname + search);
    }
    setModal(<IdleTimeout />);
  };

  useIdleTimer({ timeout: 1000 * 60 * 30, onIdle: handleOnIdle, debounce: 250 });

  return (
    <>
      <Routes>
        {[...publicRoutes, ...privateRoutes].map(
          ({ path, exact = true, component: Component, layout: Layout = DashboardLayout }) => (
            <Route
              key={path}
              // check if nested routes are allowed
              path={exact ? path : `${path}/*`}
              element={
                <Layout>
                  <React.Suspense fallback={<FullPageSpinner />}>
                    <Component />
                  </React.Suspense>
                </Layout>
              }
            />
          )
        )}

        {schoolStaff.includes(role) && (
          // Redirect school admins and educators to school profile
          <Route path="/" element={<Navigate replace to={generatePath(paths.SCHOOL, { schoolId })} />} />
        )}
        {role === UserRole.ROLE_LEARNER && (
          // Redirect learner to his profile from root
          <Route
            path="/"
            element={<Navigate replace to={generatePath(paths.LEARNER_PROFILE, { schoolId, userId: id })} />}
          />
        )}
        <Route path="*" element={isLoggedIn ? <Page404 /> : <Navigate replace to={paths.SIGN_IN} />} />
      </Routes>
    </>
  );
};
