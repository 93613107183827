import { styled } from "@mui/material/styles";
import { Avatar, AvatarProps, Box } from "@mui/material";
import { setTransition } from "components/styledComponents/actions";

export type StyledAvatarProps = AvatarProps & {
  width?: number;
  size?: "large" | number;
  height?: number;
  bgColor?: string;
  iconsize?: number;
};

export const StyledAvatar = styled(Avatar)<StyledAvatarProps>`
  position: relative;
  width: ${(props) => (props.width ? `${props.width}px` : props.size ? `${props.size}px` : "40px")};
  height: ${(props) => (props.height ? `${props.height}px` : props.size ? `${props.size}px` : "40px")};
  overflow: hidden;
  background-color: ${(props) => (props.bgColor ? props.bgColor : props.theme.palette.grey[300])};
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 14px;
  &.large {
    width: 130px;
    height: 130px;
    &.MuiAvatar-root {
      font-size: 42px;
    }
  }
  img {
    object-fit: cover;
  }
  svg {
    width: ${({ iconsize }) => (iconsize ? `${iconsize}px` : "80%")};
    height: ${({ iconsize }) => (iconsize ? `${iconsize}px` : "80%")};
  }
  &.clickable {
    transition: ${setTransition("short")};
    &:hover {
      filter: brightness(90%);
    }
  }
`;

export const StyledAvatarGroup = styled(Box)`
  display: flex;
  .MuiAvatar-root:last-child {
    background-color: ${(props) => props.theme.palette.grey[300]};
    color: ${({ theme }) => theme.palette.text.secondary};
  }
  .MuiAvatar-root {
    margin-left: -8px;
    border: 2px solid #fff;
    font-size: 14px;
  }
`;
