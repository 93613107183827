import { memo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import clsx from "clsx";
import { generatePath, Link, matchPath, useLocation, useParams } from "react-router-dom";
import counterpart from "counterpart";

import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

import { Box, IconButton, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import { SideNavigationWrapper, SideNavTooltip } from "./styles";
import { MenuButtonIcon } from "svg";
import { getSideNavLinksByRole } from "./sideNavConfig";
import { SchoolSendingType } from "services/types";
import ExternalLink from "./ExternalLink";

const getActiveLink = (links, pathname) => {
  const linksSortedByNesting = _.sortBy(links, [({ path }) => path.split("/").length]);
  const bestMatch = _.findLast(linksSortedByNesting, ({ path }) => matchPath({ end: false, path }, pathname));
  return bestMatch?.path;
};

const MenuList = memo(function MenuList({
  links,
  open,
  isFlyout,
  subheader,
  onClose,
  linkParams,
  activeLink,
  MenuProps,
  ListItemButtonProps,
}) {
  const renderListItem = (link, to, label, isCategoryActive) => {
    if (link.target === "_blank") {
      return (
        <ExternalLink
          href={to}
          icon={!isFlyout && link.icon}
          label={label}
          isSelected={open ? activeLink === link.path : isCategoryActive}
          onClose={onClose}
          {...ListItemButtonProps}
        />
      );
    }

    return (
      <ListItemButton
        to={to}
        component={Link}
        selected={open ? activeLink === link.path : isCategoryActive}
        disableRipple
        onClick={onClose}
        {...ListItemButtonProps}
      >
        {!isFlyout && <ListItemIcon>{link.icon}</ListItemIcon>}
        <ListItemText primary={label} />
      </ListItemButton>
    );
  };

  return (
    <List {...MenuProps}>
      {subheader && <ListSubheader>{subheader}</ListSubheader>}
      {links.map((link) => {
        const to = generatePath(link.path, linkParams);

        const hasSubmenu = !_.isEmpty(link.children);
        const isCategoryActive = [link, ...(link.children || [])].some((link) => link.path === activeLink);

        const label = counterpart.translate(link.tag);

        const listItem = renderListItem(link, to, label, isCategoryActive);

        const isFlyoutActive = hasSubmenu && !open;

        const baseMenuProps = { linkParams, activeLink, links: link.children, onClose };

        return (
          <li key={link.tag}>
            {!open && !isFlyout ? (
              <SideNavTooltip
                key={isFlyoutActive ? _.uniqueId() : undefined}
                title={
                  isFlyoutActive ? (
                    <MenuList
                      {...baseMenuProps}
                      MenuProps={{ disablePadding: true, dense: true }}
                      isFlyout
                      subheader={label}
                    />
                  ) : (
                    label
                  )
                }
                className={isFlyoutActive ? "flyout" : ""}
                placement={isFlyoutActive ? "left-start" : "left"}
              >
                {listItem}
              </SideNavTooltip>
            ) : (
              listItem
            )}
            {hasSubmenu && open && (
              <MenuList
                {...baseMenuProps}
                open
                MenuProps={{ disablePadding: true }}
                ListItemButtonProps={{ sx: { pl: 12 } }}
              />
            )}
          </li>
        );
      })}
    </List>
  );
});

export const SideNavigation = memo(function LeftSidebar() {
  const location = useLocation();
  const params = useParams();

  const currentUser = useSelector((state) => state.account);

  const isLearningRecordIssuer = currentUser.sendingType === SchoolSendingType.LEARNING_RECORD;

  const [open, setOpen] = useState(false);

  const toggleSidebar = () => setOpen(!open);

  const handleClose = useCallback(() => setOpen(false), []);

  const links = getSideNavLinksByRole(currentUser.role, isLearningRecordIssuer);

  const linkParams = {
    userId: currentUser.id,
    schoolId: currentUser.schoolId,
    ...params,
  };

  const activeLink = getActiveLink(
    links.flatMap((link) => [link, ...(link.children || [])]),
    location.pathname
  );

  return (
    <SideNavigationWrapper className={clsx({ open })}>
      <div className="sidebar">
        <div className="drawer-icon">
          <IconButton onClick={toggleSidebar} aria-label={counterpart.translate("buttons.openDrawer")} size="large">
            <MenuButtonIcon className="menu-icon" fontSize="small" />
          </IconButton>
        </div>
        <SimpleBar className="menu">
          <MenuList links={links} onClose={handleClose} open={open} activeLink={activeLink} linkParams={linkParams} />
        </SimpleBar>
      </div>
      <Box className="overlay" onClick={handleClose} />
    </SideNavigationWrapper>
  );
});
