import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import growService from "services/growService";
import { dispatchErrorPopup, dispatchPopup } from "../actions/popupActions";
import communityService from "services/communityService";
import growFileService from "services/growFileService";
import { STATE_KEY, mediaFilesReceived, mediaFilesRemoved } from "redux/slices/mediaFilesActions";

export const linkGrowCredits = createAsyncThunk(`${STATE_KEY}/linkGrowCredits`, async ({ fileId, credits }) => {
  try {
    await communityService.linkGrowCredits({ fileId, data: { credits: credits.map(({ id }) => ({ id })) } });
  } catch (error) {
    dispatchErrorPopup(error);
  }
});

export const unlinkGrowCredit = createAsyncThunk(`${STATE_KEY}/unlinkGrowCredits`, async ({ fileId, creditId }) => {
  try {
    await growService.unlinkFileFromGrowCredit({ fileId, creditId });
  } catch (error) {
    dispatchErrorPopup(error);
  }
});

export const linkGrowCreditAreas = createAsyncThunk(`${STATE_KEY}/linkGrowCreditAreas`, async ({ fileId, areas }) => {
  try {
    await communityService.linkGrowCreditAreas({ fileId, data: { creditAreas: areas.map(({ id }) => ({ id })) } });
  } catch (error) {
    dispatchErrorPopup(error);
  }
});

export const unlinkGrowCreditArea = createAsyncThunk(
  `${STATE_KEY}/unlinkGrowCreditAreas`,
  async ({ fileId, areaId }) => {
    try {
      await growService.unlinkGrowCreditArea(fileId, areaId);
    } catch (error) {
      dispatchErrorPopup(error);
    }
  }
);

export const setFileAsFavourite = createAsyncThunk(`${STATE_KEY}/setFavourite`, async ({ fileId }) => {
  try {
    await communityService.favoriteFile(fileId);
  } catch (error) {
    dispatchErrorPopup(error);
  }
});

export const unsetFileAsFavourite = createAsyncThunk(`${STATE_KEY}/unsetFavourite`, async ({ fileId }) => {
  try {
    await communityService.unFavoriteFile(fileId);
  } catch (error) {
    dispatchErrorPopup(error);
  }
});

export const markAsGrowFile = createAsyncThunk(`${STATE_KEY}/markAsGrowFile`, async (fileId) => {
  try {
    await growFileService.markAsGrowFile(fileId);
  } catch (error) {
    dispatchPopup("error", { tag: `errorsList.${error.code}` });
  }
});

export const unmarkAsGrowFile = createAsyncThunk(`${STATE_KEY}/unmarkAsGrowFile`, async (fileId) => {
  try {
    await growFileService.unmarkAsGrowFile(fileId);
  } catch (error) {
    dispatchPopup("error", { tag: `errorsList.${error.code}` });
  }
});

export const updateMediaFile = createAsyncThunk(
  `${STATE_KEY}/updateMediaFile`,
  ({ id, changes }, { rejectWithValue }) => {
    try {
      return communityService.updateFile({ fileId: id, data: changes }).then(() => ({ id, changes }));
    } catch (e) {
      return rejectWithValue(e);
    }
  },
  { serializeError: (e) => e }
);

export const mediaFilesAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.position - b.position,
});

export const mediaFilesSlice = createSlice({
  name: STATE_KEY,
  initialState: mediaFilesAdapter.getInitialState({ count: {} }),
  reducers: {
    mediaFilesRepositioned: {
      reducer: mediaFilesAdapter.updateMany,
      prepare: (payload) => ({
        payload: payload.map(({ id, position }) => ({
          id,
          changes: { position },
        })),
      }),
    },
    commentAdded: (state, action) => {
      state.entities[action.payload.postId].comments.push(action.payload);
    },
    commentUpdated: (state, action) => {
      const { fileId, attachments, content, parentCommentId } = action.payload;
      const index = state.entities[fileId].comments.findIndex((comment) => comment.id === parentCommentId);
      state.entities[fileId].comments[index] = {
        ...state.entities[fileId].comments[index],
        attachments,
        content,
      };
    },
    commentDeleted: (state, action) => {
      const { fileId, commentId } = action.payload;
      state.entities[fileId].comments = state.entities[fileId].comments.filter((comment) => {
        return comment.id !== commentId;
      });
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(mediaFilesReceived, (state, action) => {
        const { items, count } = action.payload;
        if (items) {
          mediaFilesAdapter.setAll(state, items);
        }
        if (count) {
          state.count = count;
        }
      })
      .addCase(mediaFilesRemoved, mediaFilesAdapter.removeAll)
      .addCase(linkGrowCredits.fulfilled, (state, action) => {
        const { fileId, credits } = action.meta.arg;
        if (state.entities[fileId]) {
          const normalizedCredits = _.map(credits, (credit) =>
            _.pick(credit, ["id", "title", "position", "description", "archivedAt"])
          );
          state.entities[fileId].growCredits = _.unionBy(state.entities[fileId].growCredits, normalizedCredits, "id");
        }
      })
      .addCase(linkGrowCreditAreas.fulfilled, (state, action) => {
        const { fileId, areas } = action.meta.arg;
        if (state.entities[fileId]) {
          const normalizedAreas = _.map(areas, (area) => _.pick(area, ["id", "title", "position"]));
          state.entities[fileId].growCreditAreas = _.unionBy(
            state.entities[fileId].growCreditAreas,
            normalizedAreas,
            "id"
          );
        }
      })
      .addCase(unlinkGrowCredit.fulfilled, (state, action) => {
        const { fileId, creditId } = action.meta.arg;
        state.entities[fileId].growCredits = state.entities[fileId].growCredits.filter(({ id }) => {
          return id !== creditId;
        });
      })
      .addCase(unlinkGrowCreditArea.fulfilled, (state, action) => {
        const { fileId, areaId } = action.meta.arg;
        state.entities[fileId].growCreditAreas = state.entities[fileId].growCreditAreas.filter(({ id }) => {
          return id !== areaId;
        });
      })
      .addCase(markAsGrowFile.fulfilled, (state, action) => {
        state.entities[action.meta.arg].isGrowResource = true;
      })
      .addCase(unmarkAsGrowFile.fulfilled, (state, action) => {
        state.entities[action.meta.arg].isGrowResource = false;
      })
      .addCase(setFileAsFavourite.fulfilled, (state, action) => {
        const { fileId } = action.meta.arg;
        state.entities[fileId].isFavorite = true;
      })
      .addCase(unsetFileAsFavourite.fulfilled, (state, action) => {
        const { fileId } = action.meta.arg;
        state.entities[fileId].isFavorite = false;
      })
      .addCase(updateMediaFile.fulfilled, (state, update) => {
        mediaFilesAdapter.updateOne(state, update);
      }),
});

export const { mediaFilesRepositioned, commentDeleted, commentUpdated, commentAdded } = mediaFilesSlice.actions;

export const { selectAll: selectMediaFiles, selectById: selectMediaResourceById } = mediaFilesAdapter.getSelectors(
  (state) => state[STATE_KEY]
);

export const selectFileCount = (state) => state[STATE_KEY].count;

export default mediaFilesSlice.reducer;
