import { Box } from "@mui/material";
import { StyledAuth } from "components/pageLayout/styles";
import { H1 } from "components/typography";
import { Link } from "react-router-dom";
import paths from "routes/paths";
import { LogoIcon } from "svg";
import React from "react";

type AuthWrapperProps = {
  children: React.ReactNode;
  titleTag?: string;
};

export const AuthWrapper = ({ children, titleTag }: AuthWrapperProps) => (
  <StyledAuth>
    <Box width={{ xs: 150, md: 200 }} justifyContent="center" mt={2} mb={7} mx="auto">
      <Link to={paths.DASHBOARD}>
        <LogoIcon
          aria-label="Mastery Transcript Builder"
          sx={{ width: "100%", height: "100%", color: "common.white" }}
        />
      </Link>
    </Box>
    {titleTag && <H1 align="center" gutterBottom content={titleTag} />}
    {children}
  </StyledAuth>
);
