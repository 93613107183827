import * as types from "../constants";
import store from "../store";

export const showPopup = (popupType, content) => ({ type: types.SHOW_POPUP, payload: { popupType, content } });

export const hidePopup = () => ({ type: types.HIDE_POPUP });

export const dispatchPopup = (...rest) => {
  store.dispatch(hidePopup());
  store.dispatch(showPopup(...rest));
};

export const dispatchErrorPopup = (err) => {
  if (!err.response) {
    // ? For developers, it will work only if we have errors in code
    // eslint-disable-next-line no-console
    console.log(err);
    return;
  }

  const { error, details = {} } = err.response.data;
  dispatchPopup("error", {
    tag: `errorsList.${error}`,
    additionalData: details.details || details,
  });
};
