const MASTERY_ORG = "https://mastery.org";
const PRIVACY_POLICY = "https://mastery.org/privacy-policy/";
const RELEASE_NOTES = "https://mastery.org/release-notes/";
const CONNECT_WITH_US = "https://mastery.org/stay-connected/connect-with-us/";
const MEMBERSHIP = "https://mastery.org/what-we-do/membership/";

export const ExternalURLs = {
  PRIVACY_POLICY,
  RELEASE_NOTES,
  CONNECT_WITH_US,
  MASTERY_ORG,
  MEMBERSHIP,
};
