import "react-app-polyfill/stable";

import { AppProviders } from "AppProviders";
import App from "App";
import { initServices } from "config";
import { createRoot } from "react-dom/client";

initServices();

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <AppProviders>
    <App />
  </AppProviders>
);
