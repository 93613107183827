import _ from "lodash";
import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { selectCreditById } from "./creditsSlice";
import { MCARecieved, MCARemoved } from "./sharedMCAActions";

const creditLabelsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.creditsCount - a.creditsCount,
});

const initialState = creditLabelsAdapter.getInitialState({ error: null, sort: "creditsCount,desc" });

export const STATE_KEY = "creditLabels";

const creditLabelsSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    creditLabelsRecieved: creditLabelsAdapter.upsertMany,
    creditLabelAdded: {
      reducer: creditLabelsAdapter.addOne,
      // prepare: (label) => ({
      //   payload: {
      //     ..._.pick(label, ["id", "name", "color"]),
      //     icon: null,
      //     creditCount: 0,
      //   },
      // }),
    },
    creditLabelUpdated: {
      reducer: creditLabelsAdapter.updateOne,
      prepare: ({ id, ...changes }) => ({
        payload: {
          id,
          changes: _.pick(changes, ["name", "color"]),
        },
      }),
    },
    creditLabelRemoved: creditLabelsAdapter.removeOne,
    creditLabelesSortedBy: (state, { payload: { field } }) => {
      const [prevOrderBy, prevDirection] = state.sort.split(",");
      let direction = "asc";
      if (field === prevOrderBy) {
        direction = prevDirection === "asc" ? "desc" : "asc";
      }
      state.ids = _.orderBy(state.entities, field, direction).map((label) => label.id);
      state.sort = `${field},${direction}`;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(MCARecieved, (state, action) => {
        const { creditLabels } = action.payload.entities;
        if (creditLabels) {
          creditLabelsAdapter.setAll(state, creditLabels);
        }
      })
      .addCase(MCARemoved, creditLabelsAdapter.removeAll),
});

export const { creditLabelsRecieved, creditLabelAdded, creditLabelUpdated, creditLabelRemoved, creditLabelesSortedBy } =
  creditLabelsSlice.actions;

export const { selectAll: selectCreditLabels, selectEntities: selectCreditLabelEntities } =
  creditLabelsAdapter.getSelectors((state) => state.creditLabels);

export const selectCreditLabelsByCreditId = (creditId) =>
  createSelector([selectCreditById(creditId), selectCreditLabelEntities], (credit, labels) =>
    credit?.labels?.map((id) => labels[id])
  );

export default creditLabelsSlice.reducer;
