import { styled } from "@mui/material/styles";

import { SupportPopover } from "components/popover/displays/SupportPopover";
import { Notification } from "components/Notification";

export const AuthRoot = styled("div")`
  position: relative;
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(/static/img/auth-bg/${(props) => props.bgImage}.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
`;

const AuthCard = styled("div")`
  width: 900px;
  max-width: 100%;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  z-index: 1;
`;

function CleverLayout({ children }) {
  const bgImage = Math.floor(Math.random() * 5) + 1;

  return (
    <AuthRoot bgImage={bgImage}>
      <AuthCard>{children}</AuthCard>
      <Notification />
      <SupportPopover />
    </AuthRoot>
  );
}

export default CleverLayout;
