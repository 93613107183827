import BaseService from "services/baseService";
import FetchService from "services/fetchService";
import { OffsetBasedListResponse, GrowCreditType, GrowArea, PositionsParams } from "services/types";

class GrowService extends BaseService {
  servicePath = "grow";

  getAreas() {
    return FetchService.get<OffsetBasedListResponse<GrowArea>>({ url: this.getUrl(), data: { page: 1, limit: 10 } });
  }

  createCreditArea({ title, description, position }: { title: string; description: string; position: number }) {
    return FetchService.post<number>({ url: this.getUrl("areas"), data: { title, description, position } });
  }

  updateCreditArea({
    id,
    title,
    description,
    fileIds,
  }: {
    id: number;
    title: string;
    description: string;
    fileIds: number[];
  }) {
    return FetchService.put<true>({ url: this.getUrl("areas", id), data: { title, description, fileIds } });
  }

  deleteCreditArea(id: number) {
    return FetchService.delete<true>({ url: this.getUrl("areas", id) });
  }

  updateCreditAreasPosition(data: PositionsParams) {
    return FetchService.put<true>({ url: this.getUrl("areas", "position"), data });
  }

  createCredit({
    id,
    title,
    description,
    type,
    position,
  }: {
    id: number;
    title: string;
    description: string;
    type: GrowCreditType;
    position: number;
  }) {
    return FetchService.post<number>({
      url: this.getUrl("areas", id, "credits"),
      data: { title, description, type, position },
    });
  }

  deleteCredit(id: number) {
    return FetchService.delete<true>({ url: this.getUrl("areas", "credits", id) });
  }

  updateCredit({
    id,
    title,
    description,
    type,
    position,
    fileIds,
  }: {
    id: number;
    title: string;
    description: string;
    type: GrowCreditType;
    position: number;
    fileIds: number[];
  }) {
    return FetchService.put<true>({
      url: this.getUrl("areas", "credits", id),
      data: { title, description, type, position, fileIds },
    });
  }

  updateCreditsPosition(data: PositionsParams) {
    return FetchService.put<true>({
      url: this.getUrl("areas", "credits", "position"),
      data,
    });
  }

  unlinkFileFromGrowCredit({ creditId, fileId }: { fileId: number; creditId: number }) {
    return FetchService.delete<true>({
      url: this.getUrl("areas", "credits", creditId, "files", fileId),
    });
  }

  unlinkGrowCreditArea(fileId: number, growCreditAreaId: number) {
    return FetchService.delete<true>({
      url: this.getUrl("areas", growCreditAreaId, "files", fileId),
    });
  }

  archiveGrowCreditArea({ growAreaId }: { growAreaId: number }) {
    return FetchService.put({
      url: this.getUrl("areas", growAreaId, "archive"),
    });
  }

  restoreGrowCreditArea({ growAreaId }: { growAreaId: number }) {
    return FetchService.put({
      url: this.getUrl("areas", growAreaId, "unarchive"),
    });
  }

  archiveGrowCredit({ growCreditId }: { growCreditId: number }) {
    return FetchService.put({
      url: this.getUrl("areas", "credits", growCreditId, "archive"),
    });
  }

  restoreGrowCredit({ growCreditId }: { growCreditId: number }) {
    return FetchService.put({
      url: this.getUrl("areas", "credits", growCreditId, "unarchive"),
    });
  }
}
export default new GrowService();
