import { createSelector, createSlice } from "@reduxjs/toolkit";
import { LearnerReports } from "services/progressReportService";
import { RootState } from "redux/store";

export const STATE_KEY = "progressReport";

const initialState: LearnerReports | null = null;

const progressReportSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    progressReportsReceived(state, action) {
      return { ...action.payload };
    },
    progressReportsRemoved() {
      return initialState;
    },
  },
});

export const { progressReportsReceived, progressReportsRemoved } = progressReportSlice.actions;

export const selectLearnerAndReports = () =>
  createSelector([(state: RootState) => state.progressReport], (progressReport) => {
    return progressReport;
  });

export default progressReportSlice.reducer;
