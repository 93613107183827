import { generatePath } from "react-router-dom";
import { useSelector } from "react-redux";
import Translate from "react-translate-component";

import { Box, Divider, Grid } from "@mui/material";
import { UserAvatar } from "components/Avatar/UserAvatar";
import {
  StretchedCard,
  StyledCardContent,
  DefaultActiveCard,
  StyledCardFooter,
} from "components/styledComponents/StyledCards";
import { CardList } from "components/card/CardList";
import { MailTo, RouterLink } from "components/links";
import { paths } from "routes";
import { UserStatusDisplay } from "features/User/components/UserStatusDisplay";
import { H2 } from "components/typography";
import { MTCAdminRoles, UserRole } from "services/types";

export const UserErrorCard = ({ user }) => {
  const currentUser = useSelector((state) => state.account);
  const { firstName, lastName, status, photoUrl, id, role, email } = user;
  const fullName = `${firstName} ${lastName}`;

  const content = [
    {
      name: "role",
      value: role,
      handleData: () => <Translate content={`userRoles.${role}`} />,
    },
    {
      name: "email",
      value: <MailTo email={email} />,
      tooltip: email,
    },
  ];

  const pathList = {
    [UserRole.ROLE_SUPER_ADMIN]: paths.MTC_TEAM_MEMBER_PROFILE,
    [UserRole.ROLE_SUPPORT_ADMIN]: paths.MTC_TEAM_MEMBER_PROFILE,
    [UserRole.ROLE_SCHOOL_ADMIN]: paths.SCHOOL_ADMIN_PROFILE,
    [UserRole.ROLE_EDUCATOR]: paths.EDUCATOR_PROFILE,
    [UserRole.ROLE_LEARNER]: paths.LEARNER_PROFILE,
  };

  const path = pathList[user.role];
  let linkToProfile = generatePath(path, { schoolId: user.schoolId, userId: user.id });
  if (currentUser.id === id) {
    linkToProfile = paths.USER_PROFILE;
  }

  let canViewUser = MTCAdminRoles.includes(currentUser.role);
  if (currentUser.role === UserRole.ROLE_SUPPORT_ADMIN && user.role === UserRole.ROLE_SUPER_ADMIN) {
    canViewUser = false;
  }

  return (
    <DefaultActiveCard stretched>
      <StretchedCard>
        <StyledCardContent>
          <Grid container spacing={2}>
            <Grid mr={3} justifyItems="center" item>
              <UserAvatar src={photoUrl} {...{ firstName, lastName }} />
            </Grid>

            <Grid item xs>
              <Box mb={2}>
                <H2 text={fullName} display="inline" mr={3} />
                <Box display="inline-flex">
                  <UserStatusDisplay status={status} size="small" />
                </Box>
              </Box>
              <CardList items={content} />
            </Grid>
          </Grid>
        </StyledCardContent>

        {canViewUser ? (
          <>
            <Divider />
            <StyledCardFooter disableSpacing>
              <RouterLink to={linkToProfile} content="buttons.viewContact" />
            </StyledCardFooter>
          </>
        ) : null}
      </StretchedCard>
    </DefaultActiveCard>
  );
};
