import { useState } from "react";
import _ from "lodash";
import counterpart from "counterpart";
import Translate from "react-translate-component";

import { CircularProgress, Autocomplete as AutocompleteComponent } from "@mui/material";

import { dispatchErrorPopup } from "redux/actions/popupActions";
import { getHelperText, getLabel, handleFieldData } from "../actions";
import { Field } from "components/styledComponents/StyledForms";

export const Autocomplete = (props) => {
  const {
    emptyText = "global.noMatches",
    inputCallback,
    optionLabel,
    targetField,
    tipsToExclude,
    renderOption,
    hideLabel,
    optionalLabel,
    placeholder,
    disablePortal,
    autoFocus = true,
    inputRef,
    variant = "outlined",
    disableUnderline = false,
    disabled,
    margin = "normal",
    callback = () => {},
  } = props;
  const optionalProps = { renderOption, disablePortal };
  const [showTips, setShowTips] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tips, setTips] = useState([]);
  const { name, value, handleChanges, error, required } = handleFieldData({ ...props });

  const handleInputChange = _.debounce((newValue) => {
    if (newValue !== null && newValue !== "") {
      setLoading(true);
      inputCallback(newValue)
        .then((data) => {
          // backend sends different objects, hence we need to find an array with items
          const keys = Object.keys(data);
          setLoading(false);
          keys.forEach((key) => {
            if (Array.isArray(data[key])) {
              setShowTips(true);
              setTips(data[key]);
            }
          });
        })
        .catch(dispatchErrorPopup);
    } else {
      handleChanges({ target: { value: null, name } });
      setTips([]);
    }
  }, 500);

  const getValue = () => {
    if (!tips.length && value) return value;
    if (!tips.length || !value) return null;
    if (!targetField) return value; // if its a plain value

    // if tips is an array of object, we need to find the object instead of passing object`s property
    return tips.find((tip) => tip[targetField] === value);
  };

  const getTips = () => {
    if (tipsToExclude) {
      return tips.filter((tip) => tipsToExclude.every((item) => tip.id !== item.id));
    }
    return tips;
  };

  return (
    <AutocompleteComponent
      onChange={(e, newValue) => {
        const val = newValue && targetField ? newValue[targetField] : newValue;
        handleChanges({ target: { value: val, name } });
        setTimeout(() => {
          // timeout here is a form fix. e.x. in inviteSchoolAdmin school selector
          callback(newValue);
        }, 0);
      }}
      options={getTips()}
      open={showTips}
      noOptionsText={<Translate content={emptyText} />}
      getOptionLabel={optionLabel}
      onClose={() => setShowTips(false)}
      onOpen={() => setShowTips(true)}
      value={getValue()}
      disabled={disabled}
      // isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(option) => option}
      loading={loading}
      renderInput={(params) => (
        <Field
          {...params}
          label={getLabel(name, optionalLabel, hideLabel)}
          placeholder={placeholder ? counterpart.translate(placeholder) : null}
          variant={variant}
          onChange={(e) => handleInputChange(e.target.value)}
          autoFocus={autoFocus}
          required={required}
          ref={inputRef}
          error={Boolean(error)}
          value={getValue()}
          helperText={getHelperText(error)}
          margin={margin}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
            ...(variant === "standard" && { disableUnderline }),
          }}
        />
      )}
      data-test-id={`${name}-search`}
      {...optionalProps}
    />
  );
};
