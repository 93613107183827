import _ from "lodash";
import * as types from "../constants";

const defaultTranscriptData = {
  data: {},
  count: {},
};

export default function reducer(state = defaultTranscriptData, { type, payload }) {
  switch (type) {
    case types.SET_TRANSCRIPT:
      return payload;

    case types.UPDATE_CREDITS: {
      const { credits, creditsNumber } = payload;

      const data = { ...state.data };
      const count = { ...state.count };

      data.credits = credits;
      count.credits = creditsNumber;

      return { data, count };
    }

    case types.UPDATE_TRANSCRIPT: {
      const { items, name } = payload;
      const data = { ...state.data };
      const count = { ...state.count };

      data[name] = items;
      count[name] = items.length;

      if (name === "credits") {
        const credits = items.map((ca) => ca.credits);
        const flattenedCredits = _.flatten(credits);
        count[name] = flattenedCredits.length;
      }

      return { data, count };
    }
    case types.REMOVE_TRANSCRIPT:
      return defaultTranscriptData;

    default:
      return state;
  }
}
