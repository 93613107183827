import { UserRole } from "services/types";
import paths from "routes/paths";
import {
  DashboardIcon,
  MenuAdminsIcon,
  MenuCollegesIcon,
  GroupIcon,
  MenuCoursesIcon,
  MenuCreditAreasIcon,
  MenuCreditsIcon,
  MenuEducatorIcon,
  MenuGrowIcon,
  MenuLearnerIcon,
  MenuLiveFeedIcon,
  MenuMcaIcon,
  MenuMediaLibraryIcon,
  MenuSchoolIcon,
  MenuSchoolsAdminsIcon,
  MenuSchoolEventIcon,
  ProgressAvatar,
} from "svg";

export const getSideNavLinksByRole = (role, isLearningRecordIssuer) => {
  const menuLinks = new Map([
    [
      [UserRole.ROLE_SUPER_ADMIN, UserRole.ROLE_TEAMMATE],
      [
        { path: paths.DASHBOARD, tag: "dashboard.title", icon: <DashboardIcon /> },
        { path: paths.MTC_TEAM, tag: "MTCTeam.title", icon: <MenuAdminsIcon /> },
        { path: paths.ALL_SCHOOL_ADMINS, tag: "schoolAdmins.title", icon: <MenuSchoolsAdminsIcon /> },
        { path: paths.SCHOOLS, tag: "memberSchools.title", icon: <MenuSchoolIcon /> },
        { path: paths.MCAs, tag: "mcaList.title", icon: <MenuMcaIcon /> },
        { path: paths.CREDIT_AREA_LIBRARY, tag: "creditAreaLibrary.title", icon: <MenuCreditAreasIcon /> },
        { path: paths.CREDIT_LIBRARY, tag: "creditLibrary.title", icon: <MenuCreditsIcon /> },
        { path: paths.COLLEGES, tag: "colleges.title", icon: <MenuCollegesIcon /> },
        { path: paths.COURSE_LIBRARY, tag: "courseLibrary.title", icon: <MenuCoursesIcon /> },
        { path: paths.MTC_GROW, tag: "mtcGrow.title", icon: <MenuGrowIcon /> },
        { path: paths.COMMUNITY_GROUPS, tag: "communityGroups.title", icon: <GroupIcon /> },
        { path: paths.LIVE_FEED, tag: "liveFeed.title", icon: <MenuLiveFeedIcon /> },
        { path: paths.MEDIA_LIBRARY, tag: "mediaLibrary.title", icon: <MenuMediaLibraryIcon /> },
      ],
    ],
    [
      [UserRole.ROLE_SUPPORT_ADMIN],
      [
        { path: paths.DASHBOARD, tag: "dashboard.title", icon: <DashboardIcon /> },
        { path: paths.ALL_SCHOOL_ADMINS, tag: "schoolAdmins.title", icon: <MenuSchoolsAdminsIcon /> },
        { path: paths.SCHOOLS, tag: "memberSchools.title", icon: <MenuSchoolIcon /> },
        { path: paths.MCAs, tag: "mcaList.title", icon: <MenuMcaIcon /> },
        { path: paths.CREDIT_AREA_LIBRARY, tag: "creditAreaLibrary.title", icon: <MenuCreditAreasIcon /> },
        { path: paths.CREDIT_LIBRARY, tag: "creditLibrary.title", icon: <MenuCreditsIcon /> },
        { path: paths.COLLEGES, tag: "colleges.title", icon: <MenuCollegesIcon /> },
        { path: paths.COURSE_LIBRARY, tag: "courseLibrary.title", icon: <MenuCoursesIcon /> },
        { path: paths.MTC_GROW, tag: "mtcGrow.title", icon: <MenuGrowIcon /> },
        { path: paths.COMMUNITY_GROUPS, tag: "communityGroups.title", icon: <GroupIcon /> },
        { path: paths.LIVE_FEED, tag: "liveFeed.title", icon: <MenuLiveFeedIcon /> },
        { path: paths.MEDIA_LIBRARY, tag: "mediaLibrary.title", icon: <MenuMediaLibraryIcon /> },
      ],
    ],
    [
      [UserRole.ROLE_SCHOOL_ADMIN],
      [
        {
          path: paths.SCHOOL,
          tag: "mySchool.title",
          icon: <MenuSchoolIcon />,
          children: [
            { path: paths.SCHOOL_ADMINS, tag: "oneSchoolAdmins.sidebarTag", icon: <MenuAdminsIcon /> },
            { path: paths.EDUCATORS, tag: "educators.title", icon: <MenuEducatorIcon /> },
            { path: paths.LEARNERS, tag: "learners.title", icon: <MenuLearnerIcon /> },
            { path: paths.SCHOOL_COLLEGES, tag: "schoolColleges.title", icon: <MenuCollegesIcon /> },
            { path: paths.SCHOOL_GROW, tag: "schoolGrow.sidebarTag", icon: <MenuGrowIcon /> },
            { path: paths.SCHOOL_EVENTS, tag: "schoolEvents.title", icon: <MenuSchoolEventIcon /> },
          ],
        },
        { path: paths.MEDIA_LIBRARY, tag: "mediaLibrary.title", icon: <MenuMediaLibraryIcon /> },
        { path: paths.SHARED_MCAs, tag: "masteryCreditLibrary.title", icon: <MenuMcaIcon /> },
        { path: paths.LIVE_FEED, tag: "liveFeed.title", icon: <MenuLiveFeedIcon /> },
        { path: paths.COMMUNITY_GROUPS, tag: "communityGroups.title", icon: <GroupIcon /> },
      ],
    ],
    [
      [UserRole.ROLE_EDUCATOR],
      [
        {
          path: paths.SCHOOL,
          tag: "mySchool.title",
          icon: <MenuSchoolIcon />,
          children: [
            { path: paths.SCHOOL_ADMINS, tag: "oneSchoolAdmins.sidebarTag", icon: <MenuAdminsIcon /> },
            { path: paths.EDUCATORS, tag: "educators.title", icon: <MenuEducatorIcon /> },
            { path: paths.LEARNERS, tag: "learners.title", icon: <MenuLearnerIcon /> },
            { path: paths.SCHOOL_COLLEGES, icon: <MenuCollegesIcon />, tag: "schoolColleges.title" },
            { path: paths.SCHOOL_GROW, tag: "schoolGrow.sidebarTag", icon: <MenuGrowIcon /> },
            { path: paths.SCHOOL_EVENTS, tag: "schoolEvents.title", icon: <MenuSchoolEventIcon /> },
          ],
        },
        { path: paths.MEDIA_LIBRARY, tag: "mediaLibrary.title", icon: <MenuMediaLibraryIcon /> },
        { path: paths.SHARED_MCAs, tag: "masteryCreditLibrary.title", icon: <MenuMcaIcon /> },
        { path: paths.LIVE_FEED, tag: "liveFeed.title", icon: <MenuLiveFeedIcon /> },
        { path: paths.COMMUNITY_GROUPS, tag: "communityGroups.title", icon: <GroupIcon /> },
      ],
    ],
    [
      [UserRole.ROLE_LEARNER],
      [
        { path: paths.LEARNER_PROFILE, tag: "learnerCabinet.title", icon: <MenuLearnerIcon /> },
        {
          path: paths.SCHOOL,
          tag: "schoolProfile.title",
          icon: <MenuSchoolIcon />,
          children: [
            {
              path: paths.SCHOOL_MCA,
              tag: isLearningRecordIssuer ? "competencyModel.title" : "mcaList.title",
              icon: <MenuMcaIcon />,
            },
            {
              path: paths.SCHOOL_COURSES,
              tag: isLearningRecordIssuer ? "experiences.title" : "courseLibrary.title",
              icon: <MenuCoursesIcon />,
            },
          ],
        },
        { path: paths.LEARNER_COLLEGES, tag: "learnerColleges.title", icon: <MenuCollegesIcon /> },
        {
          path: `${process.env.REACT_APP_PROGRESS_URL}/profile/progress`,
          tag: "progressTracker.title",
          icon: <ProgressAvatar />,
          target: "_blank",
        },
      ],
    ],
  ]);

  for (const [roles, links] of menuLinks.entries()) {
    if (roles.includes(role)) {
      return links;
    }
  }

  return [];
};
