import * as types from "../constants";
import store from "../store";

export const setModal = (content, props) => {
  store.dispatch({ type: types.SET_MODAL, payload: { content, props } });
};

export const closeModal = () => {
  store.dispatch({ type: types.CLOSE_MODAL });

  setTimeout(() => {
    store.dispatch({ type: types.REMOVE_MODAL_CONTENT });
  }, 200);
};

export const shutDownModal = () => {
  store.dispatch({ type: types.REMOVE_MODAL_CONTENT });
};
