import { format, formatDistanceStrict, isValid, parseISO } from "date-fns";
import { isDate } from "lodash";

export const monthIndexes = Array.from({ length: 12 }, (v, i) => i);

export const parseDateToUTC = (date: string) => {
  if (isDate(date)) {
    return date.toLocaleDateString();
  }
  const parsedDate = parseISO(date);
  if (!isValid(parsedDate)) {
    return date;
  }
  // remove timezone so date-fns assumes the local timezone
  const dateWithoutStamp = date.slice(0, -1);
  return format(parseISO(dateWithoutStamp), "MM/dd/yyyy");
};

export const parseDateToLocaleString = (date: string, fallback = null) => {
  if (isDate(date)) {
    return date.toLocaleDateString();
  }
  const parsedDate = parseISO(date);
  if (isValid(parsedDate)) {
    return parsedDate.toLocaleDateString();
  }

  return fallback;
};

/**
 * @example: 3 months ago, 1 year ago
 */
export const formatTimeAgo = (date: string) => formatDistanceStrict(new Date(date), Date.now(), { addSuffix: true });

/**
 * @example: Dec 13, 2021
 */
export const formatShortLocalizedTime = (date: string) => format(new Date(date), "PP");

/**
 * @example:  Dec 13, 2021, 7:22 PM GMT+3
 */
export const formatLongLocalizedTime = (date: string) => format(new Date(date), "PPp z");

/**
 * @example:  Dec 13, 2021 07 am
 */
export const formatLongTime = (date: string) => format(new Date(date), "MMM d, yyyy hh aaaaa'm'");

/**
 * @example:  Dec 13, 2021 07:05 am
 */
export const formatLongTimeWithMinutes = (date: string) => format(new Date(date), "MMM d, yyyy hh:mm aaaaa'm'");

/**
 * @example:  03/24/2023
 */
export const formatShortTime = (date: string, separator = "/") =>
  format(new Date(date), `MM${separator}dd${separator}yyyy`);
