import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Box } from "@mui/material";
import { Caption } from "components/typography";
import authService from "services/authService";
import { dispatchErrorPopup } from "redux/actions/popupActions";
import { paths } from "routes";
import { useNavigate } from "react-router-dom";
import { login } from "redux/slices/accountSlice";
import { useTypedSelector } from "hooks";

export const LogoutFooter = () => {
  const navigate = useNavigate();
  const [showFooter, setShowFooter] = useState(true);
  const dispatch = useDispatch();
  const account = useTypedSelector((state) => state.account);

  useEffect(() => {
    const handleOnScroll = () => setShowFooter(!(window.scrollY > 0));
    window.addEventListener("scroll", handleOnScroll);
    return () => {
      window.removeEventListener("scroll", handleOnScroll);
    };
  }, []);

  return (
    <Box
      display="flex"
      position="fixed"
      bottom={showFooter ? 0 : "-100px"}
      zIndex={999}
      bgcolor="#774300"
      py={1}
      width="100%"
      px={4}
      color="common.white"
      justifyContent="center"
      style={{ cursor: "pointer", transition: "all 0.5s ease" }}
      onClick={() =>
        authService
          .logoutAs()
          .then((token) => {
            authService.setAuthToken(token);
            authService.loadAccount().then((account) => {
              localStorage.removeItem("loggedAs");
              dispatch(login(account));
              navigate(paths.DASHBOARD);
            });
          })
          .catch(dispatchErrorPopup)
      }
    >
      <Caption content="logoutFooterText" with={{ name: `${account.firstName} ${account.lastName}` }} isBold />
    </Box>
  );
};
