import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import { ErrorFallback } from "components/pageLayout/helpers/ErrorFallback";
import { Container } from "@mui/material";

class ErrorBoundary extends Component {
  state = {
    error: null,
    errorInfo: "",
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidMount() {
    window.addEventListener("error", this.handleError);
    window.addEventListener("unhandledrejection", this.handleError);
  }

  componentDidCatch(error, errorInfo) {
    this.handleError(error, errorInfo);
  }

  handleError = (error, errorInfo) => {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      if (error instanceof PromiseRejectionEvent) {
        Sentry.captureException(new Error(error.reason));
      } else {
        Sentry.captureException(error);
      }
      this.setState({ errorInfo });
    });
  };

  render() {
    const { hasError, error } = this.state;
    const { onReset } = this.props;

    if (hasError) {
      return (
        <Container sx={{ mt: 8 }} maxWidth="sm">
          <ErrorFallback onReset={onReset} error={error} />
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
