import _ from "lodash";

export const setSpacing = (spacing) => (props) => props.theme.spacing(spacing);

export const setColor = (colorCode) => (props) => {
  const v = _.get(props.theme.palette, colorCode);
  if (!v) {
    // eslint-disable-next-line no-console
    console.error(`There is no such color code "${colorCode}" in the theme`);
  }
  return v;
};

export const setTransition =
  (type = "shortest") =>
  (props) =>
    `${props.theme.transitions.duration[type]}ms`;
