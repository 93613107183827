import { MouseEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { generatePath, Link as RouterLink } from "react-router-dom";
import counterpart from "counterpart";

import { Box, Grid, ListItemSecondaryAction, ListItemText, Menu, MenuItem } from "@mui/material";
import { ExpandMore as ExpandMoreIcon, Launch } from "@mui/icons-material";
import { logout } from "redux/slices/accountSlice";
import { UserAvatar } from "components/Avatar/UserAvatar";
import { ButtonText } from "components/buttons";

import authService from "services/authService";
import { paths } from "routes";
import { Body1, Body2, Caption, Typography } from "components/typography";
import { useTypedSelector } from "hooks";
import { ExternalURLs } from "utils/constants";
import { UserRole } from "services/types";

const AccountMenu = () => {
  const currentUser = useTypedSelector((state) => state.account);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const closeMenu = () => setOpen(false);

  const handleLogout = () => {
    authService.logout();
    dispatch(logout());
  };

  const canBeOpen = open && Boolean(anchorEl);
  const ariaOwns = canBeOpen ? "menu-appbar" : undefined;

  const { photoUrl, firstName, lastName, role, schoolName, id: userId, schoolId } = currentUser;
  const toProfile =
    role === UserRole.ROLE_LEARNER ? generatePath(paths.LEARNER_PROFILE, { userId, schoolId }) : paths.USER_PROFILE;

  return (
    <>
      <ButtonText aria-owns={ariaOwns} aria-haspopup="true" onClick={handleClick} data-test-id="accountMenu">
        <Grid container alignItems="center">
          <UserAvatar src={photoUrl} {...{ firstName, lastName }} />
          <Box display={{ xs: "none", md: "flex" }}>
            <Box display="flex" alignItems="center">
              <Box mx={4} textAlign="left">
                <Body2 isBold color="textPrimary">
                  {firstName} {lastName}
                </Body2>
                <Caption
                  content={schoolName ? `schoolUserRoles.${role}` : `userRoles.${role}`}
                  with={{ schoolName }}
                  color="textSecondary"
                />
              </Box>
              <ExpandMoreIcon style={{ color: "black" }} />
            </Box>
          </Box>
        </Grid>
      </ButtonText>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        disableScrollLock
        MenuListProps={{ style: { minWidth: 200 } }}
      >
        <MenuItem component={RouterLink} to={toProfile} onClick={handleClick}>
          <Body1 content="buttons.profile" />
        </MenuItem>
        <MenuItem component="a" href={ExternalURLs.RELEASE_NOTES} onClick={handleClick} target="_blank">
          <ListItemText primary={counterpart.translate("buttons.releaseNotes")} style={{ margin: 0 }} />
          <ListItemSecondaryAction>
            <Launch style={{ verticalAlign: "middle", fontSize: 18, opacity: 0.6 }} />
          </ListItemSecondaryAction>
        </MenuItem>
        <MenuItem component="a" href={ExternalURLs.PRIVACY_POLICY} onClick={handleClick} target="_blank">
          <ListItemText primary={counterpart.translate("buttons.privacyPolicy")} style={{ margin: 0 }} />
          <ListItemSecondaryAction>
            <Launch fontSize="small" style={{ verticalAlign: "middle", fontSize: 18, opacity: 0.6 }} />
          </ListItemSecondaryAction>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Typography content="buttons.signOut" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountMenu;
