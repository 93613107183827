import _ from "lodash";
import { generatePath, matchPath } from "react-router-dom";
import { getDataFromLocalStorage, removeDataFromLocalStorage } from "utils/localStorage";
import { paths } from "routes";
import { schoolStaff, UserRole } from "services/types";

const getSalesForceAccountUrlById = (salesforceId) =>
  `https://${process.env.REACT_APP_SALESFORCE_INSTANCE}.lightning.force.com/lightning/r/Account/${salesforceId}/view`;

const getUserProfileURL = (currentPathname, userId, schoolId) => {
  if (currentPathname === paths.ALL_SCHOOL_ADMINS) {
    return generatePath(paths.SCHOOL_ADMIN_PROFILE, { schoolId, userId });
  }

  return `${currentPathname}/${userId}`;
};

const generateBackPath = (steps = 1) => {
  const { pathname } = window.location;
  return pathname.split("/").slice(0, -steps).join("/");
};

const getHomePageUrl = (user) => {
  const homePageByRole = {
    [UserRole.ROLE_SUPER_ADMIN]: paths.DASHBOARD,
    [UserRole.ROLE_SUPPORT_ADMIN]: paths.DASHBOARD,
    [UserRole.ROLE_TEAMMATE]: paths.DASHBOARD,
    [UserRole.ROLE_SCHOOL_ADMIN]: paths.SCHOOL,
    [UserRole.ROLE_EDUCATOR]: paths.SCHOOL,
    [UserRole.ROLE_LEARNER]: paths.LEARNER_PROFILE,
  };

  const homePage = homePageByRole[user.role];
  return generatePath(homePage, { schoolId: user?.schoolId, userId: user?.id });
};

const isRedirectValid = (user, params) => {
  const { schoolId, userId } = params;

  if (schoolStaff.includes(user.role)) {
    return String(user.schoolId) === String(schoolId);
  }

  if (user.role === UserRole.ROLE_LEARNER) {
    return String(schoolId) === String(user.schoolId) || String(user.id) === String(userId);
  }

  return true;
};

const getURLToRedirect = (user) => {
  const currentUrl = new URL(window.location.href);
  const externalUrl = currentUrl.searchParams.get("redirect_uri");
  const redirectTo = getDataFromLocalStorage("redirectUrl");

  if (redirectTo) {
    removeDataFromLocalStorage("redirectUrl");

    if ([UserRole.ROLE_SCHOOL_ADMIN, UserRole.ROLE_EDUCATOR, UserRole.ROLE_LEARNER].includes(user.role)) {
      const match = matchPath({ path: paths.SCHOOL, end: false }, redirectTo.pathname);
      if (!match) {
        return getHomePageUrl(user);
      }

      return isRedirectValid(user, match.params) ? redirectTo : getHomePageUrl(user);
    }

    return redirectTo;
  }

  if (externalUrl) {
    return { externalUrl };
  }

  return getHomePageUrl(user);
};

/**
 * Loosely validate a URL `string`.
 *
 * @param {String} string
 * @return {Boolean}
 */

const isUrl = (string) => {
  const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;

  const localhostDomainRE = /^localhost[:?\d]*(?:[^:?\d]\S*)?$/;
  const nonLocalhostDomainRE = /^[^\s.]+\.\S{2,}$/;
  if (typeof string !== "string") {
    return false;
  }

  const match = string.match(protocolAndDomainRE);
  if (!match) {
    return false;
  }

  const everythingAfterProtocol = match[1];
  if (!everythingAfterProtocol) {
    return false;
  }

  return localhostDomainRE.test(everythingAfterProtocol) || nonLocalhostDomainRE.test(everythingAfterProtocol);
};

const getFileNameFromUrl = (url) => _.last(url.split("/"));

const getFileNameWithoutExt = (filename) => filename.replace(/\..*$/g, "");

export {
  getSalesForceAccountUrlById,
  getUserProfileURL,
  getFileNameWithoutExt,
  generateBackPath,
  getURLToRedirect,
  isUrl,
  getFileNameFromUrl,
  getHomePageUrl,
};
