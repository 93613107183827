import { styled, darken } from "@mui/material/styles";

import { Fab, Box, Popper } from "@mui/material";
import { setSpacing } from "components/styledComponents/actions";
import { Body2 } from "components/typography";
import { CloseIcon } from "svg";

// popover
export const StyledPopper = styled(Popper)`
  z-index: ${({ theme }) => theme.zIndex.modal};

  .MuiPaper-root {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    max-width: 100vw;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

export const SupportFab = styled(Fab)`
  position: fixed;
  right: ${setSpacing(2)};
  bottom: ${setSpacing(4)};
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  background-color: ${({ theme }) => theme.palette.text.secondary};
  &:hover {
    background-color: ${({ theme }) => darken(theme.palette.text.secondary, 0.1)};
  }
`;

export const ScreenshotSize = styled(Body2)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const StyledComparePopup = styled(Box)`
  position: relative;
  padding: ${setSpacing(6)} ${setSpacing(15)} ${setSpacing(6)} ${setSpacing(10)};
  max-width: 480px;
  .close {
    position: absolute;
    top: ${setSpacing(3)};
    right: ${setSpacing(3)};
    svg {
      stroke: #a8a8a8;
      width: 14px;
      height: 14px;
    }
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.caption};
`;
