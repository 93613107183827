import _ from "lodash";
import { createAsyncThunk, createEntityAdapter, createSlice, isAnyOf, createSelector } from "@reduxjs/toolkit";

import conversationsService from "services/conversationsService";
import {
  createConversation,
  fetchConversationById,
  fetchConversations,
  fetchMoreConversations,
  fetchMoreMessages,
} from "./sharedConversationsActions";

const messagesAdapter = createEntityAdapter();

export const STATE_KEY = "messages";

export const sendMessage = createAsyncThunk(
  `${STATE_KEY}/sendMessage`,
  async ({ conversationId, content }, { getState, rejectWithValue }) => {
    try {
      const response = await conversationsService.sendMessage(conversationId, content);
      const userId = getState().account.id;

      const newDate = new Date().toISOString();

      return {
        id: response.id,
        content,
        createdAt: newDate,
        updatedAt: newDate,
        user: userId,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateMessage = createAsyncThunk(
  `${STATE_KEY}/updateMessage`,
  async ({ messageId, content }, { rejectWithValue }) => {
    try {
      await conversationsService.editMessage(messageId, content);
      return {
        id: messageId,
        changes: {
          content,
          updatedAt: new Date().toISOString(),
        },
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = messagesAdapter.getInitialState({
  ui: {
    messageInEditMode: null,
  },
});

const messagesSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    messageSetInEditMode(state, action) {
      state.ui.messageInEditMode = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(sendMessage.fulfilled, messagesAdapter.upsertOne)
      .addCase(updateMessage.fulfilled, (state, action) => {
        messagesAdapter.updateOne(state, action.payload);
        state.ui.messageInEditMode = null;
      })
      .addMatcher(
        isAnyOf(
          fetchConversationById.fulfilled,
          fetchConversations.fulfilled,
          fetchMoreConversations.fulfilled,
          createConversation.fulfilled,
          fetchMoreMessages.fulfilled
        ),
        (state, action) => {
          if (action.payload.entities?.messages) {
            state.ui.messageInEditMode = null;
            messagesAdapter.upsertMany(state, action.payload.entities.messages);
          }
        }
      ),
});

export const { messageSetInEditMode } = messagesSlice.actions;

const selectMessagesSlice = (state) => state[STATE_KEY];

export const { selectById: selectMessageById, selectEntities: selectAllMessages } =
  messagesAdapter.getSelectors(selectMessagesSlice);

export const selectMessagesByIds = createSelector(
  [selectAllMessages, (_state, messageIds) => messageIds],
  (messages, messageIds) => {
    return _.orderBy(
      _.map(messageIds, (id) => messages[id]),
      (message) => new Date(message.createdAt),
      ["asc"]
    );
  }
);

export default messagesSlice.reducer;
