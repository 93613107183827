import { StyledAvatar, StyledAvatarProps } from "components/Avatar/styles";
import clsx from "clsx";
import { TextLink } from "components/links";

export type AvatarProps = StyledAvatarProps & {
  name?: string;
  href?: string;
  clickable?: boolean;
};

export const Avatar = ({ src = "", name, size, href, clickable, children, ...props }: AvatarProps) => {
  const avatar = (
    <StyledAvatar
      src={src}
      alt={name}
      className={clsx({ clickable: clickable || Boolean(href) }, [size])}
      size={size}
      {...props}
    >
      {children}
    </StyledAvatar>
  );

  if (href) {
    return (
      <TextLink to={href} underline="none">
        {avatar}
      </TextLink>
    );
  }

  return avatar;
};
