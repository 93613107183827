import * as types from "../constants";

const closedWindow = { open: false, content: false, props: {} };

export default function reducer(state = { ...closedWindow }, { type, payload }) {
  switch (type) {
    case types.SET_MODAL:
      return { open: true, content: payload.content, props: payload.props };

    case types.CLOSE_MODAL:
      return { ...state, open: false };

    case types.REMOVE_MODAL_CONTENT:
      return { ...closedWindow };

    default:
      return state;
  }
}
