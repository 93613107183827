import { useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import learnerService from "services/learnerService";
import nudgesService from "services/nudgesService";
import { dispatchErrorPopup, dispatchPopup } from "redux/actions/popupActions";
import { paths } from "routes";
import { Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Translate from "react-translate-component";
import { NudgeReaction } from "services/types";

export const CollegeNudgeActions = ({ colleges, nudgeId, onSuccess }) => {
  const { id: learnerId, schoolId } = useSelector((state) => state.account);
  const navigate = useNavigate();

  const [isSending, setIsSending] = useState(false);

  const handleCollegesUpdateReaction = async () => {
    try {
      setIsSending(true);

      const newColleges = colleges.map(({ id, accepted, status }) => ({ id, accepted, status }));
      await learnerService.updateCollegeAssignment(learnerId, newColleges);

      await nudgesService.updateNudgeReaction({ learnerId, nudgeId, reaction: NudgeReaction.UPDATED });

      onSuccess();
      dispatchPopup("success", { tag: "formResults.COLLEGE_LIST_UPDATED" });
    } catch (e) {
      dispatchErrorPopup(e);
      setIsSending(false);
    }
  };

  const handleToCollegesReaction = async () => {
    setIsSending(true);
    try {
      await nudgesService.updateNudgeReaction({
        learnerId,
        nudgeId,
        reaction: NudgeReaction.TO_COLLEGES,
      });

      navigate(generatePath(paths.LEARNER_COLLEGES, { schoolId, userId: learnerId }));
      onSuccess();
    } catch (e) {
      dispatchErrorPopup(e);
      setIsSending(false);
    }
  };

  return (
    <Grid container spacing={3} justifyContent="flex-end">
      <Grid item xs={6}>
        <LoadingButton
          loading={isSending}
          onClick={handleToCollegesReaction}
          fullWidth
          color="white"
          variant="outlined"
        >
          <Translate content="buttons.toColleges" />
        </LoadingButton>
      </Grid>
      {colleges.length > 0 && (
        <Grid item xs={6}>
          <LoadingButton
            loading={isSending}
            onClick={handleCollegesUpdateReaction}
            fullWidth
            color="white"
            variant="contained"
          >
            <Translate content="buttons.update" />
          </LoadingButton>
        </Grid>
      )}
    </Grid>
  );
};
