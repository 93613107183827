import { Box, useMediaQuery } from "@mui/material";
import { LogoIcon, MobileLogoIcon } from "svg";

const MtcLogo = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const LogoComponent = isMobile ? MobileLogoIcon : LogoIcon;
  return (
    <Box sx={{ width: { xs: 40, md: 155 } }} display="flex" alignItems="center">
      <LogoComponent aria-label="Mastery Transcript® Builder" style={{ width: "100%", height: " 100%" }} />
    </Box>
  );
};

export default MtcLogo;
