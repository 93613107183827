import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFirstMountState } from "./useFirstMountState";

type KeyType = string | number;

type ToggleState = {
  [key: string | number]: boolean;
};

const init = <T extends KeyType>(ids: T[]) => ids.reduce((acc, id) => ({ ...acc, [id]: false }), {});

/**
 * Toggle helper
 * allows toggling one section or all sections at once
 */
export const useToggleAll = <T extends KeyType>(
  initialState: T[]
): {
  toggleState: ToggleState;
  allExpanded: boolean;
  toggleAll: () => void;
  toggle: (id: T) => void;
} => {
  const [state, setState] = useState<ToggleState>(() => init(initialState));

  const isFirstMount = useFirstMountState();

  useEffect(() => {
    if (!isFirstMount) {
      setState(init(initialState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initialState)]);

  const toggle = useCallback((id: T) => setState((prev) => ({ ...prev, [id]: !prev[id] })), []);

  const allExpanded = useMemo(() => _.every(state, Boolean), [state]);

  const toggleAll = useCallback(
    () => setState((prevState) => _.mapValues(prevState, () => !allExpanded)),
    [allExpanded]
  );

  return {
    toggleState: state,
    allExpanded,
    toggleAll,
    toggle,
  };
};
