import React, { forwardRef, Fragment, ReactNode } from "react";
import Translate, { TranslateProps } from "react-translate-component";
import { Link as ReactRouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { Button as MuiButton, ButtonProps, Link as MuiLink, LinkProps as MuiLinkProps, Tooltip } from "@mui/material";
import { RequireAtLeastOne } from "types";

type ExternalLinkProps = MuiLinkProps & { text?: string | number; content?: string };

const MailTo = ({ email, withTooltip }: { email: string; withTooltip?: boolean }) => {
  return (
    <Tooltip title={withTooltip ? email : ""} placement="bottom-start">
      <ExternalLink href={`mailto:${email}`} text={email} />
    </Tooltip>
  );
};

type RouterLinkButtonBaseProps = Omit<ButtonProps<typeof ReactRouterLink>, "children">;

type RouterLinkButtonPropsWithChildren = ButtonProps<typeof ReactRouterLink> & {
  content?: never;
  with?: never;
  children: ReactNode;
};

type RouterLinkButtonI18nProps = RouterLinkButtonBaseProps & {
  children?: never;
} & Pick<TranslateProps, "content" | "with">;

type RouterLinkButtonProps = RouterLinkButtonPropsWithChildren | RouterLinkButtonI18nProps;

const RouterLink = ({ children, to, content, with: translateWith, ...props }: RouterLinkButtonProps) => (
  <MuiButton color="primary" {...props} component={ReactRouterLink} to={String(to)}>
    {children || <Translate component={Fragment} content={content || ""} with={translateWith} />}
  </MuiButton>
);

const RouterLinkContained = (props: RouterLinkButtonProps) => <RouterLink variant="contained" {...props} />;

const RouterLinkOutlined = (props: RouterLinkButtonProps) => <RouterLink variant="outlined" {...props} />;

const ExternalLink = forwardRef<
  HTMLAnchorElement,
  RequireAtLeastOne<ExternalLinkProps, "text" | "content" | "children">
>(({ children, href, content, text, ...rest }, ref) => {
  if (content) {
    return (
      <MuiLink ref={ref} href={href} rel="noopener noreferrer" target="_blank" underline="hover" {...rest}>
        <Translate component={Fragment} content={content} />
      </MuiLink>
    );
  }

  return (
    <MuiLink ref={ref} href={href} rel="noopener noreferrer" target="_blank" underline="hover" {...rest}>
      {children || text}
    </MuiLink>
  );
});

type TextLinkProps = MuiLinkProps &
  RouterLinkProps & {
    text?: string | number;
    content?: string;
  };

const TextLink = ({ to, children, text, content, ...props }: TextLinkProps) => (
  <MuiLink component={ReactRouterLink} to={String(to)} underline="hover" {...props}>
    {children || text || <Translate component={Fragment} content={content || ""} />}
  </MuiLink>
);

export { ExternalLink, RouterLink, RouterLinkContained, RouterLinkOutlined, TextLink, MailTo };
