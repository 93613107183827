import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { setSpacing } from "components/styledComponents/actions";

export const StyledSchoolMenu = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border-color: black;
  }
  .MuiOutlinedInput-root {
    border-radius: 37px;
    max-width: 250px;
    ${(props) => props.theme.breakpoints.down("sm")} {
      width: 150px;
    }
  }
  .MuiOutlinedInput-input {
    padding-top: ${setSpacing(2.25)};
    padding-bottom: ${setSpacing(2.25)};
  }
  .MuiSelect-select:focus {
    background: none;
  }
  .MuiSelect-icon {
    top: calc(50% - 11px);
    color: black;
  }
`;
