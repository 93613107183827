import * as React from "react";

type parserOptions<T> = {
  serializer: (value: T) => string;
  deserializer: (value: string) => T;
  validKeys?: string[];
};

export const useLocalStorage = <T>(key: string, defaultValue: T, options?: parserOptions<T>) => {
  const deserializer = options?.deserializer || JSON.parse;

  const [state, setState] = React.useState(() => {
    const defaultVal = typeof defaultValue === "function" ? defaultValue() : defaultValue;
    try {
      const valueInLocalStorage = window.localStorage.getItem(key);
      if (valueInLocalStorage) {
        const deserializedValue = deserializer(valueInLocalStorage);
        const isValueValid = options?.validKeys?.includes(deserializedValue);
        return isValueValid ? deserializedValue : defaultVal;
      }
      return defaultVal;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Error reading localStorage key “${key}”:`, error);
    }
    return defaultVal;
  });

  const prevKeyRef = React.useRef(key);

  const serializer = options?.serializer || JSON.stringify;

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    const prevKey = prevKeyRef.current;
    if (prevKey !== key) {
      window.localStorage.removeItem(prevKey);
    }
    prevKeyRef.current = key;

    window.localStorage.setItem(key, serializer(state));
  }, [key, state]);

  return [state, setState];
};
