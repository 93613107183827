import { styled } from "@mui/material/styles";
import { Paper, TableContainer } from "@mui/material";
import { setColor, setSpacing } from "components/styledComponents/actions";

export const StyledCollegeNudgePopup = styled(Paper)`
  position: fixed;
  width: 650px;
  z-index: ${(props) => props.theme.zIndex.modal};
  bottom: 0;
  right: 0;
  background-color: ${setColor("warning.dark")};
  padding: ${setSpacing(4)} ${setSpacing(4)};
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
`;

export const StyledCollegeNudgePopupTable = styled(TableContainer)`
  max-height: 150px;
  td,
  th {
    padding: 2px 4px;
    border: none;
    background: ${setColor("warning.dark")};
    color: ${setColor("common.white")};
  }

  .MuiInputBase-root {
    font-size: ${(props) => props.theme.typography.body2.fontSize};
    color: inherit;

    &:before {
      border: none;
    }

    &:after {
      border-color: inherit;
    }

    &:hover:not(.Mui-disabled):before {
      border-color: inherit;
    }

    .MuiSelect-icon {
      color: inherit;
    }
  }

  .MuiCheckbox-root {
    padding: ${setSpacing(1)};
    color: inherit;
  }
`;
