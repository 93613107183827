import { Box } from "@mui/material";
import { Body2 } from "components/typography";

const sizeMap = {
  default: {
    baseSize: 14,
    ml: 1.5,
  },
  small: { baseSize: 12, ml: 1 },
};

type StatusCircleProps = {
  label?: string | null;
  color: string;
  empty?: boolean;
  size?: "default" | "small";
};

export const StatusCircle = ({ color, empty = false, label, size = "default" }: StatusCircleProps) => {
  const { baseSize, ml } = sizeMap[size];
  const indicator = (
    <Box
      position="relative"
      top={2}
      display="inline-block"
      width={baseSize}
      height={baseSize}
      border={2}
      borderColor={color}
      borderRadius="50%"
      bgcolor={empty ? undefined : color}
      mr={ml}
    />
  );

  if (!label) {
    return indicator;
  }

  return (
    <Box display="inline-block" style={{ whiteSpace: "nowrap" }} color="common.black" data-test-id="status">
      {indicator}
      <Body2 component="span" content={label} style={{ fontSize: baseSize, wordBreak: "normal" }} />
    </Box>
  );
};
