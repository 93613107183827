const getFullName = (firstName, lastName) => `${firstName} ${lastName}`;

const extractFileNameFromPath = (path) =>
  // eslint-disable-next-line no-useless-escape
  path ? path.replace(/^.*[\\\/]/, "") : "";

const arrayMove = (array, from, to) => {
  const copyOfArray = [...array];
  const startIndex = from < 0 ? copyOfArray.length + from : from;

  if (startIndex >= 0 && startIndex < copyOfArray.length) {
    const endIndex = to < 0 ? copyOfArray.length + to : to;

    const [item] = copyOfArray.splice(from, 1);
    copyOfArray.splice(endIndex, 0, item);
  }
  return copyOfArray;
};

export { arrayMove, getFullName, extractFileNameFromPath };
