import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { forwardRef } from "react";

const ExternalLink = forwardRef(({ href, icon, label, isSelected, onClose, ...rest }, ref) => {
  return (
    <ListItemButton
      ref={ref}
      component="a"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      selected={isSelected}
      disableRipple
      onClick={onClose}
      {...rest}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={label} />
    </ListItemButton>
  );
});

export default ExternalLink;
