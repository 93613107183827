import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { format, set, subSeconds } from "date-fns";

import { Box } from "@mui/material";
import { closeModal } from "redux/actions/modalActions";
import { logout } from "redux/slices/accountSlice";
import { removeDataFromLocalStorage, saveDataToLocalStorage } from "utils";
import { ModalTitle } from "../helpers/ModalTitle";
import { ButtonContained, ButtonOutlined } from "components/buttons";
import { Body2 } from "../../typography";

import AuthService from "../../../services/authService";

export const IdleTimeout = () => {
  const dispatch = useDispatch();
  const [countdown, setCountdown] = useState(set(new Date(), { minutes: 10, seconds: 0 }));
  const timeoutId = useRef(0);

  const handleLogout = useCallback(() => {
    closeModal();
    AuthService.logout();
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    removeDataFromLocalStorage("idle-timeout");

    window.addEventListener("storage", ({ key, newValue }) => {
      if (key === "idle-timeout" && newValue) {
        closeModal();
      }
    });

    return () => {
      window.removeEventListener("storage", null);
      saveDataToLocalStorage("idle-timeout", "closed", true);
    };
  }, []);

  useEffect(() => {
    timeoutId.current = setInterval(() => setCountdown((oldCount) => subSeconds(oldCount, 1)), 1000);
    return () => clearInterval(timeoutId.current);
  }, []);

  useEffect(() => {
    if (countdown.getMinutes() === 0 && countdown.getSeconds() === 0) handleLogout();
  }, [countdown, handleLogout]);

  return (
    <Box>
      <ModalTitle tag="idleModal.title" />
      <Body2 with={{ buttonText: "Continue Session" }} content="idleModal.text" />
      <Body2 my={10} align="center" style={{ fontSize: "50px" }} isBold>
        {format(countdown, "mm:ss")}
      </Body2>

      <Box mb={6}>
        <ButtonContained onClick={closeModal} fullWidth content="idleModal.confirmButton" />
      </Box>
      <ButtonOutlined onClick={handleLogout} fullWidth content="idleModal.cancelButton" />
    </Box>
  );
};
