import counterpart from "counterpart";

import { Checkbox, FormControlLabel } from "@mui/material";
import { handleFieldData } from "components/form/actions";
import { ErrorDisplay } from "./ErrorDisplay";
import { Body2 } from "../../typography";

export const CheckboxInput = (props) => {
  const { ariaLabel, onClick } = props;
  const { id, name, label, text, helper, error, value, indeterminate, handleChanges, disabled, required, hide } =
    handleFieldData(props);

  const renderedCheckbox = (
    <Checkbox
      id={id}
      name={name}
      checked={!!value}
      indeterminate={indeterminate}
      onChange={(e) => handleChanges({ target: { name, value: e.target.checked, id, checked: e.target.checked } })}
      onClick={onClick}
      disabled={disabled}
      color="primary"
      required={required}
      inputProps={{ "aria-label": ariaLabel ? counterpart.translate(ariaLabel) : null }}
      sx={{ display: hide ? "none" : "block" }}
    />
  );

  // * If we have aria-label there is no need to wrap component in label so we just render Checkbox
  return (
    <>
      {ariaLabel ? (
        renderedCheckbox
      ) : (
        <FormControlLabel control={renderedCheckbox} label={text || counterpart.translate(label)} />
      )}
      {!error && helper && <Body2 mt={-2} ml={8} content={helper} color="textSecondary" />}
      <ErrorDisplay error={error} mt={0} />
    </>
  );
};
