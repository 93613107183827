import * as Yup from "yup";
import { format } from "date-fns";

Yup.setLocale({
  // use constant translation keys for error messages
  mixed: {
    default: ({ path }) => ({ tag: "FIELD_INVALID", data: { path } }),
    required: ({ path }) => ({ tag: "REQUIRED", data: { path } }),
    oneOf: ({ path, values }) => ({ tag: "ONE_OF", data: { path, valids: values.join(", ") } }),
    notOneOf: ({ path, values }) => ({ tag: "NOT_ONE_OF", data: { path, values } }),
    notType: ({ path, type, value }) => ({ tag: `${type.toUpperCase()}.INVALID`, data: { path, type, value } }),
  },
  string: {
    email: ({ path }) => ({ tag: "INVALID_EMAIL", data: { path } }),
    min: ({ path, min }) => ({ tag: "STRING.MIN", data: { path, limit: min } }),
    max: ({ path, max }) => ({ tag: "STRING.MAX", data: { path, limit: max } }),
    url: ({ path }) => ({ tag: "STRING.URL", data: { path } }),
  },
  number: {
    min: ({ path, min }) => ({ tag: "NUMBER.MIN", data: { path, limit: min } }),
    max: ({ path, max }) => ({ tag: "NUMBER.MAX", data: { path, limit: max } }),
  },
  date: {
    min: ({ path, min }) => ({ tag: "DATE.MIN", data: { path, limit: format(new Date(min), "MM/dd/yyyy") } }),
    max: ({ path, max }) => ({ tag: "DATE.MAX", data: { path, limit: format(new Date(max), "MM/dd/yyyy") } }),
  },
  array: {
    min: ({ path, min }) => ({ tag: "ARRAY.MIN", data: { path, limit: min } }),
    max: ({ path, max }) => ({ tag: "ARRAY.MAX", data: { path, limit: max } }),
  },
});

const STRING = Yup.string().default(null).nullable().max(255);
const BOOLEAN = Yup.boolean().default(false);
const TEXT = Yup.string().default(null).nullable();
const DATE = Yup.date().default(null).nullable();
const NUMBER = Yup.number().default(null).integer().nullable();
const ARRAY = Yup.array();

export { STRING, ARRAY, NUMBER, DATE, TEXT, BOOLEAN };

export default Yup;
