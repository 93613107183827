import { CircularProgress, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const LoadingBox = styled(Box)`
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
`;

function FullPageSpinner() {
  return (
    <LoadingBox>
      <Box m={2}>
        <CircularProgress />
      </Box>
    </LoadingBox>
  );
}

export { FullPageSpinner };
