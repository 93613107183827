import _ from "lodash";
import { subMonths, subWeeks } from "date-fns";

import BaseService from "services/baseService";
import FetchService from "services/fetchService";
import store from "../redux/store";
import {
  Address,
  Educator,
  EventCategory,
  Learner,
  LearnerCollegeStatus,
  LearnerInList,
  MCACredit,
  MCACreditArea,
  MCACreditType,
  Nullable,
  OffsetBasedListResponse,
  PaginatedQueryParams,
  PublicUserInfo,
  School,
  SchoolSendingType,
  SchoolStatus,
  SchoolType,
  User,
  UserStatus,
} from "services/types";
import { AxiosProgressEvent } from "axios";

export type SchoolAdmin = User & {
  schools: {
    id: number;
    name: string;
    country: string | null;
    state: string | null;
    status: SchoolStatus;
  }[];
};

type SchoolConfig = {
  excludeCreditsFromLibrary: boolean;
  hideCreditLibrary: boolean;
  hideTranscriptPublish: boolean;
  salesforceId: Nullable<string>;
};

type ParsedEducators = {
  educators: (Partial<Pick<Educator, "email" | "firstName" | "lastName" | "title">> & {
    id: string;
    sheetIndex: number;
    sheetName: string;
    position: number;
  })[];
  // TODO fix type
  errors: Record<keyof Pick<Educator, "expirationDate" | "email" | "firstName" | "lastName" | "id">, unknown>[];
};

type LearnersXlsx = {
  learners: Pick<
    Learner,
    | "email"
    | "firstName"
    | "lastName"
    | "graduationYear"
    | "externalId"
    | "dateOfBirth"
    | "street"
    | "street2"
    | "city"
    | "state"
    | "zip"
    | "country"
    | "enrollmentDate"
    | "enrollmentEndDate"
    | "enrollmentEndType"
  >;
  errors: Pick<
    Learner,
    | "firstName"
    | "lastName"
    | "email"
    | "graduationYear"
    | "enrollmentDate"
    | "externalId"
    | "dateOfBirth"
    | "street"
    | "street2"
    | "city"
    | "state"
    | "zip"
    | "country"
    | "id"
  >;
};

type CollegeLearner = Omit<
  Learner,
  | "numberOfColleges"
  | "collegeLastUpdate"
  | "creditAreas"
  | "title"
  | "schoolName"
  | "schoolId"
  | "role"
  | "schools"
  | "lastLoginDate"
> & {
  learnerCollegeStatus: LearnerCollegeStatus;
};

export type SchoolStats = {
  admins: number;
  courses: number;
  educators: number;
  areas: number;
  credits: number;
  growProgress: number;
  colleges: number;
  learners: {
    total: number;
    enrolled: number;
    suspended: number;
    currentYog: number;
  };
};

type SchoolWithCollege = {
  id: number;
  name: string;
  numOfCollegeApplicationsThisYear: number;
  numOfCollegeApplications: number;
};

type MCAModel = {
  id: number;
  title: string;
  description: string;
  publisher: string;
  downloads: number;
  views: number;
  reviews: Nullable<number>;
  rating: Nullable<number>;
  iconUrl: Nullable<string>;
  schoolId: number;
  isShared: boolean;
  areasCount: number;
  creditsCount: number;
};

export type MCAModelWithAreas = MCAModel & { areas: MCACreditArea[] };

type MCAModelUpdateDTO = Pick<MCAModel, "title" | "description" | "publisher"> & {
  areas: Pick<
    MCACreditArea,
    "title" | "description" | "position" | "typicalNumOfAdvancedCredits" | "thresholdNumOfAdvancedCredits"
  > &
    { credits: Pick<MCACredit, "title" | "description" | "type" | "position">[] }[];
};

type GetSharedMcaModelsParams = PaginatedQueryParams & {
  schoolId: number;
  type: ("showMcaModels" | "showSchoolMca")[];
};

export type CreateLearnerDTO = {
  firstName: string;
  lastName: string;
  email: string;
  externalId?: null | string;
  enrollmentDate: string;
  graduationYear?: number | null;
  street?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  zip?: string | null;
  dateOfBirth?: string | null;
  sendEmail: boolean;
};

export type CreateLearnersDTO = {
  learners: Omit<CreateLearnerDTO, "sendEmail">;
  sendEmail: boolean;
};

export type GetLearnersQueryParams = PaginatedQueryParams & {
  schoolId: number;
  collegeId?: number;
  status?: UserStatus[];
  graduationYear?: number[];
  numOfColleges?: "Any" | "3 or less" | "4 or more" | "None";
  lastUpdated?: "Within the past week" | "Within the past month" | "More than 3 months ago" | "More than 6 months ago";
  excludeAssignedTo?: number;
  assignedTo?: string[];
};

type GetSchoolsParams = PaginatedQueryParams & {
  status?: SchoolStatus;
  type?: SchoolType;
  sendingType?: SchoolSendingType;
  schoolAdvancedSettings?: string[];
};
type CreateSchoolParams = Address &
  Pick<School, "name" | "type" | "status" | "expirationDate" | "sendingType"> & {
    salesforceId: Nullable<string>;
  };
type GetSchoolAdminsParams = PaginatedQueryParams & {
  schoolId: number;
  status: UserStatus[];
  school: Nullable<
    Omit<School, "contacts" | "sections" | "features"> & {
      config: SchoolConfig;
    }
  >;
  schoolStatus: SchoolStatus;
};
type GetEducatorsParams = PaginatedQueryParams & { schoolId: number; status: UserStatus[] };

type InviteParams = {
  email: string;
  firstName: string;
  lastName: string;
  title: string;
};

export type EducatorCreationDTO = {
  schoolId: number;
  data: Pick<Educator, "email" | "title" | "firstName" | "lastName"> &
    Partial<Pick<Educator, "expirationDate" | "canAdviseAllLearners" | "canEvaluateAllCredits">> & {
      sendEmail: boolean;
    };
};

export type CreateEducatorApiResponse = Pick<Educator, "id" | "email" | "status">;

export type CreateEducatorsApiResponse = CreateEducatorApiResponse[];

type InviteEducatorsParams = {
  educators: Educator[];
  sendEmail: boolean;
};

type SchoolMCACreationDTO = {
  title?: string | null;
  description?: string | null;
  publisher?: string | null;
  typesEnabled?: boolean;
  areas?: {
    title: string;
    description: string;
    position?: number;
    typicalNumOfAdvancedCredits?: number | null;
    thresholdNumOfAdvancedCredits?: number | null;
    credits?: {
      title: string;
      description: string;
      type: MCACreditType;
      position?: number;
    }[];
  }[];
};

type InviteEducatorsApiResponse = Pick<User, "id" | "email">;

type InviteLearnersApiResponse = Pick<User, "id" | "email">[];

type AddLearnerApiResponse = Pick<User, "id" | "email" | "status">;

type AddLearnersApiResponse = Pick<User, "id" | "email" | "status">[];

type SearchLearnersByNameApiResponse = OffsetBasedListResponse<PublicUserInfo>;

type GetActiveUsersApiResponse = User[];

class SchoolService extends BaseService {
  servicePath = "schools";

  getSchoolUrl = (schoolId: number | string, ...rest: (string | number)[]) => {
    const id = schoolId || store.getState().account.schoolId;

    return this.getUrl(id, ...rest);
  };

  getSchoolSectionUrl(schoolId: number, ...rest: (string | number)[]) {
    return this.getSchoolUrl(schoolId, "profile", ...rest);
  }

  getAdminUrl(schoolId: number, ...rest: (string | number)[]) {
    return this.getSchoolUrl(schoolId, "admins", ...rest);
  }

  getEducatorUrl(schoolId: number, ...rest: (string | number)[]) {
    return this.getSchoolUrl(schoolId, "educators", ...rest);
  }

  getLearnersUrl(schoolId: number, ...rest: (string | number)[]) {
    return this.getSchoolUrl(schoolId, "learners", ...rest);
  }

  getCollegeLearnersUrl(schoolId: number, collegeId: number, ...rest: (string | number)[]) {
    return this.getSchoolUrl(schoolId, `colleges/${collegeId}/learners`, ...rest);
  }

  getMcaUrl(schoolId: number, ...rest: (string | number)[]) {
    return this.getSchoolUrl(schoolId, "mca", ...rest);
  }
  getSchools({
    page = 1,
    limit = 8,
    sort = "name,asc",
    filter,
    status,
    type,
    sendingType,
    schoolAdvancedSettings = [],
  }: GetSchoolsParams) {
    return FetchService.get<OffsetBasedListResponse<School>>({
      url: this.getUrl(),
      data: {
        page,
        limit,
        sort,
        filter,
        status,
        type,
        advancedSettingsOn: schoolAdvancedSettings?.includes("advancedSettingsOn") || undefined,
        sendingType,
      },
    });
  }

  createSchool(data: CreateSchoolParams) {
    return FetchService.post<number>({ url: this.getUrl(), data });
  }

  updateSchool(schoolId: number, data: Address & Omit<School, "id" | "photoUrl" | "features">) {
    return FetchService.put<true>({ url: this.getSchoolUrl(schoolId), data });
  }

  updateSchoolSendingType(schoolId: number, sendingType: SchoolSendingType) {
    return FetchService.put<true>({
      url: this.getSchoolUrl(schoolId, "sendingType"),
      data: { sendingType },
    });
  }

  updateSectionDescription(schoolId: number, sectionType: string, content: string) {
    return FetchService.put<true>({
      url: this.getSchoolSectionUrl(schoolId, sectionType),
      data: { content },
    });
  }

  updateSubsection(schoolId: number, subsectionId: number, data: { header: string; content: string }) {
    return FetchService.put<true>({
      url: this.getSchoolSectionUrl(schoolId, "subsections", subsectionId),
      data,
    });
  }

  uploadSchoolPhoto(schoolId: number, data: FormData) {
    return FetchService.post<string>({ url: this.getSchoolUrl(schoolId, "photo"), data });
  }

  deleteSchoolPhoto(schoolId: number) {
    return FetchService.delete<true>({ url: this.getSchoolUrl(schoolId, "photo") });
  }

  getSchool({ schoolId }: { schoolId: number }) {
    return FetchService.get<School>({ url: this.getSchoolUrl(schoolId) });
  }

  getSchoolStats({ schoolId }: { schoolId: number }) {
    return FetchService.get<SchoolStats>({ url: this.getSchoolUrl(schoolId, "stats") });
  }

  getSchoolAdmins({
    schoolId,
    page = 1,
    limit = 16,
    sort = "firstName,asc",
    filter,
    status,
    school = null,
    schoolStatus,
  }: GetSchoolAdminsParams) {
    const baseSchoolId = schoolId || school?.id || store.getState().account.schoolId;
    return FetchService.get<OffsetBasedListResponse<SchoolAdmin>>({
      url: this.getSchoolUrl("admins"),
      data: { page, limit, sort, filter, status, schoolId: baseSchoolId, schoolStatus },
    });
  }

  inviteAdmin(schoolId: number, data: InviteParams) {
    return FetchService.post<true>({ url: this.getAdminUrl(schoolId, "invite"), data });
  }

  reinviteAdmin(schoolId: number, data: { email: string }) {
    return FetchService.post<true>({ url: this.getAdminUrl(schoolId, "reinvite"), data });
  }

  getEducators({ schoolId, filter, page = 1, limit = 8, status, sort = "firstName,asc" }: GetEducatorsParams) {
    return FetchService.get<OffsetBasedListResponse<Educator>>({
      url: this.getEducatorUrl(schoolId),
      data: { filter, page, limit, status, sort },
    });
  }

  addEducator({ schoolId, data }: EducatorCreationDTO) {
    return FetchService.post<CreateEducatorApiResponse>({
      url: this.getEducatorUrl(schoolId),
      data,
    });
  }

  addEducators(schoolId: number, data: InviteEducatorsParams) {
    return FetchService.post<CreateEducatorsApiResponse>({
      url: this.getEducatorUrl(schoolId, "bulk"),
      data,
    });
  }

  inviteEducators(schoolId: number, educators: { id: number }[], selectAll?: boolean) {
    return FetchService.put<InviteEducatorsApiResponse>({
      url: this.getEducatorUrl(schoolId, "invite/bulk"),
      data: { educators, selectAll },
    });
  }

  parseEducatorsXlsx(schoolId: number, data: FormData, onUploadProgress: (e: AxiosProgressEvent) => void) {
    return FetchService.post<ParsedEducators>({ url: this.getEducatorUrl(schoolId, "xlsx"), data, onUploadProgress });
  }

  getEducatorsTemplate(schoolId: number) {
    return FetchService.get<string>({ url: this.getEducatorUrl(schoolId, "template"), responseType: "blob" });
  }

  searchLearnersByName({ schoolId, filter }: { schoolId: number; filter: string }) {
    return FetchService.get<SearchLearnersByNameApiResponse>({
      url: this.getLearnersUrl(schoolId, "search"),
      data: { filter },
    });
  }

  getLearners({
    schoolId,
    collegeId,
    filter,
    page = 1,
    limit = 8,
    status = [],
    graduationYear,
    sort = "firstName,asc",
    numOfColleges,
    lastUpdated,
    excludeAssignedTo,
    assignedTo,
  }: GetLearnersQueryParams) {
    const now = new Date();
    const mapCollegeLastUpdateToDate = () => {
      const mapLastUpdatedLabelsToDate = {
        "Within the past week": now,
        "Within the past month": now,
        "More than 3 months ago": subMonths(now, 3),
        "More than 6 months ago": subMonths(now, 6),
      };

      return lastUpdated ? mapLastUpdatedLabelsToDate[lastUpdated] : null;
    };

    const url = collegeId ? this.getCollegeLearnersUrl(schoolId, collegeId) : this.getLearnersUrl(schoolId);

    return FetchService.get<OffsetBasedListResponse<LearnerInList>>({
      url,
      data: {
        page,
        limit,
        sort,
        filter,
        status,
        graduationYear,
        numCollegeMin: numOfColleges === "4 or more" ? 4 : null,
        numCollegeMax: numOfColleges === "None" ? 0 : numOfColleges === "3 or less" ? 3 : null,
        collegeLastUpdateTo: mapCollegeLastUpdateToDate(),
        collegeLastUpdateFrom:
          lastUpdated === "Within the past month"
            ? subMonths(now, 1)
            : lastUpdated === "Within the past week"
            ? subWeeks(now, 1)
            : null,
        assignedTo: assignedTo?.[0],
        excludeAssignedTo,
      },
    });
  }

  addLearner(schoolId: number, data: CreateLearnerDTO) {
    return FetchService.post<AddLearnerApiResponse>({
      url: this.getLearnersUrl(schoolId),
      data,
    });
  }

  addLearners(schoolId: number, data: CreateLearnersDTO) {
    return FetchService.post<AddLearnersApiResponse>({
      url: this.getLearnersUrl(schoolId, "bulk"),
      data,
    });
  }

  inviteLearners(schoolId: number, learners: { id: number }[], selectAll?: boolean) {
    return FetchService.put<InviteLearnersApiResponse>({
      url: this.getLearnersUrl(schoolId, "invite/bulk"),
      data: { learners, selectAll },
    });
  }

  resetPasswords(schoolId: number, learners: { id: number }[], selectAll?: boolean) {
    return FetchService.post<true>({
      url: this.getLearnersUrl(schoolId, "recovery/bulk"),
      data: { learners, selectAll },
    });
  }

  resetEducatorPassword(schoolId: number, educators: { id: number }[], selectAll?: boolean) {
    return FetchService.post<true>({
      url: this.getEducatorUrl(schoolId, "recovery/bulk"),
      data: { educators, selectAll },
    });
  }

  changeEducatorsStatus(
    schoolId: number,
    educators: {
      id: number;
    }[],
    status: UserStatus.INVITED | UserStatus.ACTIVE,
    selectAll?: boolean
  ) {
    return FetchService.put<true>({
      url: this.getEducatorUrl(schoolId, "bulk"),
      data: { educatorIds: educators.map(({ id }) => id), status, selectAll },
    });
  }

  changeLearnersStatus(
    schoolId: number,
    learners: {
      id: number;
    }[],
    status: UserStatus.ACTIVE | UserStatus.ACCOUNT_ARCHIVED,
    selectAll?: boolean
  ) {
    return FetchService.put<true>({
      url: this.getLearnersUrl(schoolId, "bulk"),
      data: { learnerIds: learners.map(({ id }) => id), status, selectAll },
    });
  }

  updateLearnersData(
    schoolId: number,
    learners: {
      id: number;
      status: UserStatus;
      graduationYear: number;
    }[]
  ) {
    return FetchService.put<true>({ url: this.getLearnersUrl(schoolId, "bulk"), data: { learners } });
  }

  parseLearnersXlsx(schoolId: number, data: FormData, onUploadProgress: (e: AxiosProgressEvent) => void) {
    return FetchService.post<LearnersXlsx>({ url: this.getLearnersUrl(schoolId, "xlsx"), data, onUploadProgress });
  }

  getLearnersTemplate(schoolId: number) {
    return FetchService.get<string>({ url: this.getLearnersUrl(schoolId, "template"), responseType: "blob" });
  }

  getMca({
    schoolId,
    includeArchived = true,
    includeArchivedSteps = true,
  }: {
    schoolId: number;
    includeArchived?: boolean;
    includeArchivedSteps?: boolean;
  }) {
    return FetchService.get<MCAModelWithAreas>({
      url: this.getMcaUrl(schoolId),
      data: { includeArchived, includeArchivedSteps },
    });
  }

  createMca(schoolId: number, data: SchoolMCACreationDTO) {
    return FetchService.post<number>({
      url: this.getMcaUrl(schoolId),
      data: _.pick(data, ["title", "description", "publisher", "areas", "typesEnabled"]),
    });
  }

  copyMca(schoolId: number, mcaModelId: number) {
    return FetchService.put<number>({ url: this.getMcaUrl(schoolId, "copy", mcaModelId) });
  }

  updateMca(schoolId: number, data: MCAModelUpdateDTO) {
    return FetchService.put<true>({ url: this.getMcaUrl(schoolId), data });
  }

  getSchoolSettings({ schoolId }: { schoolId: number }) {
    return FetchService.get<SchoolConfig>({ url: this.getSchoolUrl(schoolId, "config") });
  }

  updateSchoolSettings(schoolId: number, data: Partial<SchoolConfig>) {
    return FetchService.put<true>({
      url: this.getSchoolUrl(schoolId, "config"),
      data: _.pick(data, ["hideCreditLibrary", "excludeCreditsFromLibrary", "hideTranscriptPublish", "salesforceId"]),
    });
  }

  getSharedMcaModels({ schoolId, page = 1, limit = 8, filter, type = [] }: GetSharedMcaModelsParams) {
    return FetchService.get<OffsetBasedListResponse<MCAModel>>({
      url: this.getMcaUrl(schoolId, "shared"),
      data: {
        page,
        limit,
        filter,
        showMcaModels: type?.includes("showMcaModels") || undefined,
        showSchoolMca: type.includes("showSchoolMca") || undefined,
      },
    });
  }

  getActiveUsers(schoolId: number) {
    return FetchService.get<GetActiveUsersApiResponse>({
      url: this.getSchoolUrl(schoolId, "find", "active"),
    });
  }

  deleteArchivalSchool(schoolId: number) {
    return FetchService.delete<true>({
      url: this.getSchoolUrl(schoolId),
    });
  }

  getSchoolsWithCollege({ filter, limit = 8 }: PaginatedQueryParams) {
    return FetchService.get<OffsetBasedListResponse<SchoolWithCollege>>({
      url: this.getUrl("withcollege"),
      data: { filter, limit },
    });
  }

  getLearnersByCollege({
    schoolId,
    collegeId,
    graduationYear,
  }: {
    schoolId: number;
    collegeId: number;
    graduationYear: number;
  }) {
    return FetchService.get<{ items: CollegeLearner[] }>({
      url: this.getUrl(schoolId, "colleges", collegeId, "learners"),
      data: { graduationYear },
    });
  }

  getGraduationYears({ schoolId }: { schoolId: number }) {
    return FetchService.get<{ items: number[] }>({ url: this.getLearnersUrl(schoolId, "yog") });
  }

  exportSchoolAdmins({ status }: { status: UserStatus[] }) {
    return FetchService.get<Blob>({
      url: this.getUrl("admins", "xlsx"),
      data: { status },
      responseType: "blob",
    });
  }

  exportSchoolAdults() {
    return FetchService.get<Blob>({
      url: this.getUrl("adults", "xlsx"),
      responseType: "blob",
    });
  }

  getActivityCategoriesBySchool(schoolId: number) {
    return FetchService.get<EventCategory[]>({
      url: this.getUrl(schoolId, "categories"),
    });
  }

  searchSchoolStuff({ schoolId, filter, page, limit }: { schoolId: number | string } & PaginatedQueryParams) {
    return FetchService.get({
      url: this.getUrl(schoolId, "adults"),
      data: { filter, page, limit },
    });
  }

  archiveLearnersInvitationBulk(schoolId: number, users: { id: number }[], selectAll?: boolean) {
    return FetchService.put<true>({
      url: this.getLearnersUrl(schoolId, "invite/archive/bulk"),
      data: { users, selectAll },
    });
  }

  archiveEducatorsInvitationBulk(schoolId: number, users: { id: number }[], selectAll?: boolean) {
    return FetchService.put<true>({
      url: this.getEducatorUrl(schoolId, "invite/archive/bulk"),
      data: { users, selectAll },
    });
  }
}

export default new SchoolService();
