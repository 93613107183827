import _ from "lodash";
import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { creditAreasReceived, creditAreasRemoved, MCARecieved, MCARemoved } from "./sharedMCAActions";
import { creditAdded, creditRemoved } from "./creditsSlice";
import { creditAdded as growCreditAdded, creditRemoved as growCreditRemoved } from "redux/slices/growCreditsSlice";
import growService from "services/growService";
import { dispatchErrorPopup, dispatchPopup } from "redux/actions/popupActions";
import { restoreGrowCredit } from "redux/slices/growCreditsSlice";

export const STATE_KEY = "creditAreas";

export const creditAreasAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.position - b.position,
});

export const archiveGrowArea = createAsyncThunk(
  `${STATE_KEY}/growAreaArchived`,
  async ({ growAreaId, growCreditIds }) => {
    try {
      await growService.archiveGrowCreditArea({ growAreaId });
      dispatchPopup("success", {
        tag: "formResults.CREDIT_AREA_ARCHIVED",
      });
      return { growAreaId, growCreditIds };
    } catch (err) {
      dispatchErrorPopup(err);
    }
  }
);

export const restoreGrowArea = createAsyncThunk(
  `${STATE_KEY}/growAreaRestored`,
  async ({ growAreaId, growCreditIds }) => {
    try {
      await growService.restoreGrowCreditArea({ growAreaId });
      dispatchPopup("success", {
        tag: "formResults.CREDIT_AREA_RESTORED",
      });
      return { growAreaId, growCreditIds };
    } catch (error) {
      dispatchErrorPopup(error);
    }
  }
);

const initialState = creditAreasAdapter.getInitialState();

const areaFields = [
  "title",
  "description",
  "position",
  "archivedAt",
  "thresholdNumOfAdvancedCredits",
  "typicalNumOfAdvancedCredits",
];

const creditAreasSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    creditAreaAdded(state, action) {
      const { id, ...details } = action.payload;
      creditAreasAdapter.addOne(state, {
        id,
        credits: [],
        files: [],
        ..._.pick(details, areaFields),
      });
    },
    creditAreaRemoved: creditAreasAdapter.removeOne,
    creditAreaUpdated: {
      reducer: creditAreasAdapter.updateOne,
      prepare: ({ id, ...changes }) => ({
        payload: {
          id,
          changes: _.pick(changes, areaFields),
        },
      }),
    },
    creditAreasRepositioned: {
      reducer: creditAreasAdapter.updateMany,
      prepare: (changesArray) => ({
        payload: changesArray.map(({ id, position }) => ({
          id,
          changes: { position },
        })),
      }),
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(archiveGrowArea.fulfilled, (state, action) => {
        const { growAreaId } = action.payload;
        state.entities[growAreaId].archivedAt = new Date().toISOString();
      })
      .addMatcher(isAnyOf(MCARemoved, creditAreasRemoved), creditAreasAdapter.removeAll)
      .addMatcher(isAnyOf(creditAreasReceived, MCARecieved), (state, action) => {
        const { creditAreas } = action.payload.entities;
        if (creditAreas) {
          creditAreasAdapter.setAll(state, creditAreas);
        }
      })
      .addMatcher(isAnyOf(restoreGrowCredit.fulfilled, restoreGrowArea.fulfilled), (state, action) => {
        const { growAreaId } = action.payload;
        creditAreasAdapter.updateOne(state, { id: growAreaId, changes: { archivedAt: null } });
      })
      .addMatcher(isAnyOf(creditAdded, growCreditAdded), (state, action) => {
        const { creditAreaId, id } = action.payload;
        state.entities[creditAreaId].credits.push(id);
      })
      .addMatcher(isAnyOf(creditRemoved, growCreditRemoved), (state, action) => {
        _.forEach(state.entities, (area) => {
          area.credits = _.without(area.credits, action.payload);
        });
      }),
});

export const { creditAreaAdded, creditAreaRemoved, creditAreaUpdated, creditAreasRepositioned } =
  creditAreasSlice.actions;

export const {
  selectById,
  selectIds: selectCreditAreaIds,
  selectAll: selectCreditAreas,
  selectEntities: selectCreditAreaEntities,
  selectTotal: selectCreditAreasTotal,
} = creditAreasAdapter.getSelectors((state) => state.creditAreas);

export const selectCreditAreaById = (id) =>
  createSelector(
    (state) => selectById(state, id),
    (credit) => credit
  );

export default creditAreasSlice.reducer;
