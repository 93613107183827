import { useState } from "react";

import { Box, IconButton } from "@mui/material";
import { ViewOffIcon, ViewIcon } from "svg";
import { InputField } from "components/form/helpers/InputField";

export const PasswordField = (props) => {
  const [showPassword, togglePasswordShow] = useState(false);
  const toggleShow = () => togglePasswordShow(!showPassword);

  const Icon = showPassword ? ViewOffIcon : ViewIcon;
  const fieldType = showPassword ? "text" : "password";

  return (
    <Box position="relative">
      <InputField {...props} type={fieldType} />
      <Box position="absolute" top="18px" right="6px">
        <IconButton aria-label="settings" onClick={toggleShow}>
          <Icon />
        </IconButton>
      </Box>
    </Box>
  );
};
