import { Components } from "@mui/material";
import { blue } from "@mui/material/colors";

const { REACT_APP_ENABLE_CROWDIN } = process.env;

const components: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        height: "100%",
      },
      body: {
        height: "100%",
        fontSize: "0.875rem",
        lineHeight: 1.43,
        letterSpacing: "0.01071em",
      },
      "#root": {
        height: "100%",
      },
      code: {
        padding: "2px 4px",
        backgroundColor: "#f0f0f0",
        borderRadius: "4px",
        fontFamily: "Roboto",
      },
      "a:focus-visible": {
        outline: `2px solid ${blue[600]}`,
        outlineOffset: "-2px",
        boxShadow: "none",
      },
      "button:focus": {
        outline: "none",
      },
    },
  },
  MuiTooltip: {
    defaultProps: {
      // Show tooltips longer so we could translate them in crowdin version of our app
      leaveDelay: REACT_APP_ENABLE_CROWDIN ? 8000 : 0,
    },
    styleOverrides: {
      tooltip: {
        fontSize: "0.75rem",
      },
      tooltipPlacementBottom: {
        marginTop: "6px !important",
      },
      tooltipPlacementTop: {
        marginBottom: "6px !important",
      },
      tooltipPlacementLeft: {
        marginRight: "6px !important",
      },
      tooltipPlacementRight: {
        marginLeft: "6px !important",
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        "&.stroke": {
          stroke: "currentColor",
          fill: "none",
        },
      },
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        "&:focus": {
          outline: "none",
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontWeight: 500,
        fontSize: 14,
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      sizeSmall: {
        fontSize: 12,
        height: 22,
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      sizeSmall: {
        padding: 6,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      sizeSmall: {
        // fontSize: 12,
      },
    },
  },
  MuiDialog: {
    defaultProps: {
      // Now we can translate dialogs windows in crowdin version of our app
      disableEnforceFocus: Boolean(REACT_APP_ENABLE_CROWDIN),
    },
  },
  // MuiCardContent: {
  //   styleOverrides: {
  //     root: {
  //       "&:last-child": {
  //         paddingBottom: 16,
  //       },
  //     },
  //   },
  // },
  MuiAlert: {
    styleOverrides: {
      icon: {
        "& .MuiSvgIcon-root": {
          marginTop: 2,
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: 14,
      },
    },
  },
};

export default components;
