import BaseService from "services/baseService";
import FetchService from "services/fetchService";
import { OffsetBasedListResponse, PaginatedQueryParams } from "services/types";

type CollegeNudge = {
  name: string;
  startDate: string;
  endDate: string;
  graduationYear: number;
  id: number;
  graduatingLearners: number;
  views: number;
  reactions: number;
};

type CollegeNudgeParams = Pick<CollegeNudge, "name" | "startDate" | "endDate" | "graduationYear">;

type LearnersNudge = Omit<CollegeNudge, "graduatingLearners" | "views" | "reactions">;

type ReactionType = "TO_COLLEGES" | "UPDATED";

class NudgesService extends BaseService {
  servicePath = "nudges";

  getNudgesUrl(...params: (number | string)[]) {
    return this.getUrl("colleges", ...params);
  }

  getNudgeReactionsUrl(...params: (number | string)[]) {
    return this.getUrl("colleges/learners", ...params);
  }

  getNudges({ page = 1, limit = 8, sort = "name,asc", filter }: PaginatedQueryParams) {
    return FetchService.get<OffsetBasedListResponse<CollegeNudge>>({
      url: this.getNudgesUrl(),
      data: {
        page,
        limit,
        sort,
        filter,
      },
    });
  }

  createCollegeNudge(data: CollegeNudgeParams) {
    return FetchService.post<number>({ url: this.getNudgesUrl(), data });
  }

  editCollegeNudge(nudgeId: number, data: CollegeNudgeParams) {
    return FetchService.put<true>({ url: this.getNudgesUrl(nudgeId), data });
  }

  deleteCollegeNudge(nudgeId: number) {
    return FetchService.delete<true>({ url: this.getNudgesUrl(nudgeId) });
  }

  getLearnerCollegeNudges(learnerId: number) {
    return FetchService.get<OffsetBasedListResponse<LearnersNudge>>({ url: this.getNudgeReactionsUrl(learnerId) });
  }

  nudgeShown(learnerId: number, nudgeId: number) {
    return FetchService.put<true>({
      url: this.getNudgeReactionsUrl(learnerId, "shown"),
      data: { collegeNudgeId: nudgeId },
    });
  }

  updateNudgeReaction({
    learnerId,
    nudgeId,
    reaction,
  }: {
    learnerId: number;
    nudgeId: number;
    reaction: ReactionType;
  }) {
    return FetchService.put<true>({
      url: this.getNudgeReactionsUrl(learnerId, "reacted"),
      data: { collegeNudgeId: nudgeId, reactionType: reaction },
    });
  }
}

export default new NudgesService();
