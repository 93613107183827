import BaseService from "services/baseService";
import FetchService from "services/fetchService";
import { OffsetBasedListResponse, PaginatedQueryParams, User } from "services/types";

type Participant = Omit<User, "isDemoAccount">;

type Message = {
  id: number;
  content: string;
  user: Participant;
  createdAt: string;
  updatedAt: string;
};

type Conversation = {
  id: number;
  subject: string;
  createdAt: string;
  messages: Message[];
  participants: Participant[];
};

type ConversationPreview = Pick<Conversation, "id" | "subject" | "createdAt" | "participants"> & {
  hasUnreadMessages: boolean;
  latestMessage: Message;
};

class ConversationsService extends BaseService {
  servicePath = "conversations";

  createConversation({ subject, content, userIds }: { subject: string; content?: string; userIds: number[] }) {
    return FetchService.post<Conversation>({
      url: this.getUrl(),
      data: { subject, content, usersIds: userIds },
    });
  }

  getConversations({ userId, page, limit }: { userId: number } & Omit<PaginatedQueryParams, "sort">) {
    return FetchService.get<OffsetBasedListResponse<ConversationPreview>>({
      url: this.getUrl(),
      data: { userId, page, limit },
    });
  }

  getConversation({ conversationId, page, limit }: PaginatedQueryParams & { conversationId: number }) {
    return FetchService.get<Omit<OffsetBasedListResponse<Conversation>, "items"> & { items: Conversation }>({
      url: this.getUrl(conversationId),
      data: { page, limit },
    });
  }

  getConversationParticipants({ conversationId, filter }: { conversationId: number; filter: string }) {
    return FetchService.get<{ items: Participant[] }>({
      url: this.getUrl(conversationId, "participants"),
      data: { filter },
    });
  }

  editConversation(conversationId: number, { subject, userIds }: { subject?: string; userIds?: number[] }) {
    return FetchService.put<true>({
      url: this.getUrl(conversationId),
      data: { subject, userIds },
    });
  }

  getUnreadConversationsCount() {
    return FetchService.get<number>({ url: this.getUrl("withUnreadMessages") });
  }

  sendMessage(conversationId: number, content: string) {
    return FetchService.post<{ id: number }>({
      url: this.getUrl(conversationId, "messages"),
      data: { content },
    });
  }

  editMessage(messageId: number, content: string) {
    return FetchService.put<true>({
      url: this.getUrl("messages", messageId),
      data: { content },
    });
  }
}

export default new ConversationsService();
