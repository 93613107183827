import counterpart from "counterpart";

export const getLabel = (name, content, hideLabel) => {
  if (hideLabel) {
    return null;
  }

  const label = name.replace(/.*\[\d+\]\./, "");
  return counterpart.translate(content || `formLabels.${label}`);
};
