import { useSelector } from "react-redux";

import React from "react";
import Translate from "react-translate-component";

import { generatePath } from "react-router-dom";

import { Box, Grid, Toolbar } from "@mui/material";
import AccountMenu from "components/pageLayout/helpers/header/AccountMenu";
import MtcLogo from "../MtcLogo";
import { TextLink } from "components/links";
import { SkipLink, StyledAppBar } from "components/pageLayout/styles";
import { ChangeCurrentSchoolMenu } from "./ChangeCurrentSchoolMenu";

import { paths } from "routes";
import { UserRole } from "services/types";

const Header = React.memo(function Header() {
  const { schools, role, schoolId, id } = useSelector((state) => state.account);
  const showSchoolMenu = [UserRole.ROLE_SCHOOL_ADMIN, UserRole.ROLE_EDUCATOR].includes(role) && schools.length > 1;
  return (
    <>
      <StyledAppBar position="fixed" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <TextLink
                to={
                  role === UserRole.ROLE_LEARNER
                    ? generatePath(paths.LEARNER_PROFILE, { userId: id, schoolId })
                    : paths.DASHBOARD
                }
              >
                <MtcLogo />
              </TextLink>
            </Grid>
            {showSchoolMenu && (
              <Grid item xs>
                <Box display="flex" justifyContent="center">
                  <ChangeCurrentSchoolMenu />
                </Box>
              </Grid>
            )}
            <Grid item>
              <SkipLink href="#main-content">
                <Translate content="skipLinks.skipToContent" />
              </SkipLink>
            </Grid>
            <Grid item>
              <AccountMenu />
            </Grid>
          </Grid>
        </Toolbar>
      </StyledAppBar>
      <Toolbar />
    </>
  );
});

export default Header;
