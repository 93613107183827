import _ from "lodash";
import BaseService from "services/baseService";
import FetchService from "services/fetchService";
import authService from "./authService";
import store from "../redux/store";
import {
  AddLearnerCreditsProps,
  Course,
  Learner,
  LearnerCollege,
  LearnerCollegeStatus,
  LearnerCourse,
  LearnerCredit,
  LearnerCreditStatus,
  LearnerDetails,
  LearnerEvidence,
  LearnerEvidenceCredit,
  LearnerParent,
  MCACreditArea,
  Nullable,
  OffsetBasedListResponse,
  PaginatedQueryParams,
  UserRole,
} from "services/types";

class LearnerService extends BaseService {
  servicePath = "learners";

  getLearnerUrl(learnerId: number, ...rest: (string | number | boolean)[]) {
    if (!learnerId && authService.hasAnyAuthority([UserRole.ROLE_LEARNER])) {
      return this.getUrl(store.getState().account.id, ...rest);
    }

    return this.getUrl(learnerId, ...rest);
  }

  getCollegesUrl(learnerId: number, ...rest: (string | number)[]) {
    return this.getLearnerUrl(learnerId, "colleges", ...rest);
  }

  getCreditsUrl(learnerId: number, ...rest: (string | number)[]) {
    return this.getLearnerUrl(learnerId, "credits", ...rest);
  }

  getCoursesUrl(learnerId: number, ...rest: (string | number)[]) {
    return this.getLearnerUrl(learnerId, "courses", ...rest);
  }

  getCourseTranscriptUrl(learnerId: number, ...rest: (string | number)[]) {
    return this.getLearnerUrl(learnerId, "legacyTranscript", ...rest);
  }

  getParentsUrl(learnerId: number, ...rest: (string | number)[]) {
    return this.getLearnerUrl(learnerId, "parents", ...rest);
  }

  getLearnerDetails({ userId }: { userId: number }) {
    return FetchService.get<LearnerDetails>({ url: this.getLearnerUrl(userId) });
  }

  getLearnerStats({ userId }: { userId: number }) {
    return FetchService.get<{ courses: number; credits: number; evidences: number }>({
      url: this.getLearnerUrl(userId, "stats"),
    });
  }

  updateLearner(
    learnerId: number,
    data: Partial<
      Pick<
        Learner,
        | "firstName"
        | "lastName"
        | "preferredName"
        | "email"
        | "expirationDate"
        | "graduationYear"
        | "enrollmentDate"
        | "enrollmentEndDate"
        | "enrollmentEndType"
        | "graduationDate"
        | "externalId"
        | "dateOfBirth"
        | "street"
        | "street2"
        | "city"
        | "state"
        | "zip"
        | "country"
        | "overviewStatement"
      >
    >
  ) {
    return FetchService.put<true>({ url: this.getLearnerUrl(learnerId), data });
  }

  getCredits(learnerId: number) {
    return FetchService.get<OffsetBasedListResponse<MCACreditArea & { credits: LearnerCredit }>>({
      url: this.getCreditsUrl(learnerId),
      data: { page: 1, limit: 500 },
    });
  }

  addCredits({ learnerId, credits }: AddLearnerCreditsProps) {
    return FetchService.post<true>({
      url: this.getCreditsUrl(learnerId, "bulk"),
      data: { credits },
    });
  }

  addCreditsWithSteps({ learnerId, credits }: AddLearnerCreditsProps) {
    return FetchService.post<true>({
      url: this.getCreditsUrl(learnerId, "steps", "bulk"),
      data: { credits },
    });
  }

  updateCredit(learnerId: number, creditId: number, data: { status: LearnerCreditStatus; isFeatured: boolean }) {
    return FetchService.put<true>({
      url: this.getCreditsUrl(learnerId, creditId),
      data: _.pick(data, ["status", "isFeatured"]),
    });
  }

  removeCredits(learnerId: number, credits: { id: number }[], comment: string) {
    return FetchService.delete<true>({
      url: this.getCreditsUrl(learnerId, "bulk"),
      data: { credits, comment },
    });
  }

  getCoursesList(
    learnerId: number,
    { page, filter, limit, sort, state }: PaginatedQueryParams & { state?: "all" | "assigned" | "not_assigned" } = {}
  ) {
    return FetchService.get<OffsetBasedListResponse<LearnerCourse>>({
      url: this.getCoursesUrl(learnerId),
      data: { page, filter, limit, sort, state },
    });
  }

  addCourses(learnerId: number, courses: { id: number; completionDate: string }[]) {
    return FetchService.post<true>({
      url: this.getCoursesUrl(learnerId, "bulk"),
      data: { courses },
    });
  }

  deleteCourses(learnerId: number, courses: { id: number }[]) {
    return FetchService.delete<true>({
      url: this.getCoursesUrl(learnerId, "bulk"),
      data: { courses },
    });
  }

  updateCourse(learnerId: number, courseId: number, data: { completionDate: string }) {
    return FetchService.put<true>({
      url: this.getCoursesUrl(learnerId, courseId),
      data,
    });
  }

  getEvidences({ userId }: { userId: number }) {
    return FetchService.get<
      OffsetBasedListResponse<
        LearnerEvidence & {
          credits: LearnerEvidenceCredit[];
          courses: Course[];
        }
      >
    >({
      url: this.getLearnerUrl(userId, "evidences"),
      data: { limit: 500, page: 1 },
    });
  }

  createEvidence(
    learnerId: number,
    evidence: Pick<LearnerEvidence, "title" | "description"> & Partial<LearnerEvidence["isFeatured"]>
  ) {
    return FetchService.post<number>({
      url: this.getLearnerUrl(learnerId, "evidences"),
      data: evidence,
    });
  }

  updateEvidencePositions(learnerId: number, evidences: { id: number; position: number }[]) {
    return FetchService.put<true>({
      url: this.getLearnerUrl(learnerId, "evidences", "position"),
      data: evidences,
    });
  }

  assignColleges(
    learnerId: number,
    colleges: {
      id: number;
      accepted: boolean;
      status: LearnerCollegeStatus;
    }[]
  ) {
    return FetchService.post<true>({
      url: this.getCollegesUrl(learnerId, "bulk"),
      data: { colleges },
    });
  }

  getLearnerColleges({ userId }: { userId: number }) {
    return FetchService.get<LearnerCollege[]>({
      url: this.getCollegesUrl(userId),
    });
  }

  updateCollegeAssignment(
    learnerId: number,
    colleges: {
      id: number;
      accepted: boolean;
      status: LearnerCollegeStatus;
    }[]
  ) {
    return FetchService.put<true>({
      url: this.getCollegesUrl(learnerId, "bulk"),
      data: { colleges },
    });
  }

  deleteCollegesAssignment(learnerId: number, collegeIds: { collegeIds: number[] }) {
    return FetchService.delete<true>({
      url: this.getCollegesUrl(learnerId, "bulk"),
      data: { collegeIds },
    });
  }

  getLearnerStaff(learnerId: number, { filter, page, limit = 20, sort = "name,asc" }: PaginatedQueryParams) {
    return FetchService.get<
      OffsetBasedListResponse<{
        id: number;
        firstName: string;
        lastName: string;
        role: UserRole;
        photoUrl: Nullable<string>;
      }>
    >({
      url: this.getLearnerUrl(learnerId, "authorizedStaff"),
      data: { filter, page, limit, sort },
    });
  }

  requestCourses(learnerId: number, courseIds: number[]) {
    return FetchService.post({
      url: this.getCoursesUrl(learnerId, "requests", "bulk"),
      data: { courseIds },
    });
  }

  removeCourseRequest(learnerId: number, courseId: number, comment: string) {
    return FetchService.delete({
      url: this.getCoursesUrl(learnerId, courseId, "request"),
      data: { comment },
    });
  }

  approveCourseRequest(learnerId: number, courseId: number, completionDate: Date) {
    return FetchService.put({
      url: this.getCoursesUrl(learnerId, courseId, "request"),
      data: { completionDate },
    });
  }

  addParents(learnerId: number, parents: LearnerParent[]) {
    return FetchService.post({
      url: this.getParentsUrl(learnerId, "bulk"),
      data: { parents },
    });
  }

  removeParent(learnerId: number, email: string) {
    return FetchService.delete({
      url: this.getParentsUrl(learnerId),
      data: { email },
    });
  }

  attachCourseTranscriptFile(learnerId: number, file: FormData) {
    const url = this.getCourseTranscriptUrl(learnerId, "upload");
    return FetchService.post<string>({
      url,
      data: file,
    });
  }

  deleteCourseTranscriptFile(learnerId: number) {
    const url = this.getCourseTranscriptUrl(learnerId);
    return FetchService.delete<string>({ url });
  }

  downloadCaregivers(schoolId: number) {
    return FetchService.get<Blob>({
      url: this.getUrl("schools", schoolId, "caregiversExcelDownload"),
      responseType: "blob",
    });
  }

  uploadCaregivers(data: FormData) {
    return FetchService.post({
      url: this.getUrl("caregiversExcelUpload"),
      data,
    });
  }
}

export default new LearnerService();
export { LearnerService };
