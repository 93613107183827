import BaseService from "services/baseService";
import FetchService from "services/fetchService";
import { GrowFile } from "services/types";

class GrowFileService extends BaseService {
  servicePath = "grow/files";

  getFiles() {
    return FetchService.get<GrowFile[]>({ url: this.getUrl() });
  }

  markAsGrowFile(fileId: number) {
    return FetchService.post<true>({ url: this.getUrl(fileId) });
  }

  unmarkAsGrowFile(fileId: number) {
    return FetchService.delete<true>({ url: this.getUrl(fileId) });
  }
}

export default new GrowFileService();
