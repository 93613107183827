import counterpart from "counterpart";
import ach from "locales/ach.json";

// In-Context Localization for Web Apps - https://support.crowdin.com/in-context-localization/
function activateCrowdin() {
  const scriptJipt = document.createElement("script");

  scriptJipt.type = "text/javascript";
  scriptJipt.innerHTML = `
      const _jipt = [];
      _jipt.push(["project", "mastery-transcript"]);
  `;

  document.head.appendChild(scriptJipt);

  const scriptJiptCode = document.createElement("script");

  scriptJiptCode.type = "text/javascript";
  scriptJiptCode.src = "//cdn.crowdin.com/jipt/jipt.js";

  document.head.appendChild(scriptJiptCode);
}

function initCrowdin() {
  counterpart.registerTranslations("ach", ach);
  counterpart.setLocale("ach");

  activateCrowdin();
}

export { initCrowdin };
