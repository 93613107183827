class BaseService {
  readonly baseUrl: string;
  protected servicePath = "";

  constructor() {
    // this comparison be needed if we want to test localhost-ed app
    // on random devices: phones or tablets - located in same wifi space
    // basically we connect to same ip with another port (where our backend placed)
    const { protocol, hostname } = window.location;
    this.baseUrl = process.env.REACT_APP_API_BASE_URL || `${protocol}//${hostname}:8000`;
  }

  protected getBaseUrl() {
    return this.baseUrl;
  }

  protected getUrl(...segments: (string | number | boolean)[]): string {
    const basePaths = [this.baseUrl, this.servicePath];
    return basePaths.concat(segments.map(String)).join("/");
  }
}

export default BaseService;
