import _ from "lodash";
import { createAsyncThunk, createEntityAdapter, createSlice, isAnyOf, createSelector } from "@reduxjs/toolkit";
import growEvidencesService from "services/growEvidencesService";
import { GrowEvidenceStatus } from "services/types";
import { dispatchErrorPopup } from "../actions/popupActions";

export const STATE_KEY = "schoolGrowEvidence";

const schoolGrowEvidenceAdapter = createEntityAdapter({
  sortComparer: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
});

export const linkSchoolGrowCredits = createAsyncThunk(`${STATE_KEY}/linkToCredits`, async ({ evidenceId, credits }) =>
  growEvidencesService.linkEvidenceWithCredits(
    evidenceId,
    credits.map(({ id }) => ({ id }))
  )
);

export const unlinkSchoolGrowCredit = createAsyncThunk(`${STATE_KEY}/unlinkCredit`, async ({ evidenceId, creditId }) =>
  growEvidencesService.unlinkCreditsFromEvidence({ evidenceId, credits: [{ id: creditId }] })
);

export const updateSchoolGrowEvidence = createAsyncThunk(
  `${STATE_KEY}/updateEvidence`,
  async ({ id, ...data }, { rejectWithValue }) => {
    const details = _.pick(data, ["title", "description", "fileUrl"]);
    try {
      await growEvidencesService.updateGrowEvidence(id, details);
      return { id, changes: details };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSchoolGrowEvidenceStatus = createAsyncThunk(
  `${STATE_KEY}/updateEvidenceStatus`,
  async ({ evidenceId, status, comment }, { rejectWithValue }) => {
    try {
      await growEvidencesService.updateStatus({ evidenceId, status, comment });
      return { id: evidenceId, changes: { status } };
    } catch (error) {
      dispatchErrorPopup(error);
      return rejectWithValue(error);
    }
  }
);

export const addSchoolGrowEvidence = createAsyncThunk(
  `${STATE_KEY}/addEvidence`,
  async ({ schoolId, ...data }, { rejectWithValue, getState }) => {
    const currentUser = getState().account;
    const details = _.pick(data, ["title", "description", "fileUrl"]);
    try {
      const id = await growEvidencesService.createGrowSchoolEvidence(schoolId, details);
      return {
        ...details,
        schoolId,
        id,
        credits: [],
        status: GrowEvidenceStatus.PRIVATE,
        createdAt: new Date().toISOString(),
        user: _.pick(currentUser, ["id", "lastName", "firstName"]),
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const schoolGrowEvidenceSlice = createSlice({
  name: STATE_KEY,
  initialState: schoolGrowEvidenceAdapter.getInitialState(),
  reducers: {
    schoolGrowEvidencesRemoved: schoolGrowEvidenceAdapter.removeAll,
    schoolGrowEvidenceRemoved: schoolGrowEvidenceAdapter.removeOne,
    schoolGrowEvidencesReceived: (state, action) => {
      const evidences = action.payload;
      if (evidences) {
        schoolGrowEvidenceAdapter.setAll(state, evidences);
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(unlinkSchoolGrowCredit.fulfilled, (state, action) => {
        const { evidenceId, creditId } = action.meta.arg;
        state.entities[evidenceId].credits = state.entities[evidenceId].credits.filter(({ id }) => {
          return id !== creditId;
        });
      })
      .addCase(linkSchoolGrowCredits.fulfilled, (state, action) => {
        const { evidenceId, credits } = action.meta.arg;
        const newCredits = credits.map((credit) =>
          _.pick(credit, ["id", "title", "description", "position", "archivedAt"])
        );
        state.entities[evidenceId].credits = _.uniqBy(state.entities[evidenceId].credits.concat(newCredits), "id");
      })
      .addCase(addSchoolGrowEvidence.fulfilled, schoolGrowEvidenceAdapter.upsertOne)
      .addMatcher(
        isAnyOf(updateSchoolGrowEvidence.fulfilled, updateSchoolGrowEvidenceStatus.fulfilled),
        schoolGrowEvidenceAdapter.updateOne
      ),
});

export const { selectAll: selectSchoolGrowEvidences, selectById: selectSchoolGrowEvidenceById } =
  schoolGrowEvidenceAdapter.getSelectors((state) => state[STATE_KEY]);

export const selectSharedGrowEvidences = createSelector([selectSchoolGrowEvidences], (evidences) => {
  return evidences.filter(
    (e) => e.status === GrowEvidenceStatus.SHARED || e.status === GrowEvidenceStatus.SHARE_PENDING
  );
});

export const { schoolGrowEvidencesRemoved, schoolGrowEvidencesReceived, schoolGrowEvidenceRemoved } =
  schoolGrowEvidenceSlice.actions;

export default schoolGrowEvidenceSlice.reducer;
