import { Component } from "react";
import { connect } from "react-redux";

import { FullPageSpinner } from "components/pageLoading/FullPageSpinner";
import { AppDisplay } from "AppDisplay";

import AuthService from "services/authService";
import SystemService from "services/systemService";
import { saveDataToLocalStorage } from "utils";
import { login } from "redux/slices/accountSlice";
import { paths } from "routes";

class App extends Component {
  state = {
    loading: true,
  };

  async componentDidMount() {
    try {
      await SystemService.checkServerStatus();
    } catch (e) {
      if (window.location.pathname !== paths.PAGE_500) {
        this.savePath();
        window.location.href = paths.PAGE_500;
      }
    }

    window.addEventListener("storage", this.onLogoutEvent);
    window.addEventListener("storage", this.onLoginEvent);

    await this.handleLogin();

    this.setState({ loading: false });
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.onLogoutEvent);
    window.removeEventListener("storage", this.onLoginEvent);
  }

  handleLogin = async () => {
    try {
      const account = await AuthService.loadAccount();
      // save to redux
      const { dispatchLogin } = this.props;
      dispatchLogin(account);

      if (window.location.href.includes(paths.SIGN_IN)) {
        window.location.href = paths.DASHBOARD;
      }
    } catch (e) {
      this.savePath();
    }
  };

  onLoginEvent = async (event) => {
    if (event.key === "login-event") {
      this.setState({ loading: true });
      await this.handleLogin();
      this.setState({ loading: false });
    }
  };

  onLogoutEvent(event) {
    if (event.key === "logout-event") {
      window.location.href = paths.SIGN_IN;
    }
  }

  savePath = () => {
    const { pathname, search } = window.location;
    if (!pathname.includes("auth")) {
      saveDataToLocalStorage("redirectUrl", "pathname", pathname + search);
    }
  };

  render() {
    const { loading } = this.state;

    return loading ? <FullPageSpinner /> : <AppDisplay />;
  }
}

export default connect(null, { dispatchLogin: login })(App);
