import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import eventsService from "services/eventsService";
import _ from "lodash";

export const fetchActivities = createAsyncThunk("activities/fetch", async (params = {}) =>
  eventsService.getEvents(params)
);

export const fetchMore = createAsyncThunk("activities/fetchMore", async (params = {}) =>
  eventsService.getEvents(params)
);

export const STATE_KEY = "activity";

export const activitiesSlice = createSlice({
  name: STATE_KEY,
  initialState: {
    entities: [],
    error: null,
    hasMore: false,
    page: 1,
    loading: false,
    loadingMore: false, // just for fetchMore
    learnerFilter: {},
    courseFilter: {},
    creditFilter: {},
    evidenceFilter: {},
    initiatorFilter: {},
    collegeFilter: {},
    onlyShowActionRequired: false,
    typeItem: null,
  },
  reducers: {
    resetItems: (state) => {
      state.entities = [];
    },
    addEntityFilter: (state, action) => {
      _.forEach(action.payload, (value, key) => {
        state[key] = value;
      });
    },
    removeEntityFilter: (state, action) => {
      const typeMap = {
        learner: "learnerFilter",
        course: "courseFilter",
        credit: "creditFilter",
        evidence: "evidenceFilter",
        initiator: "initiatorFilter",
        college: "collegeFilter",
      };
      const key = typeMap[action.payload];
      state[key] = {};
    },
    eventActionCompleted: (state, action) => {
      const { eventId } = action.payload;
      const index = state.entities.findIndex((event) => event.id === eventId);
      if (index !== -1) {
        state.entities[index].actionCompleted = true;
      }
    },
    updateCreditStatus: (state, action) => {
      const { newStatus, eventId } = action.payload;
      const index = state.entities.findIndex((event) => event.id === eventId);
      state.entities[index].creditStatus = newStatus;
    },
    clearEntityFilters: (state) => {
      state.learnerFilter = {};
      state.courseFilter = {};
      state.creditFilter = {};
      state.evidenceFilter = {};
      state.initiatorFilter = {};
      state.collegeFilter = {};
      state.typeItem = null;
    },
    setOnlyShowActionRequired: (state, action) => {
      state.onlyShowActionRequired = action.payload;
    },
    addTaskAssignee: (state, action) => {
      const event = state.entities.find((e) => e.id === action.payload.eventId);
      if (event) {
        event.assignees = _.unionBy(event.assignees, [action.payload.assignee], "id");
      }
    },
    setCategoryType: (state, action) => {
      state.typeItem = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchActivities.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchActivities.fulfilled, (state, action) => {
        const { items, count } = action.payload;
        state.entities = items;
        state.error = null;
        state.loading = false;
        state.page = 1;
        state.hasMore = count.allItems > state.entities.length;
        state.count = count;
      })
      .addCase(fetchActivities.rejected, (state, action) => {
        state.loading = false;
        state.error = { ...action.error, tag: action.error.code };
      })
      .addCase(fetchMore.fulfilled, (state, action) => {
        const { items, count } = action.payload;
        state.page += 1;
        state.entities = [...state.entities, ...items];
        state.error = null;
        state.hasMore = count.allItems > state.entities.length;
        state.loadingMore = false;
      })
      .addCase(fetchMore.rejected, (state, action) => {
        state.error = { ...action.error, tag: action.error.code };
        state.loadingMore = false;
      })
      .addCase(fetchMore.pending, (state) => {
        state.loadingMore = true;
      }),
});

export const {
  setOnlyShowActionRequired,
  addEntityFilter,
  removeEntityFilter,
  eventActionCompleted,
  clearEntityFilters,
  addTaskAssignee,
  setCategoryType,
} = activitiesSlice.actions;

export default activitiesSlice.reducer;
