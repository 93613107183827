import _ from "lodash";
import { createEntityAdapter, createSlice, isAnyOf, createSelector } from "@reduxjs/toolkit";

import {
  createConversation,
  fetchConversationById,
  fetchConversations,
  fetchMoreConversations,
  fetchMoreMessages,
} from "./sharedConversationsActions";
import { updateConversation } from "./conversationsSlice";

export const usersAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.firstName.localeCompare(b.firstName),
});

export const STATE_KEY = "users";

const usersSlice = createSlice({
  name: STATE_KEY,
  initialState: usersAdapter.getInitialState(),
  reducers: {
    userReceived: usersAdapter.upsertOne,
    userUpdated: {
      reducer: usersAdapter.updateOne,
      prepare: (id, changes) => ({ payload: { id, changes } }),
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(updateConversation.fulfilled, (state, action) => {
        const { users = [] } = action.meta.arg;
        if (users.length) {
          usersAdapter.upsertMany(state, users);
        }
      })
      .addMatcher(
        isAnyOf(
          fetchConversationById.fulfilled,
          fetchConversations.fulfilled,
          fetchMoreConversations.fulfilled,
          createConversation.fulfilled,
          fetchMoreMessages.fulfilled
        ),
        (state, action) => {
          if (action.payload.entities?.users) {
            usersAdapter.upsertMany(state, action.payload.entities.users);
          }
        }
      ),
});

export const { userReceived, userUpdated } = usersSlice.actions;

export const { selectById: selectUserById, selectEntities } = usersAdapter.getSelectors((state) => state.users);

export const selectUsersByIds = createSelector([selectEntities, (_state, userIds) => userIds], (users, userIds) => {
  return _.map(userIds, (id) => users[id]);
});

export default usersSlice.reducer;
