import { createTheme } from "@mui/material/styles";

import components from "./components";
import { typography } from "./typography";
import { palette } from "./palette";

const theme = createTheme({
  spacing: 4,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1440,
    },
  },
  components,
  typography,
  palette,
});

export default theme;
