import { createAction } from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import { Schemas } from "./schema";

export const MCARecieved = createAction("MCAs/MCARecieved", (MCA) => {
  return { payload: normalize(MCA, Schemas.MCA) };
});

export const MCARemoved = createAction("MCAs/MCARemoved");

export const creditAreasReceived = createAction("creditAreas/areasReceived", (areas) => {
  return { payload: normalize(areas, [Schemas.CREDIT_AREA]) };
});

export const creditAreasRemoved = createAction("creditAreas/areasRemoved");
