import BaseService from "services/baseService";
import FetchService from "services/fetchService";
import { SchoolAcademicYearType, SchoolFeature, SchoolSendingType, UserRole, UserStatus } from "services/types";
import { removeDataFromLocalStorage } from "utils";

export type AuthUser = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: UserRole;
  title: string | null;
  authorities: UserRole[];
  photoUrl: string | null;
  status: UserStatus;
  expirationDate: string | null;
  schoolId: number | null;
  schoolName: string | null;
  schoolAcademicYearType: SchoolAcademicYearType | null;
  schoolFeatures: SchoolFeature[];
  sendingType: SchoolSendingType | null;
  schools: {
    id: number;
    name: string | null;
    academicYearType: SchoolAcademicYearType | null;
    features: SchoolFeature[];
    sendingType: SchoolSendingType;
  }[];
};

class AuthService extends BaseService {
  static account: AuthUser | undefined;

  servicePath = "auth";

  async lookupEmail({ email }: { email: string }) {
    return FetchService.post<true>({
      url: this.getUrl("lookup"),
      data: { email: email },
      needAuth: false,
    });
  }

  async lookupInvite({ inviteCode }: { inviteCode: string }) {
    return FetchService.post<{ email: string; firstName: string; lastName: string }>({
      url: this.getUrl("invite/lookup"),
      data: { inviteCode },
      needAuth: false,
    });
  }

  async acceptInvite({
    inviteCode,
    firstName,
    lastName,
    password,
  }: {
    inviteCode: string;
    firstName: string;
    lastName: string;
    password: string;
  }) {
    return FetchService.post<true>({
      url: this.getUrl("invite/accept"),
      data: { inviteCode, firstName, lastName, password },
      needAuth: false,
    });
  }

  resendInvite(email: string) {
    return FetchService.post<true>({
      url: this.getUrl("invite/resend"),
      data: { email },
    });
  }

  restorePassword(email: string) {
    return FetchService.post<true>({ url: this.getUrl("recovery/initiate"), data: { email } });
  }

  passwordReset(data: { password: string; recoveryCode: string }) {
    return FetchService.post<true>({ url: this.getUrl("recovery/reset"), data });
  }

  cancelPasswordReset(data: { recoveryCode: string }) {
    return FetchService.post<true>({ url: this.getUrl("recovery/cancel"), data });
  }

  async login({ email, password }: { email: string; password: string }) {
    const reqData = {
      url: this.getUrl("login"),
      data: { email, password },
      needAuth: false,
    };

    removeDataFromLocalStorage("loggedAs"); // remove "loggedAs" just in case

    await FetchService.post<string>(reqData);

    return await this.loadAccount();
  }

  async loadAccount() {
    AuthService.account = await FetchService.get<AuthUser>({ url: this.getBaseUrl() + "/account" });
    return AuthService.account;
  }

  getAccount() {
    return AuthService.account;
  }

  hasAnyAuthority(authorities: UserRole[]) {
    // no authorities required <=> access granted
    if (!authorities) return true;
    if (Array.isArray(authorities) && authorities.length === 0) return true;

    const account = this.getAccount();

    if (!account || !Array.isArray(account.authorities) || !account.authorities.length) {
      return false;
    }

    return authorities.some((a) => account.authorities.includes(a));
  }

  getStorage() {
    // TODO: implement remember me
    const rememberMe = true;
    if (rememberMe) {
      return localStorage;
    }
    return sessionStorage;
  }

  getAuthToken() {
    return this.getStorage().getItem("authToken");
  }

  setAuthToken(token: string) {
    this.getStorage().setItem("authToken", token);
    localStorage.setItem("login-event", `logout${Math.random()}`);
  }

  removeAuthToken() {
    sessionStorage.removeItem("authToken");
    removeDataFromLocalStorage("authToken");
    localStorage.setItem("logout-event", `logout${Math.random()}`);
  }

  getAuthHeader() {
    return { headers: { Authorization: this.getAuthToken() } };
  }

  logout() {
    FetchService.get({ url: this.getUrl("logout") });
    removeDataFromLocalStorage("loggedAs");
    // clear filters
    removeDataFromLocalStorage("filters");
    // inform system of logout event
    this.removeAuthToken();

    AuthService.account = undefined;
  }

  cleverSignIn(code: string) {
    return FetchService.post<{ token: string; cleverToken: string; user: unknown }>({
      url: this.getUrl("login/clever"),
      data: { code },
    });
  }

  googleSignIn(token: string) {
    return FetchService.post<string>({
      url: this.getUrl("login/google"),
      data: { token },
    });
  }

  lmsSignIn(token: string) {
    return FetchService.post<string>({
      url: this.getUrl("login/lti"),
      data: { token },
    });
  }

  loginAs(userId: number) {
    return FetchService.get<string>({ url: this.getUrl("loginAs"), data: { userId } });
  }

  logoutAs() {
    return FetchService.get<string>({ url: this.getUrl("logoutAs") });
  }
}

export default new AuthService();
