import { DependencyList, useCallback, useEffect, useRef } from "react";

type UseDebounceFnReturn = [() => boolean | null, () => void];

export const useDebounce = (fn: () => void, ms = 0, deps: DependencyList = []): UseDebounceFnReturn => {
  const ready = useRef<boolean | null>(false);
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const callback = useRef(fn);

  const isReady = useCallback(() => ready.current, []);

  const set = useCallback(() => {
    ready.current = false;
    timeout.current && clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      ready.current = true;
      callback.current();
    }, ms);
  }, [ms]);

  const clear = useCallback(() => {
    ready.current = false;
    timeout.current && clearTimeout(timeout.current);
  }, []);

  // update ref when function changes
  useEffect(() => {
    callback.current = fn;
  }, [fn]);

  // set on mount, clear on unmount
  useEffect(() => {
    set();

    return clear;
  }, [clear, ms, set]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(set, deps);

  return [isReady, clear];
};
