import { useCallback, useState } from "react";
import { dispatchErrorPopup, dispatchPopup } from "redux/actions/popupActions";
import { getFileNameWithoutExt } from "utils";
import { DropzoneOptions, FileRejection, useDropzone } from "react-dropzone";

enum StatusKind {
  IDLE,
  SUCCEEDED,
  LOADING,
  FAILED,
}

type UploadedFile = {
  fileUrl: string;
  description: string | null;
  name: string;
};

type useFileUploadProps = {
  onUpload: (formData: FormData) => Promise<string>;
  onUploadSuccess: () => void;
  options?: DropzoneOptions;
};

export const useFileUpload = ({ onUpload, onUploadSuccess, options }: useFileUploadProps) => {
  const [file, setFile] = useState<UploadedFile | null>(null);
  const [status, setStatus] = useState<StatusKind>(StatusKind.IDLE);

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length) {
        const { message } = rejectedFiles[0].errors[0];
        dispatchPopup("error", { tag: "errorsList.FILE_UPLOAD_ERROR", additionalData: { message } });
        return;
      }

      const [file] = acceptedFiles;
      try {
        setStatus(StatusKind.LOADING);
        const formData = new FormData();
        formData.append("file", file);

        const response = await onUpload(formData);
        setStatus(StatusKind.SUCCEEDED);
        setFile({ fileUrl: response, description: null, name: getFileNameWithoutExt(file.name) });
        onUploadSuccess?.();
      } catch (error) {
        setStatus(StatusKind.FAILED);
        dispatchErrorPopup(error);
      }
    },
    [onUpload, onUploadSuccess]
  );

  const isLoading = status === StatusKind.LOADING;

  const dropzoneProps = useDropzone({
    multiple: false,
    accept: {
      "image/*": [],
      "video/*": [],
      "application/pdf": [".pdf"],
    },
    noClick: true,
    noKeyboard: true,
    maxSize: 1024 * 1024 * 50,
    disabled: isLoading,
    useFsAccessApi: false,
    ...options,
    onDrop,
  });

  return {
    ...dropzoneProps,
    file,
  };
};
