import { dispatchErrorPopup, dispatchPopup } from "redux/actions/popupActions";

export const makeApiRequest = (request, onSuccess, notification, onFail) =>
  request()
    .then(() => {
      onSuccess && onSuccess();

      if (notification) {
        if (typeof notification === "object") {
          dispatchPopup("success", notification);
        } else {
          dispatchPopup("success", { tag: notification });
        }
      }
    })
    .catch((e) => {
      onFail && onFail();
      dispatchErrorPopup(e);
    });

export const loadTimeout = (m) =>
  new Promise((r) => {
    setTimeout(r, m);
  });
