import { privateRoutes } from "routes/routesConfig";
import { useSelector } from "react-redux";

const flattenRoutes = (routes) => {
  const iter = (routes, nestingLevel = 0) => {
    return routes.reduce((arr, route) => {
      //  mark route as category (for breadcrumbs and menu)
      route.category = nestingLevel === 0;
      if (route.children) {
        return arr.concat(route, iter(route.children, nestingLevel + 1));
      }
      return arr.concat(route);
    }, []);
  };

  return iter(routes);
};

export const useUserRoutes = () => {
  const role = useSelector((state) => state.account.role);
  const flatRoutes = flattenRoutes(privateRoutes);

  return flatRoutes.filter(({ disabled, authorities }) => !disabled && authorities.includes(role));
};
