import { RefObject, useEffect, useMemo, useState } from "react";

export const useIsVisible = (
  ref: RefObject<Element>,
  rootElement?: Element | null,
  rootMargin?: number,
  threshold?: number | number[]
) => {
  const [isVisible, setVisible] = useState(false);
  const options = useMemo(
    () => ({
      root: rootElement || document,
      rootMargin: `${rootMargin || 0}px`,
      threshold: threshold ?? 0,
    }),
    [rootElement, rootMargin, threshold]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting);
    }, options);

    if (ref.current) observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [options, ref]);

  return isVisible;
};
