import _ from "lodash";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import multiSchoolService from "services/multiSchoolService";
import { paths } from "routes";
import { dispatchErrorPopup, dispatchPopup } from "redux/actions/popupActions";
import { generatePath } from "react-router-dom";

export const activeSchoolChanged = createAsyncThunk(
  "account/changeActiveSchool",
  async ({ schoolId, navigate }, { getState, rejectWithValue }) => {
    try {
      const { account } = getState();
      const { schools } = account;
      await multiSchoolService.selectSchoolForUser(account.id, schoolId);

      const url = generatePath(paths.SCHOOL, { schoolId });
      navigate(url, { replace: true });

      const activeSchool = _.find(schools, { id: schoolId });
      dispatchPopup("success", {
        tag: "schoolAdmins.successSchoolChange",
        additionalData: { schoolName: activeSchool.name },
      });
      return schoolId;
    } catch (err) {
      dispatchErrorPopup(err);
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  id: null,
  schoolId: null,
  authorities: [],
  role: "",
  firstName: "",
  lastName: "",
};

export const STATE_KEY = "account";

const accountSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    login(state, action) {
      return action.payload;
    },
    logout() {
      return initialState;
    },
    accountUpdated(state, action) {
      const values = _.pick(action.payload, [
        "email",
        "firstName",
        "lastName",
        "title",
        "receiveEmailDigest",
        "photoUrl",
        "schoolId",
        "schoolName",
      ]);
      return { ...state, ...values };
    },
  },
  extraReducers: (builder) =>
    builder.addCase(activeSchoolChanged.fulfilled, (state, action) => {
      const activeSchool = state.schools.find((school) => school.id === action.payload);
      state.schoolId = action.payload;
      state.schoolName = activeSchool.name;
      state.schoolAcademicYearType = activeSchool.academicYearType;
      state.schoolFeatures = activeSchool.features;
    }),
});

export const { login, logout, accountUpdated } = accountSlice.actions;

export default accountSlice.reducer;
