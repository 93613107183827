import { FormControl, FormLabel, FormGroup } from "@mui/material";
import { handleFieldData } from "components/form/actions";
import { CheckboxInput } from "./CheckboxInput";
import counterpart from "counterpart";

export const CheckboxGroup = ({ title, translatePrefix, checkboxes, ...props }) => {
  const { name: groupName, value: checkedValues, handleChanges } = handleFieldData(props);

  const handleCheckboxGroupChange = ({ target }) => {
    const { name, value } = target;
    handleChanges({
      target: {
        name: groupName,
        value: value ? [...checkedValues, name] : checkedValues.filter((n) => n !== name),
      },
    });
  };
  return (
    <FormControl component="fieldset" fullWidth margin="normal">
      {title && <FormLabel component="legend">{counterpart.translate(title)}</FormLabel>}
      <FormGroup>
        {checkboxes.map((item) => {
          const value = item?.value || item;
          const label = item?.label || item;

          return (
            <CheckboxInput
              key={value}
              name={value}
              value={checkedValues.includes(value)}
              handleChanges={handleCheckboxGroupChange}
              label={translatePrefix && `${translatePrefix}.${label}`}
              text={!translatePrefix && value}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};
