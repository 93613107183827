import { AppContent, Root } from "components/pageLayout/styles";
import { Notification } from "components/Notification";
import { ModalWindow } from "components/modal/helpers/ModalWindow";

export const TranscriptLayout = ({ children }) => (
  <Root>
    <AppContent>{children}</AppContent>
    <ModalWindow />
    <Notification />
  </Root>
);
