import { CalendarIcon } from "svg";
import { format } from "date-fns";

import { getHelperText, getLabel, handleFieldData } from "../actions";
import { Field } from "components/styledComponents/StyledForms";
import { DatePicker } from "@mui/x-date-pickers";

const MIN_DATE = new Date("1900-01-01");
const MAX_DATE = new Date("2099-12-31");

export const mapDatePickerError = (reason, maxDate = MAX_DATE, minDate = MIN_DATE) => {
  switch (reason) {
    case "invalidDate":
      return { tag: "DATE.INVALID" };
    case "disablePast":
      return { tag: "DATE.MIN", data: { limit: format(Date.now(), "P") } };
    case "minDate":
      return { tag: "DATE.MIN", data: { limit: format(minDate, "P") } };
    case "maxDate":
      return { tag: "DATE.MAX", data: { limit: format(maxDate, "P") } };
    case "disableFuture":
      return { tag: "DATE.MAX", data: { limit: format(Date.now(), "P") } };
    default:
      return null;
  }
};

const popperProps = {
  placement: "bottom-start",
  modifiers: [
    { name: "flip", enabled: true },
    // {
    //   name: "preventOverflow",
    //   enabled: true,
    //   options: {
    //     rootBoundary: "viewport",
    //     padding: 8,
    //   },
    // },
    {
      name: "offset",
      options: {
        offset: [-44, 8],
      },
    },
  ],
};

export const MtcDatePicker = ({ pickerProps, ...props }) => {
  const { id, name, helperText, value, hideLabel, handleChanges, marginDisabled, label, error, disabled, required } =
    handleFieldData(props);

  const onChange = (date) => handleChanges({ target: { value: date, name } }, true);

  const renderInput = (inputProps) => (
    <Field
      {...inputProps}
      fullWidth
      margin={marginDisabled ? "none" : "normal"}
      helperText={getHelperText(error, name, helperText)}
      error={Boolean(error)}
      required={required}
      id={id}
    />
  );

  return (
    <DatePicker
      disabled={disabled}
      value={value || null}
      label={getLabel(name, label, hideLabel)}
      onChange={onChange}
      views={["year", "month", "day"]}
      PopperProps={popperProps}
      allowSameDateSelection
      PaperProps={{ elevation: 2 }}
      components={{ OpenPickerIcon: CalendarIcon }}
      OpenPickerButtonProps={{ sx: { svg: { fontSize: 20 } } }}
      InputAdornmentProps={{ position: "start" }}
      renderInput={renderInput}
      {...pickerProps}
      defaultCalendarMonth={pickerProps?.minDate || new Date()}
      minDate={pickerProps?.minDate || MIN_DATE}
      maxDate={pickerProps?.maxDate || MAX_DATE}
    />
  );
};
