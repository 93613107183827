const isBrowser = typeof window !== "undefined";

export const getDataFromLocalStorage = (storeName: string) => {
  try {
    if (!isBrowser) return null;

    const value = localStorage.getItem(storeName);
    return value ? JSON.parse(value) : null;
  } catch (err) {
    return null;
  }
};

export const saveDataToLocalStorage = (storeName: string, key: string, data: unknown) => {
  if (!isBrowser) return;

  const prevStoreData = getDataFromLocalStorage(storeName);
  localStorage.setItem(storeName, JSON.stringify({ ...prevStoreData, [key]: data }));
};

export const removeDataFromLocalStorage = (storeName: string) => {
  if (!isBrowser) return;

  localStorage.removeItem(storeName);
};
