import { configureStore } from "@reduxjs/toolkit";
import { Schemas } from "redux/slices/schema";

import rootReducer from "../reducers";
import { logout } from "../slices/accountSlice";

const IS_DEVELOPMENT = process.env.NODE_ENV === "development";

const store = configureStore({
  reducer: (state, action) => {
    if (action.type === logout.type) {
      // eslint-disable-next-line no-param-reassign
      state = undefined;
    }
    return rootReducer(state, action);
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: true,
      // When we show modal dialog we pass a component in payload, so it doesn't make much sense to use this middleware
      // We can turn it on if we refactor how we handle modal dialogs
      serializableCheck: false,
      thunk: { extraArgument: { Schemas } },
    }),
  devTools: IS_DEVELOPMENT,
  // if we'll decide to use rtk-query, then we need to add middlewares
  //  .concat(mtcApi.middleware, transformResponse)
});

if (IS_DEVELOPMENT && module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept("../reducers", () => {
    store.replaceReducer(rootReducer);
  });
}

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
