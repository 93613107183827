import React from "react";
import Translate, { TranslateProps } from "react-translate-component";

import { Tooltip, TooltipProps } from "@mui/material";

type TooltipI18nProps = Omit<TooltipProps, "title"> &
  Pick<TranslateProps, "with" | "content"> & {
    disablePortal?: boolean;
    /**
     *  wrap prop is for elements that cannot hold a ref like svg
     */
    wrap?: boolean;
  };

export const TooltipI18n = React.memo(function TooltipI18n({
  children,
  with: translateWith,
  content,
  wrap,
  disablePortal,
  open,
  ...props
}: TooltipI18nProps) {
  return (
    <Tooltip
      {...props}
      PopperProps={{ disablePortal }}
      title={open ? "" : <Translate with={translateWith || {}} content={content} />}
    >
      {wrap ? <span>{children}</span> : children}
    </Tooltip>
  );
});
