import { dispatchPopup } from "redux/actions/popupActions";
import mtcTeamService from "services/mtcTeamService";
import schoolService from "../services/schoolService";
import { UserRole } from "services/types";

const { ROLE_SUPER_ADMIN, ROLE_SCHOOL_ADMIN, ROLE_SUPPORT_ADMIN, ROLE_EDUCATOR, ROLE_LEARNER, ROLE_TEAMMATE } =
  UserRole;

const actions = {
  [ROLE_SUPER_ADMIN]: ({ email }) => mtcTeamService.reinvite({ email }),
  [ROLE_SUPPORT_ADMIN]: ({ email }) => mtcTeamService.reinvite({ email }),
  [ROLE_TEAMMATE]: ({ email }) => mtcTeamService.reinvite({ email }),
  [ROLE_SCHOOL_ADMIN]: ({ schoolId, email }) => schoolService.reinviteAdmin(schoolId, { email }),
  [ROLE_EDUCATOR]: ({ schoolId, id }) => schoolService.inviteEducators(schoolId, [{ id }]),
  [ROLE_LEARNER]: ({ schoolId, id }) => schoolService.inviteLearners(schoolId, [{ id }]),
};

export const reinviteUser = async (user, role) => {
  let reinviteResult = `formResults.USER_INVITED`;
  let popupType = "success";

  try {
    await actions[role](user);
  } catch ({ code, ...rest }) {
    reinviteResult = `errorsList.${code}`;
    popupType = "error";
  }

  dispatchPopup(popupType, { tag: reinviteResult, additionalData: user });
};
