import { lazy } from "react";

import paths from "./paths";
import { TranscriptLayout } from "layouts/TranscriptLayout";
import AuthLayout from "layouts/AuthLayout";
import CleverLayout from "layouts/CleverLayout";
import ImportPageLayout from "layouts/ImportPageLayout";
import { MTCAdminRoles, schoolStaff, MTCTeamRoles, UserRole } from "services/types";

// Dashboards
const Dashboard = lazy(() => import("screens/Dashboard"));

// User profile
const UserProfile = lazy(() => import("screens/UserProfile"));

// Public user profile
const PublicUserProfile = lazy(() => import("screens/PublicUserProfile"));

// Course library
const CourseLibrary = lazy(() => import("screens/CourseLibrary"));

// Colleges library
const Colleges = lazy(() => import("screens/Colleges"));

// Manage Nudges
const ManageCollegeNudges = lazy(() => import("screens/ManageCollegeNudges"));

// All school admins in system
const AllSchoolAdmins = lazy(() => import("screens/AllSchoolAdmins"));

// MCA library
// TODO rename to MCALibrary
const MCAs = lazy(() => import("screens/MCAs"));
const MCAImportWarnings = lazy(() => import("screens/MCAImportWarnings"));
const MCA = lazy(() => import("screens/MCA"));

// Credit library
const CreditLibrary = lazy(() => import("screens/CreditLibrary"));

// Credit areas library
const CreditAreas = lazy(() => import("screens/CreditAreas"));

// MTC team
const MTCTeam = lazy(() => import("screens/MTCTeam"));
const MTCTeamMemberProfile = lazy(() => import("screens/MTCTeamMemberProfile"));

// School
const School = lazy(() => import("screens/School"));
const Schools = lazy(() => import("screens/Schools"));
const SchoolEdit = lazy(() => import("screens/SchoolEdit"));

// School colleges
const SchoolColleges = lazy(() => import("screens/SchoolColleges"));

// School admins
const SchoolAdmins = lazy(() => import("screens/SchoolAdmins"));
const SchoolAdminProfile = lazy(() => import("screens/SchoolAdminProfile"));
const SchoolEvents = lazy(() => import("screens/SchoolEvents"));

// School courses
const SchoolCourses = lazy(() => import("screens/SchoolCourses"));
const SchoolCoursesImport = lazy(() => import("screens/SchoolCoursesImport"));

const SchoolExperiencesImport = lazy(() => import("screens/SchoolExperiencesImport"));

// School MCA
const SchoolMCA = lazy(() => import("screens/SchoolMCA"));
const SchoolMCAImportWarnings = lazy(() => import("screens/SchoolMCAImportWarnings"));

// Shared MCA library
const SharedMCAs = lazy(() => import("screens/SharedMCAs"));
const SharedMCA = lazy(() => import("screens/SharedMCA"));

// Educators
const Educators = lazy(() => import("screens/Educators"));
const EducatorsImport = lazy(() => import("screens/EducatorsImport"));
const EducatorProfile = lazy(() => import("screens/EducatorProfile"));
const EducatorEdit = lazy(() => import("screens/EducatorEdit"));

// Learners
const Learners = lazy(() => import("screens/Learners"));
const LearnersImport = lazy(() => import("screens/LearnersImport"));
const LearnerProfile = lazy(() => import("screens/LearnerProfile"));
const LearnerEdit = lazy(() => import("screens/LearnerEdit"));
const LearnerColleges = lazy(() => import("screens/LearnerColleges"));

// College Learners
const CollegeLearners = lazy(() => import("screens/CollegeLearners"));

// Evidences
const Evidence = lazy(() => import("screens/Evidence"));
const EvidenceCompare = lazy(() => import("screens/EvidenceCompare"));

// Transcript
const TranscriptV2 = lazy(() => import("screens/TranscriptV2"));

// Learning Record
const LearningRecord = lazy(() => import("screens/LearningRecord"));

//Progress report
const ProgressReport = lazy(() => import("screens/ProgressReport"));

// Community groups
const MTCTeamGroups = lazy(() => import("screens/CommunityGroups/MTCTeamGroups"));
const SchoolStaffGroups = lazy(() => import("screens/CommunityGroups/SchoolStaffGroups"));
const CommunityDiscoverGroups = lazy(() => import("screens/CommunityDiscoverGroups"));
const CommunityUserGroups = lazy(() => import("screens/CommunityUserGroups"));

const CommunityGroup = lazy(() => import("screens/CommunityGroup"));

// Feed
const LiveFeed = lazy(() => import("screens/LiveFeed"));
const Post = lazy(() => import("screens/Post"));
const GroupPost = lazy(() => import("screens/GroupPost"));

// Media Library
const MediaLibrary = lazy(() => import("screens/MediaLibrary"));
const FilePage = lazy(() => import("screens/File"));
const FolderPage = lazy(() => import("screens/Folder"));
const FavoritesPage = lazy(() => import("screens/FavoriteResources"));

const MTCGrow = lazy(() => import("screens/MTCGrow"));
const GrowEvidence = lazy(() => import("screens/GrowEvidence"));

const SchoolGrow = lazy(() => import("screens/SchoolGrow"));
const SchoolGrowCredit = lazy(() => import("screens/SchoolGrowCredit"));

// Unauthenticated routes
const publicRoutes = [
  {
    path: paths.SIGN_IN,
    component: lazy(() => import("screens/SignIn")),
    layout: AuthLayout,
  },
  {
    path: paths.CLEVER_SIGN_IN,
    component: lazy(() => import("screens/CleverSignIn")),
    layout: AuthLayout,
  },
  {
    path: paths.CLEVER_SIGN_IN_REQUEST,
    component: lazy(() => import("screens/CleverMembershipRequest")),
    layout: CleverLayout,
  },
  {
    path: paths.CLEVER_SIGN_IN_STUDENT,
    component: lazy(() => import("screens/CleverSignInStudent")),
    layout: AuthLayout,
  },
  {
    path: paths.LMS_SIGN_IN,
    component: lazy(() => import("screens/LMSSignIn")),
    layout: AuthLayout,
  },
  {
    path: paths.ACCEPT_INVITE,
    component: lazy(() => import("screens/AcceptInvite")),
    layout: AuthLayout,
  },
  {
    path: paths.RESET_PASSWORD,
    component: lazy(() => import("screens/ResetPassword")),
    layout: AuthLayout,
  },
  {
    path: paths.RESET_PASSWORD_SUCCESS,
    component: lazy(() => import("screens/ResetPasswordSuccess")),
    layout: AuthLayout,
  },
  {
    path: paths.PASSWORD_RECOVERY,
    component: lazy(() => import("screens/PasswordRecovery")),
    layout: AuthLayout,
  },
  {
    path: paths.PASSWORD_RECOVERY_CANCEL,
    component: lazy(() => import("screens/PasswordRecoveryCancel")),
    layout: AuthLayout,
  },
  {
    path: paths.PAGE_500,
    component: lazy(() => import("screens/Page500")),
    layout: AuthLayout,
  },
  {
    path: paths.UNSUBSCRIBE,
    component: lazy(() => import("screens/Unsubscribe")),
    layout: AuthLayout,
  },
];

const globalRoutes = [
  {
    path: paths.DASHBOARD,
    tag: "dashboard",
    component: Dashboard,
    authorities: [UserRole.ROLE_SUPER_ADMIN, UserRole.ROLE_SUPPORT_ADMIN, UserRole.ROLE_TEAMMATE],
    children: [
      {
        tag: "userProfile",
        path: paths.USER_PROFILE,
        component: UserProfile,
        exact: false,
        authorities: [
          UserRole.ROLE_SUPER_ADMIN,
          UserRole.ROLE_SUPPORT_ADMIN,
          UserRole.ROLE_TEAMMATE,
          UserRole.ROLE_SCHOOL_ADMIN,
          UserRole.ROLE_EDUCATOR,
        ],
      },
      {
        tag: "publicUserProfile",
        path: paths.PUBLIC_USER_PROFILE,
        component: PublicUserProfile,
        authorities: [
          UserRole.ROLE_SUPER_ADMIN,
          UserRole.ROLE_SUPPORT_ADMIN,
          UserRole.ROLE_TEAMMATE,
          UserRole.ROLE_SCHOOL_ADMIN,
          UserRole.ROLE_EDUCATOR,
        ],
      },
      {
        path: paths.TRANSCRIPT_V2,
        tag: "transcript",
        component: TranscriptV2,
        layout: TranscriptLayout,
        authorities: [
          UserRole.ROLE_SUPER_ADMIN,
          UserRole.ROLE_SUPPORT_ADMIN,
          UserRole.ROLE_SCHOOL_ADMIN,
          UserRole.ROLE_EDUCATOR,
          UserRole.ROLE_LEARNER,
        ],
      },
      {
        path: paths.LEARNING_RECORD,
        tag: "learningRecord",
        component: LearningRecord,
        layout: TranscriptLayout,
        authorities: [
          UserRole.ROLE_SUPER_ADMIN,
          UserRole.ROLE_SUPPORT_ADMIN,
          UserRole.ROLE_SCHOOL_ADMIN,
          UserRole.ROLE_EDUCATOR,
          UserRole.ROLE_LEARNER,
        ],
      },
      {
        path: paths.PROGRESS_REPORT,
        tag: "progressReport",
        component: ProgressReport,
        layout: TranscriptLayout,
        authorities: [
          UserRole.ROLE_SUPER_ADMIN,
          UserRole.ROLE_SUPPORT_ADMIN,
          UserRole.ROLE_SCHOOL_ADMIN,
          UserRole.ROLE_EDUCATOR,
          UserRole.ROLE_LEARNER,
        ],
      },
    ],
  },
];

const highOrderAdminRoutes = [
  {
    path: paths.MTC_TEAM,
    tag: "MTCTeam",
    component: MTCTeam,
    authorities: [UserRole.ROLE_SUPER_ADMIN, UserRole.ROLE_TEAMMATE],
    children: [
      {
        path: paths.MTC_TEAM_MEMBER_PROFILE,
        tag: "userProfile",
        component: MTCTeamMemberProfile,
        authorities: [UserRole.ROLE_SUPER_ADMIN, UserRole.ROLE_TEAMMATE],
      },
    ],
  },
  {
    path: paths.ALL_SCHOOL_ADMINS,
    tag: "schoolAdmins",
    component: AllSchoolAdmins,
    authorities: MTCTeamRoles,
  },
  {
    path: paths.SCHOOLS,
    tag: "memberSchools",
    component: Schools,
    authorities: MTCTeamRoles,
    children: [
      {
        path: paths.SCHOOL,
        tag: "school",
        component: School,
        authorities: MTCTeamRoles,
      },
      {
        path: paths.SCHOOL_EDIT,
        tag: "editSchool",
        component: SchoolEdit,
        authorities: MTCAdminRoles,
      },
      {
        path: paths.SCHOOL_ADMINS,
        tag: "oneSchoolAdmins",
        component: SchoolAdmins,
        authorities: MTCTeamRoles,
      },
      {
        path: paths.SCHOOL_ADMIN_PROFILE,
        tag: "userProfile",
        component: SchoolAdminProfile,
        authorities: MTCTeamRoles,
      },
      {
        path: paths.EDUCATORS,
        tag: "educators",
        component: Educators,
        authorities: MTCTeamRoles,
      },
      {
        path: paths.SCHOOL_GROW,
        tag: "schoolGrow",
        component: SchoolGrow,
        authorities: MTCTeamRoles,
        children: [
          {
            path: paths.SCHOOL_GROW_CREDIT,
            tag: "schoolGrowCredit",
            component: SchoolGrowCredit,
            authorities: MTCTeamRoles,
            exact: false,
          },
        ],
      },
      {
        path: paths.EDUCATORS_IMPORT,
        tag: "importPage",
        component: EducatorsImport,
        authorities: MTCAdminRoles,
        layout: ImportPageLayout,
      },
      {
        path: paths.EDUCATOR_PROFILE,
        tag: "userProfile",
        component: EducatorProfile,
        authorities: MTCTeamRoles,
      },
      {
        path: paths.EDUCATOR_EDIT,
        tag: "editEducatorProfile",
        component: EducatorEdit,
        authorities: MTCAdminRoles,
      },
      {
        path: paths.LEARNERS,
        tag: "learners",
        component: Learners,
        authorities: MTCTeamRoles,
      },
      {
        path: paths.LEARNERS_IMPORT,
        tag: "importPage",
        component: LearnersImport,
        authorities: MTCAdminRoles,
        layout: ImportPageLayout,
      },
      {
        path: paths.LEARNER_PROFILE,
        tag: "learnerPage",
        component: LearnerProfile,
        authorities: MTCTeamRoles,
      },
      {
        path: paths.LEARNER_EDIT,
        tag: "editLearnerProfile",
        component: LearnerEdit,
        authorities: MTCAdminRoles,
        exact: false,
      },
      {
        path: paths.LEARNER_COLLEGES,
        tag: "colleges",
        component: LearnerColleges,
        authorities: MTCTeamRoles,
      },
      {
        path: paths.EVIDENCE,
        tag: "evidence",
        component: Evidence,
        authorities: MTCTeamRoles,
      },
      {
        path: paths.EVIDENCE_COMPARE,
        tag: "evidence",
        component: EvidenceCompare,
        authorities: MTCTeamRoles,
      },
      {
        path: paths.SCHOOL_MCA,
        tag: "schoolMCA",
        component: SchoolMCA,
        authorities: MTCTeamRoles,
      },
      {
        path: paths.SHARED_MCAs,
        tag: "masteryCreditLibrary",
        component: SharedMCAs,
        authorities: MTCTeamRoles,
      },
      {
        path: paths.SCHOOL_MCA_IMPORT_WARNINGS,
        tag: "mcaWarnings",
        component: SchoolMCAImportWarnings,
        authorities: MTCAdminRoles,
      },
      {
        path: paths.SHARED_MCA,
        tag: "mcaPage",
        component: SharedMCA,
        authorities: MTCTeamRoles,
      },
      {
        path: paths.SCHOOL_COURSES,
        tag: "courses",
        component: SchoolCourses,
        authorities: MTCTeamRoles,
      },
      {
        path: paths.SCHOOL_COURSES_IMPORT,
        tag: "importedCourses",
        component: SchoolCoursesImport,
        authorities: MTCAdminRoles,
        layout: ImportPageLayout,
      },
      {
        path: paths.SCHOOL_EXPERIENCES_IMPORT,
        tag: "importedExperiences",
        component: SchoolExperiencesImport,
        authorities: MTCAdminRoles,
        layout: ImportPageLayout,
      },
      {
        path: paths.SCHOOL_COLLEGES,
        tag: "schoolColleges",
        component: SchoolColleges,
        authorities: MTCTeamRoles,
      },
      {
        path: paths.SCHOOL_COLLEGE,
        component: CollegeLearners,
        authorities: MTCTeamRoles,
      },
      {
        path: paths.SCHOOL_EVENTS,
        tag: "schoolEvents",
        component: SchoolEvents,
        authorities: MTCTeamRoles,
      },
    ],
  },
  {
    path: paths.MCAs,
    tag: "mcaList",
    component: MCAs,
    authorities: MTCTeamRoles,
    children: [
      {
        path: paths.MCA_IMPORT_WARNINGS,
        tag: "mcaWarnings",
        component: MCAImportWarnings,
        authorities: MTCAdminRoles,
      },
      {
        path: paths.MCA,
        tag: "mcaPage",
        component: MCA,
        authorities: MTCTeamRoles,
      },
    ],
  },
  {
    path: paths.CREDIT_AREA_LIBRARY,
    tag: "creditAreaLibrary",
    component: CreditAreas,
    authorities: MTCTeamRoles,
  },
  {
    path: paths.CREDIT_LIBRARY,
    tag: "creditLibrary",
    component: CreditLibrary,
    authorities: MTCTeamRoles,
  },
  {
    path: paths.COLLEGES,
    tag: "colleges",
    component: Colleges,
    authorities: [UserRole.ROLE_SUPER_ADMIN, UserRole.ROLE_TEAMMATE, UserRole.ROLE_SUPPORT_ADMIN],
    children: [
      {
        path: paths.MANAGE_NUDGES,
        tag: "manageCollegeNudges",
        component: ManageCollegeNudges,
        authorities: [UserRole.ROLE_SUPER_ADMIN, UserRole.ROLE_TEAMMATE, UserRole.ROLE_SUPPORT_ADMIN],
      },
    ],
  },
  {
    path: paths.COURSE_LIBRARY,
    tag: "courseLibrary",
    component: CourseLibrary,
    authorities: MTCTeamRoles,
  },
  {
    path: paths.MTC_GROW,
    tag: "mtcGrow",
    component: MTCGrow,
    authorities: MTCTeamRoles,
    children: [
      {
        path: paths.GROW_EVIDENCE,
        tag: "growEvidence",
        component: GrowEvidence,
        authorities: MTCTeamRoles,
      },
    ],
  },
  {
    path: paths.COMMUNITY_GROUPS,
    tag: "communityGroups",
    component: MTCTeamGroups,
    authorities: MTCTeamRoles,
    children: [
      {
        path: paths.COMMUNITY_GROUP,
        tag: "communityGroup",
        component: CommunityGroup,
        authorities: MTCTeamRoles,
        exact: false,
      },
      {
        path: paths.GROUP_POST,
        tag: "post",
        component: GroupPost,
        authorities: MTCTeamRoles,
      },
    ],
  },
  {
    path: paths.LIVE_FEED,
    tag: "liveFeed",
    component: LiveFeed,
    authorities: MTCTeamRoles,
    children: [
      {
        path: paths.POST,
        tag: "post",
        component: Post,
        authorities: MTCTeamRoles,
      },
    ],
  },
  {
    path: paths.MEDIA_LIBRARY,
    tag: "mediaLibrary",
    component: MediaLibrary,
    authorities: MTCTeamRoles,
    children: [
      {
        path: paths.FILE_PAGE,
        tag: "filePage",
        component: FilePage,
        authorities: MTCTeamRoles,
      },
      {
        path: paths.FOLDER_PAGE,
        tag: "folderPage",
        component: FolderPage,
        authorities: MTCTeamRoles,
      },
      {
        path: paths.FAVORITES_PAGE,
        tag: "favoritesPage",
        component: FavoritesPage,
        authorities: MTCTeamRoles,
      },
    ],
  },
];

const schoolUserRoutes = [
  {
    path: paths.SCHOOL,
    tag: "mySchool",
    component: School,
    authorities: schoolStaff,
    children: [
      {
        path: paths.SCHOOL_EDIT,
        tag: "editSchool",
        component: SchoolEdit,
        authorities: [UserRole.ROLE_SCHOOL_ADMIN],
      },
      {
        path: paths.SCHOOL_GROW,
        tag: "schoolGrow",
        component: SchoolGrow,
        authorities: schoolStaff,
        children: [
          {
            path: paths.SCHOOL_GROW_CREDIT,
            tag: "schoolGrowCredit",
            component: SchoolGrowCredit,
            authorities: schoolStaff,
            exact: false,
          },
        ],
      },
      {
        path: paths.SCHOOL_ADMINS,
        tag: "oneSchoolAdmins",
        component: SchoolAdmins,
        authorities: schoolStaff,
        children: [
          {
            path: paths.SCHOOL_ADMIN_PROFILE,
            tag: "userProfile",
            component: SchoolAdminProfile,
            authorities: [UserRole.ROLE_SCHOOL_ADMIN],
          },
        ],
      },
      {
        path: paths.EDUCATORS,
        tag: "educators",
        component: Educators,
        authorities: schoolStaff,
        children: [
          {
            path: paths.EDUCATORS_IMPORT,
            tag: "importPage",
            component: EducatorsImport,
            authorities: [UserRole.ROLE_SCHOOL_ADMIN],
            layout: ImportPageLayout,
          },
          {
            path: paths.EDUCATOR_PROFILE,
            tag: "userProfile",
            component: EducatorProfile,
            authorities: [UserRole.ROLE_SCHOOL_ADMIN],
          },
          {
            path: paths.EDUCATOR_EDIT,
            tag: "editEducatorProfile",
            component: EducatorEdit,
            authorities: [UserRole.ROLE_SCHOOL_ADMIN],
          },
        ],
      },
      {
        path: paths.LEARNERS,
        tag: "learners",
        component: Learners,
        authorities: schoolStaff,
        children: [
          {
            path: paths.LEARNERS_IMPORT,
            tag: "importPage",
            component: LearnersImport,
            authorities: schoolStaff,
            layout: ImportPageLayout,
          },
          {
            path: paths.LEARNER_PROFILE,
            tag: "learnerPage",
            component: LearnerProfile,
            authorities: schoolStaff,
          },
          {
            path: paths.LEARNER_EDIT,
            tag: "editLearnerProfile",
            component: LearnerEdit,
            authorities: schoolStaff,
            exact: false,
          },
          {
            path: paths.LEARNER_COLLEGES,
            tag: "colleges",
            component: LearnerColleges,
            authorities: schoolStaff,
          },
          {
            path: paths.EVIDENCE,
            tag: "evidence",
            component: Evidence,
            authorities: schoolStaff,
          },
          {
            path: paths.EVIDENCE_COMPARE,
            tag: "evidence",
            component: EvidenceCompare,
            authorities: schoolStaff,
          },
        ],
      },
      {
        path: paths.SCHOOL_COURSES,
        tag: "courses",
        component: SchoolCourses,
        authorities: schoolStaff,
        children: [
          {
            path: paths.SCHOOL_COURSES_IMPORT,
            tag: "importedCourses",
            component: SchoolCoursesImport,
            layout: ImportPageLayout,
            authorities: [UserRole.ROLE_SCHOOL_ADMIN],
          },
        ],
      },
      {
        path: paths.SCHOOL_EXPERIENCES_IMPORT,
        tag: "importedExperiences",
        component: SchoolExperiencesImport,
        authorities: schoolStaff,
        layout: ImportPageLayout,
      },
      {
        path: paths.SCHOOL_COLLEGES,
        tag: "schoolColleges",
        component: SchoolColleges,
        authorities: schoolStaff,
      },
      {
        path: paths.SCHOOL_COLLEGE,
        component: CollegeLearners,
        authorities: schoolStaff,
      },
      {
        path: paths.SCHOOL_EVENTS,
        tag: "schoolEvents",
        component: SchoolEvents,
        authorities: schoolStaff,
      },
    ],
  },
  {
    path: paths.MEDIA_LIBRARY,
    tag: "mediaLibrary",
    component: MediaLibrary,
    authorities: schoolStaff,
    children: [
      {
        path: paths.FILE_PAGE,
        tag: "filePage",
        component: FilePage,
        authorities: schoolStaff,
      },
      {
        path: paths.FOLDER_PAGE,
        tag: "folderPage",
        component: FolderPage,
        authorities: schoolStaff,
      },
      {
        path: paths.FAVORITES_PAGE,
        tag: "favoritesPage",
        component: FavoritesPage,
        authorities: schoolStaff,
      },
    ],
  },
  {
    path: paths.GROW_EVIDENCE,
    tag: "growEvidence",
    component: GrowEvidence,
    authorities: schoolStaff,
  },
  {
    path: paths.SCHOOL_MCA,
    tag: "schoolMCA",
    component: SchoolMCA,
    authorities: schoolStaff,
    children: [
      {
        path: paths.SCHOOL_MCA_IMPORT_WARNINGS,
        tag: "mcaWarnings",
        component: SchoolMCAImportWarnings,
        authorities: [UserRole.ROLE_SCHOOL_ADMIN],
      },
      {
        path: paths.SHARED_MCA,
        tag: "mcaPage",
        component: SharedMCA,
        authorities: schoolStaff,
      },
    ],
  },
  {
    path: paths.SHARED_MCAs,
    tag: "masteryCreditLibrary",
    component: SharedMCAs,
    authorities: schoolStaff,
  },
  {
    path: paths.LIVE_FEED,
    tag: "liveFeed",
    component: LiveFeed,
    authorities: schoolStaff,
    children: [
      {
        path: paths.POST,
        tag: "post",
        component: Post,
        authorities: schoolStaff,
      },
    ],
  },
  {
    path: paths.COMMUNITY_GROUPS,
    tag: "communityGroups",
    component: SchoolStaffGroups,
    authorities: schoolStaff,
    children: [
      {
        path: paths.COMMUNITY_MY_GROUPS,
        tag: "myGroups",
        component: CommunityUserGroups,
        authorities: schoolStaff,
      },
      {
        path: paths.COMMUNITY_DISCOVER_GROUPS,
        tag: "discoverGroups",
        component: CommunityDiscoverGroups,
        authorities: schoolStaff,
      },
      {
        path: paths.COMMUNITY_GROUP,
        tag: "communityGroup",
        component: CommunityGroup,
        authorities: schoolStaff,
        exact: false,
      },
      {
        path: paths.GROUP_POST,
        tag: "post",
        component: GroupPost,
        authorities: schoolStaff,
      },
    ],
  },
];

const schoolLearnerRoutes = [
  {
    path: paths.LEARNER_PROFILE,
    tag: "learnerCabinet",
    component: LearnerProfile,
    authorities: [UserRole.ROLE_LEARNER],
    children: [
      {
        path: paths.EVIDENCE,
        tag: "evidence",
        component: Evidence,
        authorities: [UserRole.ROLE_LEARNER],
      },
      {
        path: paths.EVIDENCE_COMPARE,
        tag: "evidence",
        component: EvidenceCompare,
        authorities: [UserRole.ROLE_LEARNER],
      },
    ],
  },
  {
    path: paths.SCHOOL,
    tag: "schoolProfile",
    component: School,
    authorities: [UserRole.ROLE_LEARNER],
    children: [
      {
        path: paths.SCHOOL_MCA,
        tag: "schoolMCA",
        component: SchoolMCA,
        authorities: [UserRole.ROLE_LEARNER],
      },
      {
        path: paths.SCHOOL_COURSES,
        tag: "courses",
        component: SchoolCourses,
        authorities: [UserRole.ROLE_LEARNER],
      },
    ],
  },
  {
    path: paths.LEARNER_COLLEGES,
    tag: "learnerColleges",
    component: LearnerColleges,
    authorities: [UserRole.ROLE_LEARNER],
  },
];

const privateRoutes = [...globalRoutes, ...schoolUserRoutes, ...highOrderAdminRoutes, ...schoolLearnerRoutes];

export { publicRoutes, privateRoutes };
