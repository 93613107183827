import BaseService from "services/baseService";
import FetchService from "services/fetchService";
import { LearnerCreditStatus } from "services/types";

export type LearningScaleStep = {
  id: number;
  position: number;
  title: string;
  description: string;
  archivedAt?: null | string;
  createdAt: string;
  stepId: number;
  creditId: number;
};

export type LearningScale = {
  id: number;
  creditArchitectureId: number;
  title: string;
  updatedAt?: string;
  createdAt: string;
  enrollmentDate: string;
  steps: LearningScaleStep[];
};

export type CreditStepDescription = {
  stepId: number;
  creditId: number;
  levelDescription: string | null;
};

export type CreditLearningScaleStepFormType = {
  steps: (LearningScaleStep & { stepId: number })[];
  learningScaleId: number;
  hideForm: () => void;
};

class LearningScaleService extends BaseService {
  servicePath = "learningScale";

  createLearningScale(creditArchitectureId: number) {
    return FetchService.post<number>({
      url: this.getUrl("mcas", creditArchitectureId),
    });
  }

  updateLearningScale({ learningScaleId, title }: { learningScaleId: number; title: string }) {
    return FetchService.put<true>({
      url: this.getUrl(learningScaleId),
      data: { title },
    });
  }

  archiveLearningScale({ learningScaleId }: { learningScaleId: number }) {
    return FetchService.delete<true>({
      url: this.getUrl(learningScaleId),
    });
  }

  createStep({
    step,
    learningScaleId,
  }: {
    step: Pick<LearningScaleStep, "title" | "description">;
    learningScaleId: number;
  }) {
    return FetchService.post<LearningScaleStep>({
      url: this.getUrl(learningScaleId, "steps"),
      data: { ...step },
    });
  }

  createSteps({
    steps,
    learningScaleId,
  }: {
    steps: Pick<LearningScaleStep, "title" | "description">[];
    learningScaleId: number;
  }) {
    return FetchService.post<LearningScaleStep[]>({
      url: this.getUrl(learningScaleId, "steps/bulk"),
      data: { steps },
    });
  }

  archiveStep({ id }: { id: number }) {
    return FetchService.put<true>({
      url: this.getUrl("steps", id, "archive"),
    });
  }

  unarchiveStep({ id }: { id: number }) {
    return FetchService.put<true>({
      url: this.getUrl("steps", id, "unarchive"),
    });
  }

  updateStep({ stepId, title, description }: { stepId: number; title: string; description: string }) {
    return FetchService.put<true>({
      url: this.getUrl("steps", stepId),
      data: { title, description },
    });
  }

  updateCreditStepDescription({
    stepId,
    creditId,
    description,
  }: {
    stepId: number;
    creditId: number;
    description: string;
  }) {
    return FetchService.put<true>({
      url: this.getUrl("steps", stepId, "credits", creditId),
      data: { description },
    });
  }

  updateCreditStepDescriptions({
    descriptions,
    learningScaleId,
  }: {
    descriptions: CreditStepDescription[];
    learningScaleId: number;
  }) {
    return FetchService.put<true>({
      url: this.getUrl("steps", learningScaleId, "bulk"),
      data: { stepCreditIds: descriptions },
    });
  }

  addStepsToCredit({ learningScaleId, creditId }: { learningScaleId: number; creditId: number }) {
    return FetchService.post<true>({
      url: this.getUrl(learningScaleId, "credits", creditId),
    });
  }

  updateCreditStepProgress({
    newStepId,
    learnerId,
    creditId,
  }: {
    newStepId: number;
    learnerId: number;
    creditId: number;
  }) {
    return FetchService.put<LearnerCreditStatus>({
      url: this.getUrl("steps", "learners", learnerId, "credits", creditId),
      data: { stepId: newStepId },
    });
  }

  approveProgress({ learnerId, creditId }: { learnerId: number; creditId: number }) {
    return FetchService.put<LearnerCreditStatus>({
      url: this.getUrl("steps", "learners", learnerId, "credits", creditId, "approve"),
    });
  }

  denyProgress({ learnerId, creditId }: { learnerId: number; creditId: number }) {
    return FetchService.delete<true>({
      url: this.getUrl("steps", "learners", learnerId, "credits", creditId, "deny"),
    });
  }

  repositionSteps({
    learningScaleId,
    positions,
  }: {
    learningScaleId: number;
    positions: { learningScaleStepId: number; position: number }[];
  }) {
    return FetchService.put<true>({
      url: this.getUrl(learningScaleId, "steps", "position"),
      data: { positions },
    });
  }

  demonstrateStep({ evidenceId, creditId, stepId }: { evidenceId: number; creditId: number; stepId: number }) {
    return FetchService.post<true>({
      url: this.getUrl("evidences", evidenceId, "credits", creditId, "steps", stepId, "demonstrate"),
    });
  }
}

export default new LearningScaleService();
