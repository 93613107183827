import { useSelector } from "react-redux";

import { Box } from "@mui/material";
import { AppContent, Main, Root } from "components/pageLayout/styles";
import { SupportPopover } from "components/popover/displays/SupportPopover";
import { Notification } from "components/Notification";
import { ModalWindow } from "components/modal/helpers/ModalWindow";
import Header from "../components/pageLayout/helpers/header";
import { SideNavigation } from "components/pageLayout/SideNavigation/SideNavigation";
import { LogoutFooter } from "components/pageLayout/helpers/LogoutFooter";
import { CollegeNudgePopup } from "features/CollegeNudges/components/CollegeNudgePopup";
import { UserRole } from "services/types";

const DashboardLayout = ({ children }) => {
  const { role } = useSelector((state) => state.account);
  const isSupportPopupVisible = role !== UserRole.ROLE_LEARNER;
  const isNudgesPopupVisible = role === UserRole.ROLE_LEARNER;

  const showLogoutFooter = Boolean(localStorage.getItem("loggedAs"));

  return (
    <>
      <Root>
        <SideNavigation />
        <AppContent>
          <Header />
          <Box height="100%" display="flex">
            <Main id="main-content">{children}</Main>
          </Box>
        </AppContent>

        <ModalWindow />
        <Notification />
        {isNudgesPopupVisible && <CollegeNudgePopup />}
        {isSupportPopupVisible && <SupportPopover />}
      </Root>
      {showLogoutFooter && <LogoutFooter />}
    </>
  );
};

export default DashboardLayout;
