import Translate from "react-translate-component";

import { Box, FormHelperText } from "@mui/material";

export const ErrorDisplay = ({ error, mt = 4, fontSize = 12, textAlign = "left" }) =>
  error ? (
    <Box mt={mt}>
      <FormHelperText error style={{ whiteSpace: "pre-wrap", textAlign, fontSize }}>
        <Translate content={`errorsList.${error.tag}`} with={error.data || {}} />
      </FormHelperText>
    </Box>
  ) : (
    <span />
  );
