import * as types from "../constants";

export default function reducer(state = false, { type }) {
  switch (type) {
    case types.SET_UPD_FLAG:
      return true;

    case types.REMOVE_UPD_FLAG:
      return false;

    default:
      return state;
  }
}
