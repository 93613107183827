import { IconButton } from "@mui/material";
import { CheckIcon, CloseIcon } from "svg";
import { TooltipI18n } from "components/TooltipI18n";

export const FormActionBar = ({ disabled, handleCancel }) => (
  <>
    <TooltipI18n content="buttons.save">
      <span>
        <IconButton
          aria-label="submit"
          disabled={disabled}
          type="submit"
          color="primary"
          edge={handleCancel ? "start" : "end"}
        >
          <CheckIcon style={{ fontSize: "14px" }} />
        </IconButton>
      </span>
    </TooltipI18n>
    {handleCancel && (
      <TooltipI18n content="buttons.cancel">
        <IconButton aria-label="cancel" onClick={handleCancel} edge="end">
          <CloseIcon style={{ fontSize: "14px" }} />
        </IconButton>
      </TooltipI18n>
    )}
  </>
);
