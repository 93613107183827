import { Box } from "@mui/material";
import { Body1 } from "components/typography";
import { ButtonContained } from "components/buttons";
import { RetryIcon } from "svg";
import React from "react";
import FormError from "services/formError";

type ErrorFallbackProps = {
  onReset: () => void;
  error: FormError;
};

export const ErrorFallback = ({ onReset, error }: ErrorFallbackProps) => (
  <Box alignItems="center" justifyContent="center" display="flex" flexDirection="column" py={5} px={3} maxWidth={600}>
    <Body1 mb={5} content={`errorsList.${error.code || "LOADING_FAIL"}`} />
    <ButtonContained startIcon={<RetryIcon />} content="buttons.retry" onClick={onReset} />
  </Box>
);
