import BaseService from "services/baseService";
import FetchService from "services/fetchService";
import {
  GrowArea,
  GrowCreditStatus,
  GrowEvidence,
  OffsetBasedListResponse,
  PaginatedQueryParams,
  PriorityGrowCredit,
  ResponseSuccess,
  SchoolGrowCredit,
} from "services/types";

class GrowSchoolService extends BaseService {
  servicePath = "grow/schools";

  getAreas({ schoolId }: { schoolId: number }) {
    return FetchService.get<OffsetBasedListResponse<GrowArea>>({ url: this.getUrl(schoolId, "areas") });
  }

  getCredit(schoolId: number, creditId: number) {
    return FetchService.get<SchoolGrowCredit>({ url: this.getUrl(schoolId, "credits", creditId) });
  }

  changeCreditStatus(schoolId: number, creditId: number, { status }: { status: GrowCreditStatus }) {
    return FetchService.put<ResponseSuccess>({
      url: this.getUrl(schoolId, "credits", creditId),
      data: { status },
    });
  }

  getUnrelatedGrowEvidences({
    schoolId,
    creditId,
    limit = 8,
    filter,
    page = 1,
    sort = "title,asc",
  }: PaginatedQueryParams & { schoolId: number; creditId: number }) {
    return FetchService.get<OffsetBasedListResponse<Omit<GrowEvidence, "user" | "credits">>>({
      url: this.getUrl(schoolId, "evidences", "credits", creditId),
      data: {
        limit,
        filter,
        page,
        sort,
      },
    });
  }

  getPriorityGrowCredits({ schoolId }: { schoolId: number }) {
    return FetchService.get<PriorityGrowCredit[]>({
      url: this.getUrl(schoolId, "priorities", "credits"),
    });
  }

  createPriorityGrowCredit({ schoolId, growCreditId }: { schoolId: number; growCreditId: number }) {
    return FetchService.post<{ priorityPosition: number | undefined }>({
      url: this.getUrl(schoolId, "priorities", "credits", growCreditId),
    });
  }

  setPriorityGrowCreditPositions({
    schoolId,
    positions,
  }: {
    schoolId: number;
    positions: { growCreditId: number; position: number }[];
  }) {
    return FetchService.put<true>({
      url: this.getUrl(schoolId, "priorities", "credits", "position"),
      data: { positions },
    });
  }

  deletePriorityGrowCredit({ schoolId, growCreditId }: { schoolId: number; growCreditId: number }) {
    return FetchService.delete<true>({
      url: this.getUrl(schoolId, "priorities", "credits", growCreditId),
    });
  }
}

export default new GrowSchoolService();
