import BaseService from "services/baseService";
import FetchService from "services/fetchService";
import { ResponseSuccess } from "services/types";

export type SendQuestionParams = {
  userEmail: string;
  question: string;
  screenshot?: FormData;
  systemInformation: string;
};

class SupportService extends BaseService {
  servicePath = "support";

  async sendQuestion({ userEmail, question, screenshot, systemInformation }: SendQuestionParams) {
    return FetchService.post<ResponseSuccess>({
      url: this.getUrl("question"),
      data: screenshot,
      params: {
        userEmail,
        question,
        systemInformation,
      },
    });
  }

  async requestAbilityToPublishTranscript(schoolId: number) {
    return FetchService.post<ResponseSuccess>({
      url: this.getUrl("schools", schoolId, "sendPublishTranscriptRequest"),
    });
  }

  async requestAbilityToPublishLearningRecord(schoolId: number) {
    return FetchService.post<ResponseSuccess>({
      url: this.getUrl("schools", schoolId, "sendPublishLearningRecordRequest"),
    });
  }
}

export default new SupportService();
