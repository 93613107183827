import React, { Fragment } from "react";
import Translate, { TranslateProps } from "react-translate-component";

import { Link, Button as MuiButton, ButtonProps, LinkProps } from "@mui/material";
import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import { LoadingButtonProps } from "@mui/lab/LoadingButton/LoadingButton";

export type ExtendedButtonProps = ButtonProps &
  Partial<Pick<TranslateProps, "content" | "with">> & { component?: React.ElementType };

const Button = ({ content, children, with: translateWith, ...props }: ExtendedButtonProps) => (
  <MuiButton color="primary" {...props}>
    {children || <Translate component={Fragment} content={content || ""} with={translateWith} />}
  </MuiButton>
);

const LoadingButton = ({
  content,
  children,
  with: translateWith,
  ...props
}: ExtendedButtonProps & Pick<LoadingButtonProps, "loading">) => (
  <MuiLoadingButton color="primary" {...props}>
    {children || <Translate component={Fragment} content={content || ""} with={translateWith} />}
  </MuiLoadingButton>
);

const ButtonContained = (props: ExtendedButtonProps) => <Button variant="contained" {...props} />;

const ButtonOutlined = (props: ExtendedButtonProps) => <Button variant="outlined" {...props} />;

const ButtonText = (props: ExtendedButtonProps) => <Button {...props} />;

type ButtonTextLinkProps = TranslateProps &
  LinkProps<"button"> & {
    text?: string;
  };

const ButtonTextLink = ({ children, text, content, style, with: translateWith, ...props }: ButtonTextLinkProps) => (
  <Link style={{ verticalAlign: "baseline", ...style }} {...props} component="button">
    {children || text || <Translate component={Fragment} content={content} with={translateWith} />}
  </Link>
);

export { ButtonContained, ButtonOutlined, ButtonText, ButtonTextLink, Button as DefaultButton, LoadingButton };
export default Button;
