import { styled } from "@mui/material/styles";
import { Box, Tooltip } from "@mui/material";
import { setTransition } from "components/styledComponents/actions";
import { lighten } from "@mui/material/styles";
import { drawerWidth } from "components/pageLayout/styles";
import { tooltipClasses } from "@mui/material/Tooltip";

export const SideNavigationWrapper = styled(Box)`
  position: relative;
  .sidebar {
    min-height: 100%;
    height: 100%;
    display: flex;
    width: ${drawerWidth}px;
    position: fixed;
    flex-direction: column;
    top: 0;
    left: 0;
    z-index: ${(props) => props.theme.zIndex.modal};
    overflow: hidden;
    transition: width ${setTransition()};
  }

  .drawer-icon {
    height: 64px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    background: white;
    @media screen and (max-width: 600px) {
      height: 54px;
    }
  }

  .simplebar-scrollbar:before {
    background: ${(props) => props.theme.palette.grey[400]};
  }

  .menu-icon {
    overflow: unset;
    color: ${({ theme }) => theme.palette.primary.dark};

    path {
      transition: ${setTransition()};
    }
  }

  .menu {
    max-height: 100%;
    flex-grow: 1;
    overflow-y: auto;
    background: ${(props) => props.theme.palette.primary.dark};

    .MuiSvgIcon-root {
      font-size: 20px;
      color: ${(props) => props.theme.palette.common.white};
    }

    .MuiListItemButton-root {
      min-height: 48px;
      color: ${(props) => props.theme.palette.common.white};
      transition: none;
      &.Mui-selected {
        background-color: ${({ theme }) => theme.palette.primary.main};

        &:hover {
          background-color: ${({ theme }) => theme.palette.primary.main};
        }
      }

      &:hover {
        background-color: ${({ theme }) => lighten(theme.palette.primary.dark, 0.1)};
      }
    }

    .MuiListItemIcon-root {
      min-width: 0;
      padding: 0 7px;
    }

    .MuiListItemText-root {
      display: none;
      margin-left: 9px;

      > span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0);
    opacity: 0;
    z-index: -1;
  }

  &.open {
    .sidebar {
      height: 100%;
      width: 266px;
      box-shadow: 2.5px 0 4px 0 rgba(0, 0, 0, 0.1);
    }

    .overlay {
      z-index: ${(props) => props.theme.zIndex.modal - 1};
      opacity: 0.5;
    }

    .menu-icon path {
      &:first-of-type {
        transform: translateX(15%);
      }

      &:last-child {
        transform: translateX(-15%);
      }
    }

    .MuiListItemText-root {
      display: block;
    }
  }
  ${(props) => props.theme.breakpoints.down("lg")} {
    
    .sidebar {
      min-height: auto;
      height: auto;
    }

    .menu {
      display: none;
    }

    &.open {
      .menu {
        display: block;
        opacity: 1;
      }
    }

`;

export const SideNavTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`&.flyout .${tooltipClasses.tooltip}`]: {
    marginTop: 0,
    overflow: "hidden",
    minWidth: 150,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    maxWidth: 360,
    padding: 0,
    boxShadow: theme.shadows[2],
    ".MuiListItemIcon-root": {
      minWidth: 0,
      padding: "0 7px",
    },
    ".MuiSvgIcon-root": {
      fontSize: "18px",
    },
    ".MuiListItemButton-root": {
      transition: "none",
    },
    ".MuiListSubheader-root": {
      backgroundColor: theme.palette.grey[200],
      color: "inherit",
      lineHeight: 1,
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
}));
