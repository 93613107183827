import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import learningScaleService, { LearningScale, LearningScaleStep } from "services/learningScaleService";
import { dispatchErrorPopup, dispatchPopup } from "redux/actions/popupActions";
import { MCARemoved } from "redux/slices/sharedMCAActions";

export const STATE_KEY = "learningScale";

export const createLearningScale = createAsyncThunk(
  `${STATE_KEY}/createLearningScale`,
  async (creditArchitectureId: number) => {
    try {
      const createdLearningScaleId = await learningScaleService.createLearningScale(creditArchitectureId);
      dispatchPopup("success", { tag: "formResults.LEARNING_SCALE_CREATED" });
      return {
        creditArchitectureId,
        id: createdLearningScaleId,
        createdAt: new Date().toISOString(),
        steps: [],
      };
    } catch (err) {
      dispatchErrorPopup(err);
    }
  }
);

export const applyLearningScaleConfiguration = createAsyncThunk(
  `${STATE_KEY}/applyConfiguration`,
  async ({
    steps,
    learningScaleId,
  }: {
    learningScaleId: number;
    steps: Pick<LearningScaleStep, "title" | "description">[];
  }) => {
    try {
      const newSteps = await learningScaleService.createSteps({ learningScaleId, steps });
      dispatchPopup("success", { tag: "formResults.LEARNING_SCALE_CONFIGURATION_APPLIED" });
      return { steps: newSteps };
    } catch (err) {
      dispatchErrorPopup(err);
    }
  }
);

export const updateLearningScale = createAsyncThunk(
  `${STATE_KEY}/updateLearningScale`,
  async ({ learningScaleId, title }: { title: string; learningScaleId: number }) => {
    try {
      await learningScaleService.updateLearningScale({ learningScaleId, title });
      dispatchPopup("success", { tag: "formResults.LEARNING_SCALE_UPDATED" });
      return { title, updatedAt: new Date().toISOString() };
    } catch (err) {
      dispatchErrorPopup(err);
    }
  }
);

export const updateLearningScaleStep = createAsyncThunk(
  `${STATE_KEY}/updateLearningScaleStep`,
  async ({ stepId, title, description }: { stepId: number; title: string; description: string }) => {
    try {
      await learningScaleService.updateStep({ stepId, title, description });
      dispatchPopup("success", { tag: "formResults.LEARNING_SCALE_LEVEL_UPDATED" });
      return { stepId, title, description };
    } catch (error) {
      dispatchErrorPopup(error);
    }
  }
);

export const archiveLearningScaleStep = createAsyncThunk(
  `${STATE_KEY}/archiveLearningScaleStep`,
  async ({ stepId }: { stepId: number }) => {
    try {
      await learningScaleService.archiveStep({ id: stepId });
      dispatchPopup("success", { tag: "formResults.LEARNING_SCALE_LEVEL_ARCHIVED" });
      return { archivedAt: new Date().toISOString(), stepId };
    } catch (error) {
      dispatchErrorPopup(error);
    }
  }
);

export const unarchiveLearningScaleStep = createAsyncThunk(
  `${STATE_KEY}/archiveLearningScaleStep`,
  async ({ stepId }: { stepId: number }) => {
    try {
      await learningScaleService.unarchiveStep({ id: stepId });
      dispatchPopup("success", { tag: "formResults.LEARNING_SCALE_LEVEL_UNARCHIVED" });
      return { archivedAt: null, stepId };
    } catch (error) {
      dispatchErrorPopup(error);
    }
  }
);

export const repositionLearningScaleSteps = createAsyncThunk(
  `${STATE_KEY}/repositionLearningScaleSteps`,
  async (
    {
      newPositions,
      oldPositions,
      learningScaleId,
    }: {
      newPositions: LearningScaleStep[];
      oldPositions: LearningScaleStep[];
      learningScaleId: number;
    },
    { dispatch }
  ) => {
    try {
      dispatch(learningScaleStepsRepositioned({ positions: newPositions }));
      await learningScaleService.repositionSteps({
        learningScaleId,
        positions: newPositions.map((step) => ({ learningScaleStepId: step.id, position: step.position })),
      });
    } catch (error) {
      dispatchErrorPopup(error);
      dispatch(learningScaleStepsRepositioned({ positions: oldPositions }));
    }
  }
);

const initialState = {
  id: null,
  title: null,
  enrollmentDate: null,
  creditArchitectureId: null,
  steps: [] as LearningScale["steps"],
  showArchivedSteps: false,
};

const learningScalesSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    learningScalesReceived(state, action) {
      return { ...action.payload, showArchivedSteps: false };
    },
    learningScaleRemoved(_state, _action) {
      return initialState;
    },
    switchShowArchivedSteps(state, action) {
      state.showArchivedSteps = action.payload;
    },
    addStepToLearningScale(state, action) {
      const { step } = action.payload as { step: LearningScaleStep };
      state.steps = [...state.steps, step];
    },
    learningScaleStepsRepositioned(state, action) {
      const { positions } = action.payload as { positions: { id: number; position: number }[] };
      positions.forEach(({ id, position }) => {
        const stepIndex = state.steps.findIndex(({ id: stepId }: { id: number }) => id === stepId);
        state.steps[stepIndex].position = position;
      });
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createLearningScale.fulfilled, (state, action) => {
        return { ...action.payload, showArchivedSteps: false };
      })
      .addCase(updateLearningScale.fulfilled, (state, action) => {
        return { ...state, ...action.payload };
      })
      .addCase(applyLearningScaleConfiguration.fulfilled, (state, action) => {
        const { steps } = action.payload as { steps: LearningScaleStep[] };
        state.steps.push(...steps);
      })
      .addCase(updateLearningScaleStep.fulfilled, (state, action) => {
        const { stepId, title, description } = action.payload as {
          stepId: number;
          title: string;
          description: string;
        };
        const stepIndex = state.steps.findIndex((step: LearningScaleStep) => step.id === stepId);
        state.steps[stepIndex].title = title;
        state.steps[stepIndex].description = description;
      })
      .addCase(MCARemoved, (_state, _action) => {
        return initialState;
      })
      .addMatcher(
        isAnyOf(archiveLearningScaleStep.fulfilled, unarchiveLearningScaleStep.fulfilled),
        (state, action) => {
          const { archivedAt, stepId } = action.payload as { archivedAt: null | string; stepId: number };
          const stepIndex = state.steps.findIndex(({ id }: { id: number }) => id === stepId);
          state.steps[stepIndex].archivedAt = archivedAt;
        }
      ),
});

export const {
  learningScalesReceived,
  switchShowArchivedSteps,
  addStepToLearningScale,
  learningScaleStepsRepositioned,
  learningScaleRemoved,
} = learningScalesSlice.actions;

export default learningScalesSlice.reducer;
