import * as types from "../constants";

const initialState = {
  open: false,
  popupType: null,
  content: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SHOW_POPUP: {
      const { popupType, content } = payload;
      return { open: true, popupType, content };
    }

    case types.HIDE_POPUP:
      return initialState;

    default:
      return state;
  }
};
