import { initSentry } from "config/sentry";
import { initi18n } from "config/i18n";
import { initCrowdin } from "config/crowdin";

const { REACT_APP_SENTRY_DNS, REACT_APP_ENABLE_CROWDIN } = process.env;

const services = [
  // Crowdin is a cloud-based solution that streamlines localization management
  {
    name: "Crowdin",
    init: initCrowdin,
    canStart: REACT_APP_ENABLE_CROWDIN,
  },
  // Sentry's application monitoring platform helps every developer
  // diagnose, fix, and optimize the performance of their code.
  {
    name: "Sentry",
    init: initSentry,
    canStart: REACT_APP_SENTRY_DNS,
  },
  // internalization library
  {
    name: "i18n",
    init: initi18n,
    canStart: true,
  },
];

function initServices() {
  services.forEach(({ canStart, init }) => {
    if (canStart) {
      init();
    }
  });
}

export { initServices };
