import BaseService from "services/baseService";
import FetchService from "services/fetchService";
import { OffsetBasedListResponse, PaginatedQueryParams, User, UserRole, UserStatus } from "services/types";

export type MtcTeamMember = Omit<User, "authorities" | "schoolAcademicYearType" | "schools"> & {
  role: UserRole.ROLE_SUPER_ADMIN | UserRole.ROLE_SUPPORT_ADMIN | UserRole.ROLE_TEAMMATE;
};

type GetMembersQuery = PaginatedQueryParams & {
  role?: UserRole.ROLE_SUPER_ADMIN | UserRole.ROLE_SUPPORT_ADMIN | UserRole.ROLE_TEAMMATE;
  status?: UserStatus;
};

class MtcTeamService extends BaseService {
  servicePath = "mtc/team";

  async getMembers({ page = 1, limit = 16, sort = "firstName,asc", filter, role, status }: GetMembersQuery) {
    return FetchService.get<OffsetBasedListResponse<MtcTeamMember>>({
      url: this.getUrl(),
      data: {
        page,
        limit,
        sort,
        filter,
        role,
        status,
      },
    });
  }

  async invite({ email, firstName, lastName, role }: Pick<MtcTeamMember, "email" | "firstName" | "lastName" | "role">) {
    return FetchService.post<true>({
      url: this.getUrl("invite"),
      data: { email, firstName, lastName, role },
    });
  }

  async reinvite({ email }: { email: string }) {
    return FetchService.post<true>({
      url: this.getUrl("reinvite"),
      data: { email },
    });
  }
}

export default new MtcTeamService();
