import React, { ChangeEvent, useState } from "react";
import platform from "platform";

import { Box, Grid, IconButton } from "@mui/material";
import { Body2, H1 } from "components/typography";
import { QuestionCircleIcon } from "svg";
import { Form, InputField, SubmitBtn, TextareaField } from "components/form/helpers";
import { ButtonContained, ButtonOutlined } from "components/buttons";
import supportService, { SendQuestionParams } from "services/supportService";
import { dispatchPopup } from "redux/actions/popupActions";

import { PopoverWrapper } from "components/popover/helpers/PopoverWrapper";
import { ScreenshotSize, StyledCloseIcon, SupportFab } from "components/popover/styles";

import Yup, { BOOLEAN } from "validation";
import { useTypedSelector } from "hooks";

const supportSchema = Yup.object({
  question: Yup.string().default("").required(),
  userEmail: Yup.string().default("").email().required(),
  screenshot: BOOLEAN.default(false),
});

const getSystemInfo = (system: typeof platform) => ({
  os: `${system.os?.family} x${system.os?.architecture}`,
  osVersion: system.os?.version,
  browser: system.name,
  browserVersion: system.version,
  resolution: `${window.screen.width}x${window.screen.height}`,
});

const Support = ({ onClose }: { onClose: () => void }) => {
  const account = useTypedSelector((state) => state.account);

  const [screenshot, setScreenshot] = useState<File | null>(null);

  const handleScreenshot = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setScreenshot(file);
    e.target.value = "";
  };

  const onSuccess = () => {
    onClose();
    dispatchPopup("success", { tag: "formResults.SUPPORT_REQUEST_SUCCESS" });
  };

  const onRequest = ({ userEmail, question }: { userEmail: string; question: string }) => {
    const systemInformation = JSON.stringify(getSystemInfo(platform));

    const requestData: SendQuestionParams = {
      userEmail,
      question,
      systemInformation,
    };

    if (screenshot) {
      const formData = new FormData();
      const blob = new Blob([screenshot]);
      formData.append("screenshot", blob);
      requestData.screenshot = formData;
    }

    return supportService.sendQuestion(requestData);
  };

  return (
    <Box px={11} pt={6} pb={10} width="390px" maxWidth="100%">
      <H1 content="feedback.title" align="center" mb={6} />
      <Body2 content="feedback.desc" mb={6} />
      <Form
        handleResult={onSuccess}
        request={onRequest}
        defaultData={{ ...supportSchema.getDefault(), userEmail: account?.email }}
        validationSchema={supportSchema}
        formName="feedback"
      >
        {(form) => (
          <>
            <TextareaField
              minRows={4}
              name="question"
              formData={form}
              placeholderI18n="placeholders.feedback.question"
            />
            <InputField name="userEmail" label="formLabels.email" formData={form} />

            <Box mt={5}>
              <label htmlFor="screenshot">
                <input
                  accept="image/*"
                  type="file"
                  onChange={handleScreenshot}
                  id="screenshot"
                  style={{ display: "none" }}
                  disabled={!!screenshot}
                />
                <ButtonContained
                  variant="outlined"
                  component="span"
                  fullWidth
                  color="primary"
                  content="formLabels.screenshot"
                  disabled={!!screenshot}
                />
              </label>
            </Box>

            {screenshot && (
              <Box mt={4.25}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Body2 isBold color="primary" align="center">
                    {screenshot.name}
                  </Body2>
                  <ScreenshotSize ml={1} mr={2} isBold>
                    ({(screenshot.size / 1024 / 1024).toFixed(1)} mb)
                  </ScreenshotSize>
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      setScreenshot(null);
                      form.clearResponseErrors();
                    }}
                  >
                    <StyledCloseIcon />
                  </IconButton>
                </Grid>
              </Box>
            )}

            <Box mt={8} mb={1}>
              <Grid container spacing={6}>
                <Grid item xs sm={6}>
                  <SubmitBtn tag="submit" disabled={form.state.waitingResponse} />
                </Grid>
                <Grid item xs sm={6}>
                  <ButtonOutlined content="buttons.close" onClick={onClose} fullWidth />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Form>
    </Box>
  );
};

export const SupportPopover = React.memo(function SupportPopover() {
  return (
    <PopoverWrapper
      button={
        <SupportFab aria-label="open support form">
          <QuestionCircleIcon htmlColor="white" />
        </SupportFab>
      }
      /*@ts-expect-error the onClose prop is missing here, but it is passed inside the PopoverWrapper*/
      content={<Support />}
      placement="top-end"
    />
  );
});
