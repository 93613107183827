import BaseService from "services/baseService";
import FetchService from "services/fetchService";

import store from "../redux/store";
import {
  College,
  CommunityAttachmentType,
  CommunityFile,
  CommunityFolder,
  CommunityGroupMemberStatus,
  CommunityGroupType,
  CommunityPostType,
  GrowArea,
  GrowCredit,
  LinkPreview,
  OffsetBasedListResponse,
  PaginatedQueryParams,
  PublicUserInfo,
  School,
  User,
} from "services/types";

export type CommunityUser = PublicUserInfo &
  Pick<User, "email" | "expirationDate" | "lastLoginDate" | "role" | "schoolId" | "title" | "status"> & {
    schools?: Pick<School, "id" | "name">[];
  };

type GroupMember = CommunityUser & { memberStatus: CommunityGroupMemberStatus };

type GroupMembership = {
  group: Pick<
    CommunityGroup,
    "coverPictureUrl" | "description" | "id" | "lastActivity" | "name" | "showLearnerColleges" | "type"
  >;
  user: PublicUserInfo &
    Pick<User, "email" | "expirationDate" | "schoolId" | "title" | "status" | "schoolName" | "isDemoAccount">;
};

type PostLinkPreview = {
  // TODO url and title  cannot be null in practice but backend allows it
  url: string;
  title: string;
  description: string | null;
  thumbnailHeight: number | null;
  thumbnailType: string | null;
  thumbnailUrl: string | null;
  thumbnailWidth: number | null;
};

type CommunityAttachment = {
  id: number;
  fileUrl: string;
  commentId: number | null;
  postId: number | null;
  createdAt: string;
  type: CommunityAttachmentType;
};

export type CommunityComment = {
  attachments: CommunityAttachment[];
  commentableId: number;
  content: string;
  createdAt: string;
  id: number;
  likeCount: number;
  likes: CommunityUser[];
  parentCommentId: number | null;
  updatedAt: string;
  user: CommunityUser;
};

type CommunityPostStatus = "PUBLISHED" | "DRAFT";

export type CommunityPost = {
  id: number;
  title: string | null;
  content: string;
  embed: PostLinkPreview | null;
  status: CommunityPostStatus;
  publishedAt: string;
  createdAt: string;
  updatedAt: string;
  lastActivity: string | null;
  type: CommunityPostType;
  user: CommunityUser;
  comments: CommunityComment[];
  attachments: CommunityAttachment[];
  likes: CommunityUser[];
  likeCount: number;
  schedulePublishDate?: string | null;
};

type CommunityGroup = {
  id: number;
  name: string;
  description: string;
  coverPictureUrl: string | null;
  lastActivity: string | null;
  showLearnerColleges: boolean;
  type: CommunityGroupType;
  activeMembersCount: number;
  membershipRequestsCount: number;
  isCurrentUserMember: boolean;
  isMembershipRequested: boolean;
};

type UserCommunityGroup = Pick<
  CommunityGroup,
  "coverPictureUrl" | "description" | "id" | "lastActivity" | "name" | "showLearnerColleges" | "type"
> & { status: CommunityGroupMemberStatus };

type CommunityFolderTree = CommunityFolder & { children: CommunityFolderTree[] };

export type CreateGroupDTO = {
  data: {
    name: string;
    description: string;
    coverPictureUrl?: string | null;
    type: CommunityGroupType;
    showLearnerColleges?: boolean;
  };
};

export type UpdateGroupDTO = {
  groupId: number;
  data: {
    name?: string;
    description?: string;
    coverPictureUrl?: string | null;
    type?: CommunityGroupType;
    showLearnerColleges?: boolean;
  };
};

export type CreatePostDTO = {
  title?: string | null;
  content: string;
  groupId?: number | null;
  type: CommunityPostType;
  status?: CommunityPostStatus;
  attachmentIds?: number[];
  mentionedUserIds?: number[];
  embed?: PostLinkPreview | null;
  schedulePublishDate?: string | null;
};

export type UpdatePostDTO = {
  postId: number;
  data: {
    title?: string;
    content?: string;
    type?: CommunityPostType;
    status?: CommunityPostStatus;
    attachmentIds?: number[];
    mentionedUserIds?: number[];
    embed?: PostLinkPreview | null;
    schedulePublishDate?: string | null;
  };
};

export type CreateCommunityFileDTO = {
  data: {
    folderId?: number | null;
    groupId?: number | null;
    name: string;
    description?: string | null;
    thumbnailUrl?: string;
    fileUrl: string;
    openInNewTab?: boolean;
    position?: number | null;
  };
};

export type UpdateCommunityFileDTO = {
  fileId: number;
  data: {
    folderId?: number | null;
    groupId?: number | null;
    name?: string;
    description?: string | null;
    thumbnailUrl?: string;
    fileUrl?: string;
    openInNewTab?: boolean;
    position?: number | null;
  };
};

type GetPostsQueryParams = PaginatedQueryParams & {
  groupId?: number;
  postId?: number;
  type: string[];
  publicationDateFrom?: string;
  publicationDateTo?: string;
  publicationAuthor?: number;
};

export type CreateCommunityFolderDTO = {
  data: {
    groupId?: number | null;
    parentFolderId?: number | null;
    name: string;
    description?: string | null;
    thumbnailUrl?: string;
    position?: number | null;
  };
};

type UpdateCommunityFolderDTO = {
  folderId: number;
  data: {
    groupId?: number | null;
    name?: string;
    description?: string | null;
    thumbnailUrl?: string;
    position?: number | null;
  };
};

type GetGrowCreditsResponse = OffsetBasedListResponse<
  Pick<GrowArea, "archivedAt" | "id" | "position" | "title" | "description"> & {
    credits: (Pick<GrowCredit, "archivedAt" | "description" | "id" | "position" | "title" | "type"> & {
      area: string;
    })[];
  }
>;

type GetGrowAreasResponse = OffsetBasedListResponse<
  Pick<GrowArea, "archivedAt" | "id" | "position" | "title" | "description">
>;

class CommunityService extends BaseService {
  servicePath = "community";

  getGroupsUrl(...rest: (string | number | boolean)[]) {
    return this.getUrl("groups", ...rest);
  }

  getFilesUrl(...rest: (string | number | boolean)[]) {
    return this.getUrl("files", ...rest);
  }

  getFoldersUrl(...rest: (string | number | boolean)[]) {
    return this.getUrl("folders", ...rest);
  }

  getPostsUrl(...rest: (string | number | boolean)[]) {
    return this.getUrl("posts", ...rest);
  }

  getPosts({
    sort = "createdAt,desc",
    limit,
    page,
    groupId,
    filter,
    postId,
    type = [],
    publicationDateFrom,
    publicationDateTo,
    publicationAuthor,
  }: GetPostsQueryParams) {
    return FetchService.get<OffsetBasedListResponse<CommunityPost>>({
      url: this.getPostsUrl(),
      data: {
        sort,
        limit,
        page,
        groupId,
        filter,
        postId,
        type,
        publicationDateFrom,
        publicationDateTo,
        userId: publicationAuthor,
      },
    });
  }

  getOwnPosts({ state, limit = 20, page = 1, sort }: PaginatedQueryParams & { state: "all" | "published" | "draft" }) {
    return FetchService.get<OffsetBasedListResponse<CommunityPost>>({
      url: this.getPostsUrl("own"),
      data: { state, limit, page, sort },
    });
  }

  getGroups({
    page,
    limit = 8,
    sort = "createdAt,desc",
    filter,
    type,
    state,
  }: PaginatedQueryParams & { type: CommunityGroupType; state: "all" | "member" | "not_member" }) {
    return FetchService.get<OffsetBasedListResponse<CommunityGroup>>({
      url: this.getGroupsUrl(),
      data: { page, limit, sort, filter, type, state },
    });
  }

  getGroup({ groupId }: { groupId: number }) {
    return FetchService.get<CommunityGroup>({
      url: this.getGroupsUrl(groupId),
    });
  }

  getUserGroups({
    // TODO it requires changes on backend - the "userId" can be removed since the authenticated user id is known to the backend.
    userId = store.getState().account.id,
    sort = "createdAt,desc",
    filter,
    status,
    limit = 4,
    page,
  }: PaginatedQueryParams & { userId: number; status?: CommunityGroupMemberStatus[] }) {
    return FetchService.get<OffsetBasedListResponse<UserCommunityGroup>>({
      url: this.getGroupsUrl("members", userId),
      data: { sort, filter, status, limit, page },
    });
  }

  createGroup({ data }: CreateGroupDTO) {
    return FetchService.post<number>({ url: this.getGroupsUrl(), data });
  }

  updateGroup({ groupId, data }: UpdateGroupDTO) {
    return FetchService.put<void>({ url: this.getGroupsUrl(groupId), data });
  }

  removeGroup({ groupId }: { groupId: number }) {
    return FetchService.delete<void>({ url: this.getGroupsUrl(groupId) });
  }

  createPost(data: CreatePostDTO) {
    return FetchService.post<void>({ url: this.getPostsUrl(), data });
  }

  deletePost(postId: number) {
    return FetchService.delete<void>({ url: this.getPostsUrl(postId) });
  }

  updatePost({ postId, data }: UpdatePostDTO) {
    return FetchService.put<void>({ url: this.getPostsUrl(postId), data });
  }

  uploadGroupPhoto(data: FormData) {
    return FetchService.post<string>({ url: this.getGroupsUrl("photo"), data });
  }

  inviteGroupMembers({
    groupId,
    members,
    status,
  }: {
    groupId: number;
    members: { userId: number }[];
    status: CommunityGroupMemberStatus.INVITED | CommunityGroupMemberStatus.ACTIVE;
  }) {
    return FetchService.post<void>({
      url: this.getGroupsUrl(groupId, "members", "invite"),
      data: { members, status },
    });
  }

  getGroupMembers({ groupId, page, limit = 8, filter, sort }: PaginatedQueryParams & { groupId: number }) {
    return FetchService.get<OffsetBasedListResponse<GroupMember>>({
      url: this.getGroupsUrl(groupId, "members"),
      data: { page, limit, filter, sort },
    });
  }

  getGroupMembership({
    userId,
    groupId,
    page,
    limit = 8,
    filter,
    sort,
  }: PaginatedQueryParams & { groupId: number; userId?: number }) {
    return FetchService.get<OffsetBasedListResponse<GroupMembership>>({
      url: this.getGroupsUrl("membership"),
      data: { userId, groupId, page, limit, filter, sort },
    });
  }

  removeMembersFromGroup({ groupId, members }: { groupId: number; members: { userId: number }[] }) {
    return FetchService.delete<void>({
      url: this.getGroupsUrl(groupId, "members"),
      data: { members },
    });
  }

  requestMembership({ userId, groupId }: { userId: number; groupId: number }) {
    return FetchService.post<true>({
      url: this.getGroupsUrl(groupId, "members", userId, "request"),
    });
  }

  quitGroup({ userId, groupId }: { userId: number; groupId: number }) {
    return FetchService.delete<true>({
      url: this.getGroupsUrl(groupId, "members", userId),
    });
  }

  acceptOrDenyMembershipRequest(data: {
    requests: { groupId: number; userId: number; accept: { status: boolean; comment: string } }[];
  }) {
    return FetchService.post<true>({
      url: this.getGroupsUrl("membership", "accept"),
      data,
    });
  }

  getFiles({ folderId, groupId }: { folderId?: number | null; groupId?: number | null }) {
    return FetchService.get<{ items: CommunityFile[] }>({
      url: this.getFilesUrl(),
      data: { folderId, groupId },
    });
  }

  getFile({ fileId }: { fileId: number }) {
    return FetchService.get<CommunityFile>({ url: this.getFilesUrl(fileId) });
  }

  getFolders({ folderId, groupId }: { folderId?: number | null; groupId?: number | null }) {
    return FetchService.get<{ items: CommunityFolder[] }>({ url: this.getFoldersUrl(), data: { folderId, groupId } });
  }

  getFolder({ folderId }: { folderId: number }) {
    return FetchService.get<CommunityFolder>({ url: this.getFoldersUrl(folderId) });
  }

  getColleges({ groupId, page, limit, sort, filter, yog }: PaginatedQueryParams & { groupId: number; yog?: number }) {
    return FetchService.get<OffsetBasedListResponse<College>>({
      url: this.getGroupsUrl(groupId, "colleges"),
      data: { page, limit, filter, sort, yog },
    });
  }

  uploadFile({ groupId, file }: { groupId?: number; file: FormData }) {
    return FetchService.post<string>({
      url: this.getFilesUrl("uploadCommunityFile"),
      data: file,
      params: { groupId },
    });
  }

  createFile({ data }: CreateCommunityFileDTO) {
    return FetchService.post<{ id: number }>({
      url: this.getFilesUrl(),
      data,
    });
  }

  updateFile({ data, fileId }: UpdateCommunityFileDTO) {
    return FetchService.put<void>({
      url: this.getFilesUrl(fileId),
      data,
    });
  }

  deleteFile({ fileId }: { fileId: number }) {
    return FetchService.delete<true>({
      url: this.getFilesUrl(fileId),
    });
  }

  duplicateFile({ fileId }: { fileId: number }) {
    return FetchService.post<{ id: number }>({
      url: this.getFilesUrl(fileId, "duplicate"),
    });
  }

  moveFile({ fileId, folderId }: { fileId: number; folderId?: number | null }) {
    return FetchService.put<void>({
      url: this.getFilesUrl(fileId, "move"),
      data: { folderId },
    });
  }

  reorderFiles(data: { id: number; position: number }[]) {
    return FetchService.put<true>({
      url: this.getFilesUrl("position"),
      data,
    });
  }

  favoriteFile(fileId: number) {
    return FetchService.post<{ id: number }>({ url: this.getFilesUrl(fileId, "favorites") });
  }

  unFavoriteFile(fileId: number) {
    return FetchService.delete<true>({ url: this.getFilesUrl(fileId, "favorites") });
  }

  createFolder({ data }: CreateCommunityFolderDTO) {
    return FetchService.post<number>({ url: this.getFoldersUrl(), data });
  }

  updateFolder({ folderId, data }: UpdateCommunityFolderDTO) {
    return FetchService.put<true>({ url: this.getFoldersUrl(folderId), data });
  }

  deleteFolder({ folderId }: { folderId: number }) {
    return FetchService.delete<true>({ url: this.getFoldersUrl(folderId) });
  }

  duplicateFolder({ folderId }: { folderId: number }) {
    return FetchService.post<true>({ url: this.getFoldersUrl(folderId, "duplicate") });
  }

  moveFolder({ folderId, parentFolderId }: { folderId: number; parentFolderId?: string | null }) {
    return FetchService.put<true>({ url: this.getFoldersUrl(folderId, "move"), data: { parentFolderId } });
  }

  getFolderTree() {
    return FetchService.get<CommunityFolderTree[]>({ url: this.getFoldersUrl("tree") });
  }

  likePost(postId: number) {
    return FetchService.post<true>({ url: this.getPostsUrl(postId, "like") });
  }

  dislikePost(postId: number) {
    return FetchService.delete<void>({ url: this.getPostsUrl(postId, "like") });
  }

  getEmbeddableDataByUrl(url: string) {
    return FetchService.get<LinkPreview>({
      url: this.getPostsUrl("preview/url"),
      data: { url },
    });
  }

  reorderFolders(data: { id: number; position: number }[]) {
    return FetchService.put<true>({
      url: this.getFoldersUrl("position"),
      data,
    });
  }

  getGrowCredits({ fileId, state }: { fileId: number; state?: "all" | "assigned" | "not_assigned" }) {
    return FetchService.get<GetGrowCreditsResponse>({
      url: this.getFilesUrl(fileId, "grow", "credits"),
      data: { state },
    });
  }

  getGrowCreditAreas({ fileId, state }: { fileId: number; state?: "all" | "assigned" | "not_assigned" }) {
    return FetchService.get<GetGrowAreasResponse>({
      url: this.getFilesUrl(fileId, "grow", "areas"),
      data: { state },
    });
  }

  linkGrowCredits({ fileId, data }: { fileId: number; data: { credits: { id: number }[] } }) {
    return FetchService.post<true>({
      url: this.getFilesUrl(fileId, "grow/credits/bulk"),
      data,
    });
  }

  linkGrowCreditAreas({ fileId, data }: { fileId: number; data: { creditAreas: { id: number }[] } }) {
    return FetchService.post<true>({
      url: this.getFilesUrl(fileId, "grow/areas/bulk"),
      data,
    });
  }

  getFavorites(query: PaginatedQueryParams) {
    return FetchService.get<OffsetBasedListResponse<CommunityFile>>({
      url: this.getFilesUrl("favorites"),
      data: query,
    });
  }
}

export default new CommunityService();
