import _ from "lodash";
import { reach } from "yup";

import { FormArrayWrapper } from "../helpers/FormArrayWrapper";

export const handleFieldData = (props) => {
  const modifiedProps = { ...props };

  const { arrayId, formData } = modifiedProps;

  let {
    id,
    name,
    disabled,
    required,
    value = "",
    error = false,
    handleChanges,
    clearErrors,
    submit,
    marginDisabled,
  } = modifiedProps;

  if (!id && id !== 0) id = name;

  let formName = null;

  if (formData) {
    if (formData instanceof FormArrayWrapper) {
      const { name: arrName, highOrderForm, marginDisabled: globalMarginDisabler } = formData.props;
      const { waitingResponse } = highOrderForm.state;

      formName = highOrderForm.props.formName;

      const schema = highOrderForm.props.validationSchema;
      const schemaField = `${arrName}.${name}`;

      id = `${arrName}[${arrayId}]-${name}`;
      name = `${arrName}[${arrayId}].${name}`;

      const fieldValue = _.get(highOrderForm, `state.data.${name}`);
      value = fieldValue || fieldValue === 0 ? fieldValue : "";
      error = highOrderForm.state.errors[name];

      disabled = waitingResponse || disabled;
      // eslint-disable-next-line no-underscore-dangle
      required = schema && reach(schema, schemaField).exclusiveTests.required;

      handleChanges = formData.handleChanges;
      clearErrors = formData.clearErrors;
      submit = highOrderForm.onSubmit;

      if (globalMarginDisabler) marginDisabled = globalMarginDisabler;
    } else {
      const { data: formValues, errors: formErrors, waitingResponse } = formData.state;
      const { marginDisabled: globalMarginDisabler, validationSchema } = formData.props;

      value = formValues[name] || formValues[name] === 0 ? formValues[name] : "";
      error = formErrors[name] || false;

      disabled = waitingResponse || disabled;
      // eslint-disable-next-line no-underscore-dangle
      required = validationSchema && reach(validationSchema, name).exclusiveTests.required;

      formName = formData.props.formName;

      handleChanges = formData.handleChanges;
      clearErrors = formData.clearErrors;
      submit = formData.onSubmit;

      if (globalMarginDisabler) marginDisabled = globalMarginDisabler;
    }

    delete modifiedProps.formData;
  }

  return {
    ...modifiedProps,
    name,
    id,
    value,
    error,
    formName,
    disabled,
    required,
    handleChanges,
    clearErrors,
    marginDisabled,
    submit,
  };
};
