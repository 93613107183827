import { TypographyVariantsOptions } from "@mui/material/styles";

export const typography: TypographyVariantsOptions = {
  fontFamily: [
    "Nunito Sans",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  fontFamilyCondensed: [
    "Roboto Condensed",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  h1: {
    fontSize: "1.25rem",
    fontWeight: 500,
    lineHeight: 1.43,
  },
  h2: {
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1.43,
  },
  h3: {
    fontSize: ".875rem",
    fontWeight: 500,
    lineHeight: 1.43,
  },
  h4: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  h5: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  h6: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  body1: {
    fontSize: "1rem",
  },
  body2: {
    fontSize: ".875rem",
  },
  caption: {
    lineHeight: 1.43,
  },
  button: {
    textTransform: "none",
  },
};
