import { styled } from "@mui/material/styles";

import { FormControlLabel, IconButton, InputBase, Switch } from "@mui/material";
import { setSpacing, setTransition } from "../../styledComponents/actions";

// image upload
export const StyledImageUpload = styled("div")`
  position: relative;
  display: inline-block;

  &.error {
    label {
      box-shadow: 0 0 4px 4px ${(props) => props.theme.palette.error.main};

      &:focus {
        box-shadow: 0 0 4px 4px ${(props) => props.theme.palette.error.main};
      }
    }
  }

  label {
    display: block;
    cursor: pointer;
    border-radius: 50%;

    &:focus {
      outline: none;
      box-shadow: 0 0 4px 4px ${(props) => props.theme.palette.primary.main};
    }
  }

  .MuiBadge-root {
    border-radius: 50%;
    overflow: hidden;
  }

  &.squared {
    .MuiBadge-root {
      border-radius: 0;
    }

    label {
      border-radius: 0;
    }

    button {
      background: whitesmoke;
      padding: 4px;
      top: 3px;
      right: 3px;

      svg {
        font-size: 16px;
      }
    }
  }

  &.disabled .MuiBadge-badge {
    display: none;
  }

  .MuiBadge-badge {
    width: 100%;
    height: 40px;
    transform: translate(50%, -90%);
    right: 50%;
    top: 100%;
    background: rgba(0, 0, 0, 0.4);
    transition: ${setTransition()};
    opacity: 0;
    border-radius: 0;

    .MuiSvgIcon-root {
      color: white;
      margin-bottom: ${setSpacing(1)};
    }
  }

  &:hover:not(.disabled) {
    .MuiBadge-badge {
      opacity: 1;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }

  & > button {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(30%, -30%);
  }
`;

export const StyledImageLoader = styled("div")`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-radius: inherit;
  background-color: rgba(255, 255, 255, 0.8);
`;

// search bar
export const StyledSearchBar = styled("label")`
  // min-width: 320px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  border: 1px solid #e4e4e4;
  border-radius: 3px;
  min-width: 250px;
  background-color: ${(props) => props.theme.palette.common.white};
`;

export const StyledSearchBarInput = styled(InputBase)`
  color: inherit;
  width: 100%;
  height: 24px;

  > input {
    font-size: 12px;
    color: ${({ theme }) => theme.palette.common.black};
    padding: ${setSpacing(2.5)};
  }
`;

export const StyledSearchBarIcon = styled(IconButton)`
  padding: ${setSpacing(2)};

  &:hover {
    background: inherit;
  }

  &.clear {
    color: black;
    opacity: 0;
    transition: opacity ${(props) => props.theme.transitions.duration.shortest}ms;

    &:hover {
      background: inherit;
      opacity: 0;
      cursor: inherit;
    }

    &.active {
      opacity: 0.6;

      &:hover {
        background: inherit;
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
`;

export const MTCSwitch = styled(Switch)(() => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="white" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="white" d="M15.185 7.4l-3.184 3.185-3.186-3.186a.507.507 0 00-.712.003l-.7.701a.496.496 0 00-.004.712l3.185 3.184L7.4 15.185a.507.507 0 00.004.712l.7.7c.206.207.516.2.712.004l3.186-3.185 3.184 3.185a.507.507 0 00.712-.004l.701-.7a.496.496 0 00.003-.712l-3.186-3.186 3.186-3.184a.507.507 0 00-.003-.712l-.7-.7a.508.508 0 00-.36-.153.5.5 0 00-.353.15z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export const StyledFormSwitch = styled(FormControlLabel)`
  display: flex;
  justify-content: space-between;
  margin-left: 0;
  margin-right: 0;
`;
