import { ReactNode } from "react";
import { TextFieldProps } from "@mui/material";
import _ from "lodash";

import { Field } from "../../styledComponents/StyledForms";
import { getHelperText, getLabel, handleFieldData } from "../actions";

import { TODO_ANY } from "types";

type SelectFieldProps<T> = TextFieldProps & {
  formData?: TODO_ANY;
  itemsHandler: (el: T) => ReactNode;
  itemsList: T[];
  arrayId?: number;
};

export const SelectField = <T,>({
  InputProps,
  inputProps = {},
  size,
  color,
  focused,
  ...formProps
}: SelectFieldProps<T>) => {
  const {
    id,
    name,
    formName,
    label,
    value,
    helperText,
    defaultValue,
    itemsList,
    required,
    itemsHandler,
    error,
    disabled,
    marginDisabled,
    handleChanges,
    hideLabel,
    arrayId,
  } = handleFieldData(formProps);

  const fullFieldName = `${formName}.${name}`;

  return (
    <Field
      id={id}
      name={name}
      label={getLabel(name, label, hideLabel)}
      helperText={getHelperText(error, fullFieldName, helperText)}
      error={Boolean(error)}
      value={_.isNil(value) ? defaultValue || "" : value}
      onChange={(e) => handleChanges({ target: { ...e.target, id, rowIndex: arrayId } })}
      disabled={itemsList.length === 1 || disabled}
      required={required}
      margin={marginDisabled ? "none" : "normal"}
      inputProps={inputProps}
      InputProps={InputProps}
      size={size}
      SelectProps={{
        MenuProps: {
          disableScrollLock: true,
          style: {
            zIndex: 1300,
          },
          PaperProps: {
            style: {
              maxHeight: "30%",
            },
          },
        },
      }}
      fullWidth
      select
      color={color}
      focused={focused}
      data-test-id="durationUnit"
    >
      {itemsList.map(itemsHandler)}
    </Field>
  );
};
