import { styled } from "@mui/material/styles";

import { Accordion as MuiAccordion, Card, Dialog, Grid, LinearProgress } from "@mui/material";
import { setSpacing } from "../../styledComponents/actions";
import { Body1 } from "components/typography";

export const StyledDialog = styled(Dialog)`
  &.mobileWidth .MuiDialog-paper {
    max-width: 320px;
  }

  &.sm .MuiDialog-paper {
    max-width: 600px;
  }
  &.md .MuiDialog-paper {
    max-width: 960px;
  }
  &.wideWidth .MuiDialog-paper {
    max-width: 1120px;
  }
  &.mediumWidth .MuiDialog-paper {
    max-width: 865px;
  }
  &.dropzone {
    .MuiDialog-paper {
      padding: ${setSpacing(8.5)};
    }
    .closeBtn {
      display: none;
    }
  }
  &.noPadding .MuiDialog-paper {
    padding: 0;
  }
  .MuiDialog-paper {
    max-width: 520px;
    width: 100%;
    border-radius: 10px;
    padding: ${setSpacing(8)} ${setSpacing(10)};
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    ${(props) => props.theme.breakpoints.down("sm")} {
      max-height: 100%;
      margin: 0;
      padding: ${setSpacing(8)} ${setSpacing(8)} ${setSpacing(8)};
      border-radius: 0;
    }
  }
  .MuiDialog-paperScrollPaper {
    overflow-y: hidden;
  }
`;

export const UploadWindowWrapper = styled("div")`
  text-align: center;
`;

export const DropzoneWrapper = styled("div")`
  padding: ${setSpacing(10)};
  border: 1px dashed #a7a7a7;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  &.isDragActive {
    border-color: ${(props) => props.theme.palette.primary.main};
    background: rgba(21, 124, 185, 0.04);
  }
  &.error {
    border: 1px dashed ${(props) => props.theme.palette.error.main};
    background: rgba(255, 0, 0, 0.04);
  }
  &.disabled {
    cursor: initial;
    border: 1px solid #ececec;
  }
`;

export const ProgressBar = styled(LinearProgress)`
  width: 90%;
  height: 7px;
  background: ${({ theme }) => theme.palette.grey[300]};
  border-radius: 3px;
  margin: 0 auto;
  .MuiLinearProgress-bar {
    border-radius: 3px;
  }
`;

// MCA Warning

export const ErrorsCard = styled(Card)`
  max-width: 775px;
  margin-top: ${setSpacing(8)};
`;

export const ErrorsWrapper = styled("div")`
  margin-top: ${setSpacing(11)};
`;

export const ErrorTitle = styled(Body1)`
  display: block;
  margin-top: ${setSpacing(3)};
  font-size: 18px;
  font-weight: bold;
`;

export const ErrorSubtitle = styled(Body1)`
  display: block;
  margin-top: ${setSpacing(5)};
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.error.main};
`;

export const ErrorDisplay = styled(Grid)`
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: ${setSpacing(5)} 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
  &:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.palette.grey[200]};
  }
  svg {
    margin-top: ${setSpacing(1)};
    margin-right: ${setSpacing(5)};
    color: #e64e47;
  }
  span {
    line-height: 28px;
  }
`;

// Transcript publish

export const Accordion = styled(MuiAccordion)`
  border: 0;
  box-shadow: none;
  &:before {
    display: none;
  }
`;
