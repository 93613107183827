import counterpart from "counterpart";

import en from "locales/en.json";

const languages = [{ name: "en", file: en }];

function initi18n() {
  languages.forEach((el) => counterpart.registerTranslations(el.name, el.file));
}

export { initi18n };
