import { Avatar, AvatarProps } from "./Avatar";

type UserAvatarProps = {
  firstName: string;
  lastName: string;
} & AvatarProps;

const upperCaseFirstLetter = (str: string) => {
  if (!str.length) {
    return "";
  }

  return str[0].toUpperCase();
};

export const UserAvatar = ({ firstName, lastName, ...restProps }: UserAvatarProps) => {
  const initials = `${upperCaseFirstLetter(firstName)}${upperCaseFirstLetter(lastName)}`;
  const fullName = `${firstName} ${lastName}`;

  return (
    <Avatar name={fullName} {...restProps}>
      {initials}
    </Avatar>
  );
};
