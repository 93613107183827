export default {
  // Unauthenticated routes
  PAGE_500: "/auth/500",
  ACCEPT_INVITE: "/auth/accept-invite/:inviteCode",
  PASSWORD_RECOVERY_CANCEL: "/auth/cancel-password-recovery/:recoveryCode",
  PASSWORD_RECOVERY: "/auth/password-recovery/:recoveryCode",
  RESET_PASSWORD: "/auth/reset-password",
  RESET_PASSWORD_SUCCESS: "/auth/reset-password/success",
  SIGN_IN: "/auth/sign-in",
  CLEVER_SIGN_IN: "/auth/clever",
  CLEVER_SIGN_IN_REQUEST: "/auth/clever/request",
  CLEVER_SIGN_IN_STUDENT: "/auth/clever/request/student",
  LMS_SIGN_IN: "/auth/lms",
  UNSUBSCRIBE: "/auth/unsubscribe/:code",
  // Authenticated routes
  DASHBOARD: "/",
  COLLEGES: "/colleges",
  MANAGE_NUDGES: "/colleges/nudges",
  COURSE_LIBRARY: "/course-library",
  MCAs: "/mca-library",
  MCA: "/mca-library/:mcaId",
  MCA_IMPORT_WARNINGS: "/mca-library/warnings",
  MTC_TEAM: "/mtc-team",
  MTC_TEAM_MEMBER_PROFILE: "/mtc-team/:userId",
  ALL_SCHOOL_ADMINS: "/school-admins",
  SCHOOLS: "/schools",
  SCHOOL: "/schools/:schoolId",
  SCHOOL_ADMINS: "/schools/:schoolId/administrators",
  SCHOOL_ADMIN_PROFILE: "/schools/:schoolId/administrators/:userId",
  SCHOOL_COLLEGES: "/schools/:schoolId/colleges",
  SCHOOL_COLLEGE: "/schools/:schoolId/colleges/:collegeId",
  SCHOOL_COURSES: "/schools/:schoolId/courses",
  SCHOOL_COURSES_IMPORT: "/schools/:schoolId/courses/import",
  SCHOOL_EXPERIENCES_IMPORT: "/schools/:schoolId/experiences/import",
  SCHOOL_EDIT: "/schools/:schoolId/edit",
  EDUCATORS: "/schools/:schoolId/educators",
  EDUCATOR_PROFILE: "/schools/:schoolId/educators/:userId",
  EDUCATOR_EDIT: "/schools/:schoolId/educators/:userId/edit",
  EDUCATORS_IMPORT: "/schools/:schoolId/educators/import",
  LEARNERS: "/schools/:schoolId/learners",
  LEARNER_PROFILE: "/schools/:schoolId/learners/:userId",
  LEARNER_COLLEGES: "/schools/:schoolId/learners/:userId/colleges",
  LEARNER_PROGRESS_TRACKER: "/profile/progress",
  LEARNER_EDIT: "/schools/:schoolId/learners/:userId/edit",
  LEARNER_EDIT_PROFILE: "/schools/:schoolId/learners/:userId/edit/profile",
  LEARNER_EDIT_CAREGIVERS: "/schools/:schoolId/learners/:userId/edit/caregivers",
  LEARNER_EDIT_PASSWORD: "/schools/:schoolId/learners/:userId/edit/password",
  LEARNER_EDIT_ACCOUNT: "/schools/:schoolId/learners/:userId/edit/account",
  EVIDENCE: "/schools/:schoolId/learners/:userId/evidence/:evidenceId",
  EVIDENCE_COMPARE: "/schools/:schoolId/learners/:userId/evidence/:evidenceId/compare",
  LEARNERS_IMPORT: "/schools/:schoolId/learners/import",
  SCHOOL_MCA: "/schools/:schoolId/mca",
  SHARED_MCAs: "/schools/:schoolId/mca/shared-models",
  SHARED_MCA: "/schools/:schoolId/mca/shared-models/:mcaId",
  SCHOOL_MCA_IMPORT_WARNINGS: "/schools/:schoolId/mca/warnings",
  TRANSCRIPT_V2: "/schools/:schoolId/learners/:userId/transcript-v2",
  LEARNING_RECORD: "/schools/:schoolId/learners/:learnerId/learning-record",
  PROGRESS_REPORT: "/schools/:schoolId/learners/:userId/progress-report",
  USER_PROFILE: "/user-profile",
  USER_PROFILE_NOTIFICATIONS: "/user-profile/notifications",
  CREDIT_LIBRARY: "/credits",
  CREDIT_AREA_LIBRARY: "/credit-areas",
  PUBLIC_USER_PROFILE: "/users/:userId",
  LIVE_FEED: "/live-feed",
  COMMUNITY_GROUPS: "/groups",
  COMMUNITY_GROUP: "/groups/:groupId",
  COMMUNITY_GROUP_FEED: "/groups/:groupId/feed",
  COMMUNITY_GROUP_MEMBERS: "/groups/:groupId/members",
  COMMUNITY_GROUP_REQUESTS: "/groups/:groupId/requests",
  COMMUNITY_GROUP_COLLEGES: "/groups/:groupId/colleges",
  COMMUNITY_MY_GROUPS: "/groups/my-groups",
  COMMUNITY_DISCOVER_GROUPS: "/groups/discover-groups",
  GROUP_POST: "/groups/:groupId/live-feed/:postId",
  POST: "/live-feed/:postId",
  MEDIA_LIBRARY: "/resources",
  FAVORITES_PAGE: "/resources/favorites",
  FILE_PAGE: "/resources/files/:fileId",
  GROUP_FILE_PAGE: "/groups/:groupId/file/:fileId",
  GROUP_FOLDER_PAGE: "/groups/:groupId/folder/:folderId",
  FOLDER_PAGE: "/resources/folders/:folderId",
  MTC_GROW: "/mtc-grow",
  GROW_EVIDENCE: "/mtc-grow/evidence/:evidenceId",
  SCHOOL_GROW: "/schools/:schoolId/grow",
  SCHOOL_GROW_CREDIT: "/schools/:schoolId/grow/credits/:creditId",
  SCHOOL_GROW_CREDIT_EVIDENCE_LIST: "/schools/:schoolId/grow/credits/:creditId/evidence",
  SCHOOL_GROW_CREDIT_EVIDENCE: "/schools/:schoolId/grow/credits/:creditId/evidence/:evidenceId",
  SCHOOL_GROW_CREDIT_RESOURCES: "/schools/:schoolId/grow/credits/:creditId/resources",
  SCHOOL_GROW_CREDIT_RESOURCE: "/schools/:schoolId/grow/credits/:creditId/resources/:resourceId",
  SCHOOL_GROW_CREDIT_SHARED_EVIDENCE_LIST: "/schools/:schoolId/grow/credits/:creditId/shared-evidence",
  SCHOOL_GROW_CREDIT_SHARED_EVIDENCE: "/schools/:schoolId/grow/credits/:creditId/shared-evidence/:evidenceId",
  SCHOOL_EVENTS: "/schools/:schoolId/events",
};
