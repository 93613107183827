import _ from "lodash";

import { Tooltip } from "@mui/material";
import { Body2 } from "components/typography";
import React from "react";

export type TItem = {
  value: React.ReactNode | null;
  tooltip?: NonNullable<React.ReactNode>;
  name: string;
  label?: string;
  isBold?: boolean;
  handleData?: (values: unknown) => string | number;
};

type CardListProps<T> = {
  items: T[];
  fontSize?: number;
  values?: unknown;
};

export const CardList = <T extends TItem>({ items, fontSize = 13, values }: CardListProps<T>) => {
  const normalizedItems = _.map(items, (item) => {
    const isEmpty = item.value === "" || item.value === null;
    return isEmpty ? { ...item, value: "-" } : item;
  });

  return (
    <>
      {_.map(normalizedItems, ({ value, name, label, handleData, isBold, tooltip = "" }) => {
        return (
          <Body2 key={name} mb={1} noWrap style={{ fontSize }}>
            <Body2 color="textSecondary" content={label || `formLabels.${name}`} component="span" />
            <Body2 component="span" mr={2}>
              :
            </Body2>

            <Tooltip key={name} title={tooltip} placement="bottom-start">
              <span>
                <Body2 isBold={isBold} text={handleData ? handleData(values) : value} component="span" />
              </span>
            </Tooltip>
          </Body2>
        );
      })}
    </>
  );
};
