import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ReactNode } from "react";
import { QueryClientProvider } from "react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "lib/react-query";

import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import appTheme from "theme";
import store from "redux/store";
import ErrorBoundary from "components/pageLayout/helpers/ErrorBoundary";

type AppProvidersProps = { children: ReactNode };

function AppProviders({ children }: AppProvidersProps) {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GAUTH_ID}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <ThemeProvider theme={appTheme}>
                <CssBaseline />
                <ErrorBoundary onReset={() => window.location.reload()}>{children}</ErrorBoundary>
              </ThemeProvider>
            </LocalizationProvider>
          </Provider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export { AppProviders };
