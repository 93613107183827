import { Component } from "react";
import _ from "lodash";

class FormArrayWrapper extends Component {
  getData = () => {
    const { name, highOrderForm } = this.props;
    return _.get(highOrderForm.state.data, name);
  };

  onAdd = (initialValues = {}) => {
    const arr = this.getData() || [];

    this.callback([...arr, { ...initialValues, id: _.uniqueId() }]);
  };

  onDelete = (id) => {
    const arr = this.getData();
    this.callback(
      arr.filter((_item, i) => i !== id),
      {}
    );
  };

  clearErrors = (e) => {
    const { name } = e.target;
    const { highOrderForm } = this.props;
    const { errors } = highOrderForm.state;

    if (!errors[name]) return;

    const newErrors = highOrderForm.checkErrors(name);

    this.callback("", newErrors);
  };

  handleChanges = async (e) => {
    const { name, value, rowIndex } = e.target;
    const { highOrderForm, name: arrayName } = this.props;

    const data = _.get(highOrderForm.state.data, arrayName);
    const arr = name.split(".");
    const inputName = arr[arr.length - 1];
    data[rowIndex][inputName] = value;

    const getObjectToCheck = () => {
      // simple case
      if (arr.length < 3) return { [arrayName]: data };

      // case with subsections
      const [sectionPart, subsectionPart] = arr; // i.e. sections[0] & subsections[1]
      const regex = /\[.*?]/g;

      // get object props from input name
      const sectionName = sectionPart.replace(regex, "");
      const subsectionName = subsectionPart.replace(regex, "");

      const obj = { [sectionName]: [] }; // i.e. { sections: [] }
      _.set(obj, sectionPart, { [subsectionName]: data }); // update subsection item
      return obj;
    };

    const errors = highOrderForm.checkErrors(name, getObjectToCheck());
    this.callback(data, errors);
  };

  onSubmit = () => {
    const { highOrderForm } = this.props;
    highOrderForm.onSubmit();
  };

  callback = (value, errors) => {
    const { name, highOrderForm } = this.props;
    highOrderForm.handleArrayChanges({ name, value, errors });
  };

  updateData = (newData) => {
    this.callback(newData, {}); // assuming no errors, you may need to adjust this
  };

  componentDidMount() {
    const { onReady } = this.props;

    if (onReady) {
      onReady({
        updateData: this.updateData,
      });
    }
  }

  render() {
    const { children } = this.props;
    return children(this);
  }
}

export { FormArrayWrapper };
