/**
 * it is in inputProps because it is better to attach to an input and not to a Field for better A11y
 * in cases when we have buttons in Field now we can activate them through enter button and not trigger field itself
 */
export const handleSubmit = (e, handleChanges, submit) => {
  if (e.key !== "Enter") return;
  e.preventDefault();

  handleChanges(e).then(submit);
};
