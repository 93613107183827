import React, { Reducer, useCallback, useLayoutEffect, useReducer, useRef } from "react";
import { loadTimeout } from "utils";
import FormError from "services/formError";

export type QueryStatus = "loading" | "error" | "success";

type UseAsyncBaseResult<T> = {
  status: QueryStatus;
  data: T | undefined;
  error: FormError | null;
  run: (args: Promise<unknown> | Promise<unknown>[]) => Promise<void>;
  setData: (data: T) => void;
  setError: (error: FormError) => void;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
};

type UseAsyncLoadingResult<T> = UseAsyncBaseResult<T> & {
  status: "loading";
  data: undefined;
  error: null;
  isLoading: true;
  isError: false;
  isSuccess: false;
};

type UseAsyncErrorResult<T> = UseAsyncBaseResult<T> & {
  status: "error";
  data: undefined;
  error: FormError;
  isLoading: false;
  isError: true;
  isSuccess: false;
};

type UseAsyncSuccessResult<T> = UseAsyncBaseResult<T> & {
  status: "success";
  data: T;
  error: null;
  isLoading: false;
  isError: false;
  isSuccess: true;
};

type UseAsyncReturn<T> = UseAsyncLoadingResult<T> | UseAsyncErrorResult<T> | UseAsyncSuccessResult<T>;

type Options = {
  sleep?: number;
};

type Action<T> =
  | {
      status: "loading";
    }
  | { status: "success"; payload: T }
  | { status: "error"; error: FormError };

type State<T> = {
  status: QueryStatus;
  data: undefined | T;
  error: null | FormError;
};

const asyncReducer = <T>(state: State<T>, action: Action<T>) => {
  switch (action.status) {
    case "loading":
      return { status: action.status, data: undefined, error: null };
    case "success":
      return { status: action.status, data: action.payload, error: null };
    case "error":
      return { status: action.status, data: undefined, error: action.error };
  }
};

const useSafeDispatch = <T>(dispatch: React.Dispatch<T>) => {
  const mountedRef = useRef(false);

  useLayoutEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return useCallback((args: T) => (mountedRef.current ? dispatch(args) : void 0), [dispatch]);
};

const initialState = {
  status: "loading" as const,
  data: undefined,
  error: null,
};

export const useAsync = <T>(params?: Options): UseAsyncReturn<T> => {
  const [state, unsafeDispatch] = useReducer<Reducer<State<T>, Action<T>>>(asyncReducer, initialState);

  const dispatch = useSafeDispatch<Action<T>>(unsafeDispatch);

  const { data, error, status } = state;
  const sleep = params?.sleep;

  const run = useCallback(
    async (promises: Promise<unknown> | Promise<unknown>[]) => {
      dispatch({ status: "loading" });

      try {
        if (sleep) {
          await loadTimeout(sleep);
        }
        const data = Array.isArray(promises) ? await Promise.all(promises) : await promises;

        dispatch({ status: "success", payload: data as T });
      } catch (error) {
        if (error instanceof FormError) {
          dispatch({
            status: "error",
            error: error.response ? error : new FormError("LOADING_FAIL"),
          });
        } else {
          console.warn("-----", "Unknown error", error);
        }
      }
    },
    [dispatch, sleep]
  );

  const setData = useCallback((data: T) => dispatch({ status: "success", payload: data }), [dispatch]);

  const setError = useCallback((error: FormError) => dispatch({ status: "error", error }), [dispatch]);

  return {
    error,
    status,
    data,
    run,
    setData,
    setError,
    isLoading: status === "loading",
    isError: status === "error",
    isSuccess: status === "success",
  } as UseAsyncReturn<T>;
};
