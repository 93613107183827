import { combineReducers } from "@reduxjs/toolkit";
import modal from "./modal";
import pageUpdFlag from "./pageUpdFlag";
import popup from "./popupSlice";
import transcriptData from "./transcriptData";
import evidences from "./evidence";

import activitiesReducer, { STATE_KEY as ACTIVITIES_STATE_KEY } from "../slices/activitiesSlice";
import accountReducer, { STATE_KEY as ACCOUNT_STATE_KEY } from "../slices/accountSlice";
import usersReducer, { STATE_KEY as USERS_STATE_KEY } from "../slices/usersSlice";
import MCAsReducer, { STATE_KEY as MCA_STATE_KEY } from "../slices/mcasSlice";
import creditAreasReducer, { STATE_KEY as CREDIT_AREAS_STATE_KEY } from "../slices/creditAreasSlice";
import creditLabelsReducer, { STATE_KEY as CREDIT_LABELS_STATE_KEY } from "../slices/creditLabelsSlice";
import creditsReducer, { STATE_KEY as CREDITS_STATE_KEY } from "../slices/creditsSlice";
import conversationsReducer, { STATE_KEY as CONVERSATIONS_STATE_KEY } from "../slices/conversationsSlice";
import messagesReducer, { STATE_KEY as MESSAGES_STATE_KEY } from "../slices/messagesSlice";
import mediaFilesReducer from "../slices/mediaFilesSlice";
import { STATE_KEY as MEDIA_FILES_STATE_KEY } from "../slices/mediaFilesActions";
import mediaFoldersReducer, { STATE_KEY as MEDIA_FOLDERS_STATE_KEY } from "../slices/mediaFoldersSlice";
import schoolGrowEvidenceReducer, {
  STATE_KEY as SCHOOL_GROW_EVIDENCE_STATE_KEY,
} from "redux/slices/schoolGrowEvidenceSlice";
import growCreditsReducer, { STATE_KEY as GROW_CREDITS_STATE_KEY } from "redux/slices/growCreditsSlice";
import learningScalesReducer, { STATE_KEY as LEARNING_SCALES_STATE_KEY } from "redux/slices/learningScalesSlice";
import progressReportReducer, { STATE_KEY as PROGRESS_REPORT_STATE_KEY } from "redux/slices/progressReportSlice";

export default combineReducers({
  [ACCOUNT_STATE_KEY]: accountReducer,
  [ACTIVITIES_STATE_KEY]: activitiesReducer,
  [CONVERSATIONS_STATE_KEY]: conversationsReducer,
  [MESSAGES_STATE_KEY]: messagesReducer,
  [USERS_STATE_KEY]: usersReducer,
  [MCA_STATE_KEY]: MCAsReducer,
  [CREDIT_AREAS_STATE_KEY]: creditAreasReducer,
  [CREDIT_LABELS_STATE_KEY]: creditLabelsReducer,
  [CREDITS_STATE_KEY]: creditsReducer,
  [MEDIA_FILES_STATE_KEY]: mediaFilesReducer,
  [MEDIA_FOLDERS_STATE_KEY]: mediaFoldersReducer,
  [SCHOOL_GROW_EVIDENCE_STATE_KEY]: schoolGrowEvidenceReducer,
  [GROW_CREDITS_STATE_KEY]: growCreditsReducer,
  [LEARNING_SCALES_STATE_KEY]: learningScalesReducer,
  [PROGRESS_REPORT_STATE_KEY]: progressReportReducer,
  transcriptData,
  evidences,
  modal,
  pageUpdFlag,
  popup,
});
