import { ClickAwayListener, Grow, Paper, PopperProps } from "@mui/material";
import { MouseEvent, useState } from "react";
import { StyledPopper } from "components/popover/styles";
import { PopperUnstyledProps, ClickAwayListenerProps } from "@mui/base";

type PopoverWrapperProps = Omit<PopperProps, "open"> & {
  button: JSX.Element;
  content: JSX.Element;
};

export const PopoverWrapper = ({ button, content, ...rest }: PopoverWrapperProps) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<PopperUnstyledProps["anchorEl"]>();

  const handleClick = (e: MouseEvent) => {
    setAnchorEl(e.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClose = () => setOpen(false);

  const onClickAway: ClickAwayListenerProps["onClickAway"] = (event) => {
    // if we have a SELECT component inside the popover we need to skip this handler
    if (event && (event.target as Node).nodeName === "BODY" && event.type === "click") {
      return;
    }
    handleClose();
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "simple-popper" : undefined;

  const buttonProps = { ...button.props, open, onClick: handleClick, id };
  const contentProps = { ...content.props, onClose: handleClose };

  return (
    <>
      <button.type {...buttonProps} />
      <StyledPopper
        id={id}
        transition
        modifiers={[
          { name: "flip", enabled: true },
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              rootBoundary: "viewport",
              padding: 8,
            },
          },
        ]}
        open={open}
        anchorEl={anchorEl}
        {...rest}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={onClickAway}>
            <Grow {...TransitionProps}>
              <Paper>
                <content.type {...contentProps} />
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </StyledPopper>
    </>
  );
};
