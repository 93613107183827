import { createAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";

export const STATE_KEY = "mediaFolders";

export const mediaFoldersAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.position - b.position,
});

export const mediaFoldersRecieved = createAction("media/foldersReceived");
export const mediaFoldersRemoved = createAction("media/foldersRemoved");

export const mediaFoldersSlice = createSlice({
  name: STATE_KEY,
  initialState: mediaFoldersAdapter.getInitialState({ count: {} }),
  reducers: {
    mediaFoldersRepositioned: {
      reducer: mediaFoldersAdapter.updateMany,
      prepare: (payload) => ({
        payload: payload.map(({ id, position }) => ({
          id,
          changes: { position },
        })),
      }),
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(mediaFoldersRecieved, (state, action) => {
        const { items, count } = action.payload;
        if (items) {
          mediaFoldersAdapter.setAll(state, items);
        }
        if (count) {
          state.count = count;
        }
      })
      .addCase(mediaFoldersRemoved, mediaFoldersAdapter.removeAll),
});

export const { mediaFoldersRepositioned } = mediaFoldersSlice.actions;
export const { selectAll: selectMediaFolders } = mediaFoldersAdapter.getSelectors((state) => state.mediaFolders);

export default mediaFoldersSlice.reducer;
