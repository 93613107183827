import { schema } from "normalizr";

const creditLabel = new schema.Entity("creditLabels");

const credit = new schema.Entity("credits", { labels: [creditLabel] });

const creditArea = new schema.Entity("creditAreas", { credits: [credit] });

const MasteryCreditArchitecture = new schema.Entity("MCAs", { areas: [creditArea] });

const user = new schema.Entity("users");

const message = new schema.Entity("messages", { user });

const conversation = new schema.Entity("conversations", {
  messages: [message],
  participants: [user],
});

const mediaFiles = new schema.Entity("mediaFiles");

const mediaFolders = new schema.Entity("mediaFolders");

const grows = new schema.Entity("grow");

export const Schemas = {
  CREDIT_LABEL: creditLabel,
  CREDIT: credit,
  CREDIT_AREA: creditArea,
  MCA: MasteryCreditArchitecture,
  CONVERSATION: conversation,
  MESSAGE: message,
  MEDIA_FILES: mediaFiles,
  MEDIA_FOLDERS: mediaFolders,
  GROWS: grows,
};
