import { useEffect, useState } from "react";

import { Field } from "../../styledComponents/StyledForms";

import { getHelperText, getLabel, handleSubmit, parseValue, handleFieldData } from "../actions";
import counterpart from "counterpart";
import { TODO_ANY } from "types";

export const InputField = ({ InputProps, inputProps = {}, color, focused, ...formProps }: TODO_ANY) => {
  const {
    id,
    type,
    label,
    name,
    value,
    error,
    hideLabel,
    marginDisabled,
    handleChanges,
    clearErrors,
    submit,
    helperText,
    placeholder,
    placeholderI18n,
    formName,
    pattern,
    autoFocus,
    disabled,
    required,
    inputRef,
    arrayId,
  } = handleFieldData(formProps);
  const [currentValue, setCurrentValue] = useState(value === null ? "" : value);

  // TODO Try to remove this synchronization, it looks overcomplicated (check that Autocomplete component still works as expected)
  useEffect(() => {
    if (!value) setCurrentValue("");
    // sync state in case form state has been updated manually (form.handleChanges)
    if (value && value !== currentValue) setCurrentValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const isNumberField = type === "number";

  const handleField = async () => {
    // TODO Should it be set to null or empty string is good enough? (depends on backend validation schemas)
    const parsedValue = parseValue(currentValue);
    return handleChanges({ target: { name, value: parsedValue, id, rowIndex: arrayId } });
  };

  inputProps.onKeyDown = (e: KeyboardEvent) => handleSubmit(e, handleField, submit);

  const fullFieldName = `${formName}.${name}`;

  return (
    <Field
      fullWidth
      id={id}
      type={type}
      name={name}
      value={currentValue}
      autoFocus={autoFocus}
      placeholder={placeholderI18n ? counterpart.translate(placeholderI18n) : placeholder}
      error={Boolean(error)}
      InputProps={InputProps}
      inputProps={isNumberField ? { inputProps: { min: 0, ...inputProps } } : inputProps}
      label={getLabel(name, label, hideLabel)}
      helperText={getHelperText(error, fullFieldName, helperText)}
      margin={marginDisabled ? "none" : "normal"}
      onBlur={inputProps.readOnly ? undefined : handleField}
      focused={focused}
      color={color}
      onKeyDown={(e) => {
        // avoid exponent sign in numeric inputs
        if (isNumberField && e.keyCode === 69) {
          e.preventDefault();
        }
      }}
      onChange={(e) => {
        if (pattern) {
          const regex = new RegExp(`^(${pattern})$`);

          if (!regex.test(String(e.target.value))) {
            return;
          }
        }

        setCurrentValue(e.target.value);
        clearErrors && clearErrors(e);
      }}
      disabled={disabled}
      required={required}
      inputRef={inputRef}
    />
  );
};
