import BaseService from "services/baseService";
import FetchService from "services/fetchService";

class MultiSchoolService extends BaseService {
  servicePath = "multi-school/users";

  setAdminSchoolAssignments = (schoolIds: number[], userId: number, ...rest: (string | number)[]) =>
    FetchService.put<true>({
      url: this.getUrl(userId, "schools", ...rest),
      data: { schoolIds },
    });

  selectSchoolForUser = (userId: number, schoolId: number) =>
    FetchService.put<true>({ url: this.getUrl(userId, "schools", schoolId) });
}

export default new MultiSchoolService();
