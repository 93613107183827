/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiErrorCode } from "services/types";

type AggregationError = {
  id?: string;
  [key: string]:
    | {
        tag: string;
        data: { [key: string]: any };
        path: string;
      }
    | string
    | undefined;
};

type AggregationErrorData = {
  field: string;
  errors?: AggregationError[];
};

type DefaultErrorData = Record<string, any>;

export type ValidationErrorData = { details: string; field: string };

type FormErrorData = DefaultErrorData | AggregationErrorData | ValidationErrorData;

export type ApiErrorData = {
  error: string;
  description?: string;
  details?: FormErrorData;
};

type FormErrorCode =
  | ApiErrorCode
  | "NETWORK_ERROR"
  | "LEARNER_PROFILE_REVISION_MUST_DIFFER"
  | "LOADING_FAIL"
  | "clever.tokenNotFound"
  | "CLEVER_SIGNIN_ERROR"
  | "LTI_SIGNIN_ERROR";

class FormError extends Error {
  response: {
    data: ApiErrorData;
  };
  code: FormErrorCode;

  constructor(code: FormErrorCode, description?: string, data?: FormErrorData) {
    super(code);
    this.name = "FormError";
    this.code = code;
    this.response = {
      data: {
        error: code,
        description,
        details: data,
      },
    };
  }
}

export default FormError;
