import { LearningScaleStep } from "services/learningScaleService";

export type ResponseSuccess = true;

export type Nullable<T> = T | null;

export enum LearnerCollegeStatus {
  INTERESTED = "INTERESTED",
  PLANNING_TO_APPLY = "PLANNING_TO_APPLY",
  APPLIED = "APPLIED",
}

export enum LearnerRevisionStatus {
  DRAFT = "DRAFT",
  SUBMITTED = "SUBMITTED",
}

export enum LearnerEvidenceStatus {
  REVISED = "REVISED",
  DRAFT = "DRAFT",
  SUBMITTED = "SUBMITTED",
  APPROVED = "APPROVED",
}

export enum LearnerProgressStatus {
  REQUESTED = "REQUESTED",
  EARNED = "EARNED",
}

export enum LearnerCreditStatus {
  REQUESTED = "REQUESTED",
  EARNED = "EARNED",
  IN_PROGRESS = "IN_PROGRESS",
  ARCHIVED = "ARCHIVED", // fake status, only for frontend actions reasons
}

export enum EnrollmentEndType {
  // graduate does not exist on backend
  GRADUATE = "GRADUATE",
  WITHDRAWAL = "WITHDRAWAL",
  TRANSFER = "TRANSFER",
}

export enum UserRole {
  ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN",
  ROLE_SUPPORT_ADMIN = "ROLE_SUPPORT_ADMIN",
  ROLE_TEAMMATE = "ROLE_TEAMMATE",
  ROLE_SCHOOL_ADMIN = "ROLE_SCHOOL_ADMIN",
  ROLE_LEARNER = "ROLE_LEARNER",
  ROLE_EDUCATOR = "ROLE_EDUCATOR",
}

export enum UserStatus {
  CREATED = "CREATED",
  ACTIVE = "ACTIVE",
  INVITED = "INVITED",
  ACCOUNT_ARCHIVED = "ACCOUNT_ARCHIVED",
  ACCOUNT_EXPIRED = "ACCOUNT_EXPIRED",
  INVITATION_EXPIRED = "INVITATION_EXPIRED",
  INVITATION_ARCHIVED = "INVITATION_ARCHIVED",
}

export enum UserDocumentStatusType {
  SUCCESS = "success",
  WARNING = "warning",
  NEVER_PUBLISHED = "never published",
  ERROR = "error",
}

export enum SchoolStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  ARCHIVED = "ARCHIVED",
}

export enum SchoolType {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
  OUT_OF_SCHOOL_TIME = "OUT_OF_SCHOOL_TIME",
}

export enum SchoolSendingType {
  TRANSCRIPT = "TRANSCRIPT",
  LEARNING_RECORD = "LEARNING_RECORD",
}

export enum CoursesDurationUnit {
  TERM = "TERM",
  SEMESTER = "SEMESTER",
  TRIMESTER = "TRIMESTER",
  QUARTER = "QUARTER",
  MONTH = "MONTH",
  WEEK = "WEEK",
}

export enum ExperienceDurationUnit {
  YEAR = "YEAR",
  SEMESTER = "SEMESTER",
  TRIMESTER = "TRIMESTER",
  QUARTER = "QUARTER",
  MONTH = "MONTH",
  WEEK = "WEEK",
}

export type OffsetBasedListResponse<T> = {
  items: T[];
  count: {
    allItems: number;
    allItemsWithoutFilters: number;
  };
};

export type CursorBasedListResponse<T> = {
  items: T[];
  continuationToken: string;
};

type Direction = "asc" | "desc";

export type Sort = `${string},${Direction}`;

export type PaginatedQueryParams = {
  page?: number;
  filter?: string | null;
  limit?: number;
  sort?: Sort | null;
};

export enum SchoolAcademicYearType {
  AUGUST_JULY = "AUGUST_JULY",
  JANUARY_DECEMBER = "JANUARY_DECEMBER",
}

export enum SchoolAccreditationTypes {
  NONE = "NONE",
  ACCREDITING_ORGANIZATION = "ACCREDITING_ORGANIZATION",
  AUTHORIZING_DISTRICT = "AUTHORIZING_DISTRICT",
  CHARTER_ORGANIZATION = "CHARTER_ORGANIZATION",
}

export type School = Address & {
  id: number;
  name: string;
  type: SchoolType;
  status: SchoolStatus;
  expirationDate: string;
  phone: string;
  ceebCode: string | null;
  photoUrl: string | null;
  website: string | null;
  transcriptAuthorizerName: string | null;
  transcriptAuthorizerRole: string | null;
  maxNumOfAchievableAdvancedCredits: number | null;
  maxNumOfAchievableFoundationalCredits: number | null;
  typicalNumOfAdvancedCredits: number | null;
  thresholdNumOfAdvancedCredits: number | null;
  academicYearType: SchoolAcademicYearType;
  sendingType: SchoolSendingType;
  contacts: SchoolContact[];
  sections: SchoolSection;
  features: SchoolFeature[];
  accreditationType: SchoolAccreditationTypes;
  accreditationAgency: string | null;
};

export enum SchoolFeature {
  // Only synchronize My Colleges data coming from learners associated with school accounts with this setting turned on
  INCLUDE_IN_MY_COLLEGES_SALESFORCE_SYNC = "INCLUDE_IN_MY_COLLEGES_SALESFORCE_SYNC",
  // Allow Learners to request Credits/Competencies and Courses/Experiences, or changes to Personal Details.
  LEARNER_SELF_AUDIT = "LEARNER_SELF_AUDIT",
  // Hide the ability to see the learner in context of the school
  TRANSCRIPT_2_0_HIDE_LEARNER_IN_SCHOOL_CONTEXT = "TRANSCRIPT_2_0_HIDE_LEARNER_IN_SCHOOL_CONTEXT",
  // Allow Learning Scale to be used
  LEARNING_SCALE = "LEARNING_SCALE",
}

export enum SchoolSectionType {
  UNIQUE_PROGRAM_OFFERINGS = "UNIQUE_PROGRAM_OFFERINGS",
  CURRICULUM = "CURRICULUM",
  EVIDENCE = "EVIDENCE",
  BY_THE_NUMBERS = "BY_THE_NUMBERS",
  EXTERNAL_SYSTEMS = "EXTERNAL_SYSTEMS",
  ABOUT_SCHOOL = "ABOUT_SCHOOL",
  PROGRESS_TRACKER = "PROGRESS_TRACKER",
}

type SchoolSection = {
  id: number;
  content: string | null;
  type: SchoolSectionType;
  subsections: SchoolProfileSubsection[];
};

export type SchoolProfileSubsection = {
  id: number;
  sectionId: number;
  header: string;
  content: string;
  position: number;
};

export type SchoolContact = {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export type User = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  title: Nullable<string>;
  role: UserRole;
  photoUrl: Nullable<string>;
  schoolId: Nullable<number>;
  schoolName: Nullable<string>;
  expirationDate: Nullable<string>;
  status: UserStatus;
  isDemoAccount: boolean;
  lastLoginDate: string;
};

export type PublicUserInfo = Pick<User, "id" | "firstName" | "lastName" | "photoUrl">;

export type CommunityFolder = {
  id: number;
  userId: number;
  groupId: Nullable<number>;
  parentFolderId: Nullable<number>;
  name: string;
  description: Nullable<string>;
  thumbnailUrl: Nullable<string>;
  position: number;
  createdAt: string;
  updatedAt: string;
  parentFolder: CommunityFolder | null;
  user: PublicUserInfo | null;
};

export type CommunityFile = {
  id: number;
  userId: number;
  folderId: Nullable<number>;
  groupId: Nullable<number>;
  name: string;
  description: string | null;
  thumbnailUrl: Nullable<string>;
  fileUrl: string;
  openInNewTab: boolean;
  position: number;
  createdAt: string;
  updatedAt: string;
  user: PublicUserInfo;
  folder: Nullable<CommunityFolder>;
  comments: Comment[];
  commentCount: number;
  growCreditAreas: Pick<GrowArea, "archivedAt" | "description" | "id" | "position" | "title">[];
  growCredits: Pick<GrowCredit, "archivedAt" | "description" | "id" | "position" | "title"> &
    { area: { title: string } }[];
  isFavorite: boolean;
  isGrowResource: boolean;
  isExternalSource: boolean;
};

export enum GrowEvidenceStatus {
  PRIVATE = "PRIVATE",
  SHARE_PENDING = "SHARE_PENDING",
  SHARED = "SHARED",
}

export type GrowEvidence = {
  id: number;
  schoolId: number;
  title: string;
  description: Nullable<string>;
  fileUrl: string;
  createdAt: string;
  openInNewTab: boolean;
  status: GrowEvidenceStatus;
  isExternalSource: boolean;
  user: PublicUserInfo;
  credits?: { archivedAt: Nullable<string>; description: string; id: number; position: number; title: string }[];
};

export type MCACreditArea = {
  id: number;
  title: string;
  description: string;
  position: number | null;
  typicalNumOfAdvancedCredits: number | null;
  thresholdNumOfAdvancedCredits: number | null;
  archivedAt: string | null;
  credits: MCACredit[];
};

export enum MCACreditType {
  ADVANCED = "ADVANCED",
  FOUNDATIONAL = "FOUNDATIONAL",
}

export enum MCACreditTypeShorthand {
  AC = "AC",
  FC = "FC",
}

export type CreditLabel = {
  id: number;
  name: string;
  color: string;
  icon: string | null;
};

export type LearnerCreditStep = { creditId: number; stepId: number };
export type MCACreditStep = LearningScaleStep & LearnerCreditStep;
export type MCACredit = {
  id: number;
  title: string;
  description: string;
  type: MCACreditType;
  archivedAt: string | null;
  position: number;
  labels: CreditLabel[];
  creditSteps: MCACreditStep[];
};

export type LearnerCredit = MCACredit & {
  status: LearnerCreditStatus;
  isFeatured: boolean;
  evidences: LearnerEvidence[];
  progress: {
    steps: {
      id: number;
      title: string;
      description: string;
      createdAt: string;
      position: number;
      status: string;
      evidence: LearnerEvidence | null;
    }[];
    id: number;
    stepId: number;
    stepTitle: string;
    stepPosition: number;
  } | null;
};

export type AddLearnerCreditsProps = {
  learnerId: number;
  credits: ({ id: number; status: LearnerCreditStatus } | LearnerCreditStep)[];
  learningScaledEnabled: boolean;
};

// They are the same for now, but they will be different when CommunityFile type is updated
export type GrowFile = Pick<
  CommunityFile,
  | "id"
  | "createdAt"
  | "description"
  | "fileUrl"
  | "name"
  | "openInNewTab"
  | "isExternalSource"
  | "user"
  | "isFavorite"
  | "commentCount"
>;

export type GrowArea = {
  id: number;
  title: string;
  description: string;
  position: number;
  archivedAt: Nullable<string>;
  credits: GrowCredit[];
  files: GrowFile[];
};

export enum GrowCreditType {
  ADVANCED = "ADVANCED",
  FOUNDATIONAL = "FOUNDATIONAL",
}

export type GrowCredit = {
  id: number;
  title: string;
  description: string;
  type: GrowCreditType;
  status: GrowCreditStatus;
  position: number;
  archivedAt: Nullable<string>;
  files: GrowFile[];
  evidences: GrowEvidence[];
  priorityPosition?: number;
};

export enum GrowCreditStatus {
  NOT_YET_STARTED = "NOT_YET_STARTED", // fake status, only for frontend actions reasons
  IN_PROGRESS = "IN_PROGRESS",
  EARNED = "EARNED",
}

export type SchoolGrowCredit = GrowCredit & {
  area: Pick<GrowArea, "title">;
  status: GrowCreditStatus;
};

export type Address = {
  street: string | null;
  street2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  country: string;
};

export type PositionsParams = {
  id: number;
  position: number;
}[];

export type Course = {
  id: number;
  title: string;
  type: string | null;
  subject: string;
  description: string;
  schoolId: number;
  schoolName: string | null;
  duration: number | null;
  durationUnit: CoursesDurationUnit | null;
  archivedAt: string | null;
  assignedToLearner?: boolean;
};

export type Experience = {
  id: number;
  title: string;
  description: string | null;
  schoolId: number;
  schoolName: string | null;
  duration: number | null;
  durationUnit: ExperienceDurationUnit | null;
  archivedAt: string | null;
};

export type LearnerCourse = Course & {
  completionDate: string | null;
  status: LearnerCoursesStatus;
};

export type LearnerEvidence = {
  id: number;
  parentId: number | null;
  childId: number | null;
  status: LearnerEvidenceStatus;
  title: string;
  description: string;
  fileUrl: Nullable<string>;
  projectDuration: string | null;
  projectRole: string | null;
  isFeatured: boolean;
  highlight1: string | null;
  highlight2: string | null;
  highlight3: string | null;
  highlight4: string | null;
  highlight5: string | null;
  position: number | null;
  thumbnailUrl: Nullable<string>;
};

export type LearnerProfileRevision = Pick<Learner, "street" | "street2" | "city" | "state" | "country" | "zip"> & {
  status: LearnerRevisionStatus;
};

export type Learner = {
  id: number;
  externalId: string | null;
  schoolId: string;
  email: string;
  status: UserStatus;
  firstName: string;
  preferredName: string | null;
  lastName: string;
  dateOfBirth: string | null;
  expirationDate: string | null;
  graduationDate: string | null;
  graduationYear: number | null;
  city: string | null;
  country: string | null;
  state: string | null;
  street: string | null;
  street2: string | null;
  zip: string | null;
  enrollmentEndDate: string | null;
  enrollmentEndType: EnrollmentEndType | null;
  enrollmentDate: string;
  overviewStatement: string | null;
  photoUrl: string | null;
  schoolName: string | null;
  isDemoAccount: boolean;
  isTranscriptPublished: boolean;
  isLearningRecordPublished: boolean;
  lastLoginDate: string | null;
};

export type LearnerInList = Learner & {
  collegeLastUpdate: string | null;
  numberOfColleges: number | null;
  creditAreas: (Omit<MCACreditArea, "credits"> & {
    credits: (Omit<MCACredit, "labels"> & { status: LearnerCreditStatus })[];
  })[];
  lastPublished: string | null;
  documentPublishStatus: UserDocumentStatusType;
};

export type LearnerDetails = Learner & {
  learnerRevision: {
    overviewStatement: string;
    status: LearnerRevisionStatus;
  } | null;
  learnerProfileRevision: LearnerProfileRevision | null;
  legacyTranscriptUrl: Nullable<string>;
};

export type LearnerEvidenceCredit = {
  id: number;
  title: string;
  status: LearnerCreditStatus;
  isFeatured: boolean;
  description: string;
  areaId: number;
  areaTitle: string;
  type: MCACreditType;
  progress: LearnerCredit["progress"];
  showInTranscript: boolean;
  demonstratedStep: LearningScaleStep | null;
};

export type LearnerCollege = {
  id: number;
  learnerId: number;
  name: string;
  city: string | null;
  state: string | null;
  street: string | null;
  zip: string | null;
  url: string;
  admissionsOfficeUrl: string | null;
  status: LearnerCollegeStatus;
  accepted: boolean;
  latitude: number;
  longitude: number;
  outreachStatus: CollegeOutreachStatus;
  createdAt: string;
};

export type LearnerParent = {
  fullName: string;
  email: string;
  number: string;
};

type SchoolProfileSection = {
  primaryContent: string;
  subsections: Omit<SchoolProfileSubsection, "sectionId">[];
};

export type TranscriptSchool = {
  id: number;
  name: string;
  hideLearnerInSchoolContext?: boolean;
  transcriptRegistrarName: string;
  transcriptRegistrarTitle: string;
  address: string;
  address2: null | string;
  city: string;
  state: string;
  country: string;
  zip: string;
  ceebCode: string | null;
  academicYearType: string;
  phone: null | string;
  url: string | null;
  profile: {
    contacts: {
      id: number;
      title: string;
      name: string;
      phone: string;
      email: string;
      position: number;
    }[];
  };
  byTheNumbers: { bullets: string[] };
  curriculum: SchoolProfileSection;
  evidence: SchoolProfileSection;
  uniqueCourseOfferings: SchoolProfileSection;
  interpretingCreditProfiles: SchoolProfileSection & {
    metrics: {
      maxNumOfAchievableAdvancedCredits: number | null;
      maxNumOfAchievableFoundationalCredits: number | null;
      typicalNumOfAdvancedCredits: number | null;
      thresholdNumOfAdvancedCredits: number | null;
    };
  };
  masteryCreditsAndCourses: SchoolProfileSection;
  fullCreditList: null;
  honorsAb: null;
  creditAreas: Pick<MCACreditArea, "id" | "title" | "description" | "position"> & {
    schoolId: number;
    credits: Pick<MCACredit, "id" | "title" | "description" | "type" | "position"> & { earned: [number, number][] };
  };
};

export enum TranscriptFormatVersion {
  VERSION_1_0 = "1.0",
  VERSION_2_0 = "2.0",
}

export type AccessPDF = {
  school: Pick<School, "name" | "street" | "street2" | "city" | "state" | "zip" | "country" | "phone" | "ceebCode">;
  student: Pick<
    Learner,
    | "firstName"
    | "lastName"
    | "preferredName"
    | "street"
    | "street2"
    | "city"
    | "state"
    | "zip"
    | "country"
    | "graduationYear"
    | "dateOfBirth"
  >;
};

export enum CommunityGroupMemberStatus {
  MEMBERSHIP_REQUESTED = "MEMBERSHIP_REQUESTED",
  ACTIVE = "ACTIVE",
  INVITED = "INVITED",
}

export enum CommunityGroupType {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
  HIDDEN = "HIDDEN",
}

export type LinkPreview = {
  title: string | null;
  description: string | null;
  url: string | null;
  image: {
    url: string;
    type: string;
    width: string | null;
    height: string | null;
  } | null;
};

export type EmbeddableResource = { url: string; embeddable: boolean };

export enum CommunityPostType {
  FEED = "FEED",
  FORUM = "FORUM",
}

export enum CommunityAttachmentType {
  FILE = "FILE",
  IMAGE = "IMAGE",
}

export type College = {
  id: number;
  unitId: number | null;
  salesforceId: string;
  name: string;
  street: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  url: string | null;
  admissionsOfficeUrl: string | null;
  longitude: number | null;
  latitude: number | null;
  outreachStatus: CollegeOutreachStatus;
  numOfCollegeApplicationsThisYear: number;
  numOfCollegeApplications: number;
};

export type EventCategory =
  | "Colleges list"
  | "Course list"
  | "Experience list"
  | "Credit"
  | "Competency"
  | "Evidence"
  | "Learner profile"
  | "Overview statement"
  | "Transcript"
  | "Learning record";

export type EventType =
  | "ADD_COURSE"
  | "REMOVE_COURSE"
  | "UPDATE_COURSE"
  | "UPDATE_REQUESTED_CREDIT_TO_IN_PROGRESS"
  | "UPDATE_REQUESTED_CREDIT_TO_EARNED"
  | "ADD_EARNED_CREDIT"
  | "ADD_IN_PROGRESS_CREDIT"
  | "DENY_REQUESTED_CREDIT"
  | "REVOKE_CREDIT"
  | "UPDATE_IN_PROGRESS_CREDIT_TO_EARNED"
  | "ADD_REQUESTED_CREDIT"
  | "ADD_REQUESTED_CREDIT_TO_EVIDENCE"
  | "APPROVE_EVIDENCE"
  | "DENY_EVIDENCE"
  | "WITHDRAW_EVIDENCE"
  | "SUBMIT_EVIDENCE"
  | "APPROVE_EVIDENCE_REVISION"
  | "DENY_EVIDENCE_REVISION"
  | "WITHDRAW_EVIDENCE_REVISION"
  | "SUBMIT_EVIDENCE_REVISION"
  | "REMOVE_EVIDENCE"
  | "REMOVE_EVIDENCE_REVISION"
  | "UPDATE_EVIDENCE_TO_APPROVED"
  | "UPDATE_EVIDENCE_TO_DRAFT"
  | "UPDATE_EVIDENCE_REVISION_TO_APPROVED"
  | "UPDATE_EVIDENCE_REVISION_TO_DRAFT"
  | "APPROVE_STATEMENT"
  | "APPROVE_STATEMENT_REVISION"
  | "DENY_STATEMENT"
  | "DENY_STATEMENT_REVISION"
  | "WITHDRAW_STATEMENT"
  | "WITHDRAW_STATEMENT_REVISION"
  | "SUBMIT_STATEMENT"
  | "SUBMIT_STATEMENT_REVISION"
  | "REMOVE_STATEMENT"
  | "REMOVE_STATEMENT_REVISION"
  | "UPDATE_PROFILE"
  | "UPDATE_EARNED_CREDIT_TO_IN_PROGRESS"
  | "REMOVE_REQUESTED_CREDIT"
  | "ADD_EVIDENCE_ATTACHMENT"
  | "CHOOSE_EVIDENCE_COMMENT"
  | "UNFEATURE_EVIDENCE"
  | "MODIFY_EVIDENCE_HIGHLIGHTS"
  | "MODIFY_EVIDENCE_IMAGES"
  | "REMOVE_EVIDENCE_ATTACHMENT"
  | "FEATURE_EVIDENCE"
  | "MODIFY_EVIDENCE_DETAILS"
  | "ADD_EVIDENCE_COMMENT"
  | "ADD_CREDIT_TO_EVIDENCE"
  | "REMOVE_CREDIT_FROM_EVIDENCE"
  | "ADD_MY_EVIDENCE"
  | "ADD_MY_EVIDENCE_REVISION"
  | "REMOVE_MY_EVIDENCE"
  | "REMOVE_MY_EVIDENCE_REVISION"
  | "ADD_STATEMENT"
  | "ADD_STATEMENT_REVISION"
  | "MODIFY_EVIDENCE_THUMBNAIL"
  | "ADD_MY_COLLEGE"
  | "ADD_LEARNER_COLLEGE"
  | "REMOVE_MY_COLLEGE"
  | "REMOVE_LEARNER_COLLEGE"
  | "UPDATE_COLLEGE_APPLICATION_STATUS"
  | "MARK_COLLEGE_APPLICATION_AS_ACCEPTED"
  | "MARK_COLLEGE_APPLICATION_AS_NOT_ACCEPTED"
  | "PUBLISH_TRANSCRIPT"
  | "REQUEST_TO_SHARE_GROW_EVIDENCE"
  | "DENY_REQUEST_TO_SHARE_GROW_EVIDENCE"
  | "APPROVE_REQUEST_TO_SHARE_GROW_EVIDENCE";

export type SystemActivityAction =
  | "DELETE_MCA"
  | "DELETE_CREDIT"
  | "ARCHIVE_CREDIT"
  | "UNARCHIVE_CREDIT"
  | "LOGOUT"
  | "LOGIN"
  | "LOGIN_ERROR"
  | "LOGIN_EMAIL_NOT_FOUND"
  | "LOGIN_GOOGLE_SIGN_IN_ERROR"
  | "LOGIN_GOOGLE_SIGN_IN_ATTEMPT"
  | "LOGIN_LTI_SIGN_IN_ERROR"
  | "LOGIN_LTI_SIGN_IN_ATTEMPT"
  | "LOGIN_AS"
  | "LOGOUT_AS"
  | "ARCHIVE_SCHOOL"
  | "DELETE_ACCOUNT"
  | "DELETE_CREDIT_AREA"
  | "ARCHIVE_CREDIT_AREA"
  | "UNARCHIVE_CREDIT_AREA"
  | "DELETE_SCHOOL"
  | "COPY_MCA"
  | "COLLEGE_SYNC_SUCCESS"
  | "COLLEGE_SYNC_WARNING"
  | "COLLEGE_SYNC_ERROR"
  | "EXPIRE_ACCOUNT"
  | "EXPIRE_INVITATION"
  | "SCHOOL_SYNC_SUCCESS"
  | "SCHOOL_SYNC_ERROR"
  | "SCHOOL_STATUS_SYNC_SUCCESS"
  | "SCHOOL_STATUS_SYNC_WARNING"
  | "SCHOOL_STATUS_SYNC_ERROR"
  | "SCHOOL_GROW_STATS_SYNC_SUCCESS"
  | "SCHOOL_GROW_STATS_SYNC_WARNING"
  | "SCHOOL_GROW_STATS_SYNC_ERROR"
  | "TRANSCRIPT_SYNC_SUCCESS"
  | "TRANSCRIPT_SYNC_ERROR"
  | "EMAIL_DIGEST_SUCCESS "
  | "EMAIL_DIGEST_ERROR"
  | "CHANGE_DOCUMENT_TYPE"
  | "ARCHIVE_GROW_CREDIT_AREA"
  | "UNARCHIVE_GROW_CREDIT_AREA"
  | "ARCHIVE_GROW_CREDIT"
  | "UNARCHIVE_GROW_CREDIT";

export type SystemActivityCategory = "SCHOOL" | "MCA" | "AUTH" | "USERS" | "SALESFORCE" | "EMAIL" | "GROW";

export type Event = {
  id: number;
  type: EventType;
  schoolId: number;
  learnerId?: number;
  courseId?: number;
  collegeId?: number;
  creditId?: number;
  evidenceId?: number;
  creditStatus?: MCACreditType;
  details?: object;
  createdAt: string;
  initiator: PublicUserInfo;
  learner: PublicUserInfo | null;
  course?: Pick<Course, "id" | "title" | "subject" | "type" | "description" | "duration" | "durationUnit">;
  college?: Pick<College, "id" | "name">;
  credit?: Pick<MCACredit, "id" | "title" | "type">;
  evidence?: LearnerEvidence & {
    child?: {
      id: number;
      title: string;
    };
  };
  typeItem: EventCategory;
  revisionStatus?: LearnerRevisionStatus;
  assignees: PublicUserInfo[];
};

export type ApiErrorCode =
  | "VALIDATION_ERROR"
  | "AGGREGATION_ERROR"
  | "INSUFFICIENT_PRIVILEGES"
  | "CHANGE_OWN_ROLE"
  | "CHANGE_OWN_STATUS"
  | "EMAIL_ALREADY_EXISTS"
  | "EMAIL_NOT_FOUND"
  | "EMAIL_SENDING_ERROR"
  | "SCHOOL_NOT_FOUND"
  | "SCHOOL_MEMBERS_NOT_FOUND"
  | "SCHOOL_FEATURE_NOT_FOUND"
  | "SCHOOL_FEATURE_ALREADY_EXISTS"
  | "SCHOOL_SENDING_TYPE_EQUAL_TO_CURRENT"
  | "USERS_NOT_FOUND"
  | "USERS_ALREADY_REGISTERED"
  | "USER_ALREADY_EXISTS"
  | "CREDIT_AREA_NOT_FOUND"
  | "CREDIT_AREAS_NOT_FOUND"
  | "CREDIT_AREA_DUPLICATION"
  | "MCA_MODEL_NOT_FOUND"
  | "MCA_MODEL_ALREADY_EXISTS"
  | "MCA_MODEL_ALREADY_COPIED"
  | "CREDIT_NOT_FOUND"
  | "CREDIT_NOT_FOUND_BY"
  | "CREDITS_NOT_FOUND"
  | "CREDITS_ALREADY_ASSIGNED_TO_LEARNER"
  | "CREDITS_NOT_ASSIGNED_TO_LEARNER"
  | "CREDIT_ASSIGNED_TO_LEARNERS"
  | "COMPETENCY_ASSIGNED_TO_LEARNER"
  | "CREDITS_ASSIGNED_TO_LEARNERS"
  | "COMPETENCIES_ASSIGNED_TO_LEARNERS"
  | "CREDIT_ARCHIVED_ASSIGNED_OR_REMOVED"
  | "CREDIT_ALREADY_HAS_ASSOCIATED_EVIDENCE"
  | "INVALID_CREDIT_TYPE"
  | "CREDIT_DUPLICATION"
  | "CREDITS_ALREADY_ASSIGNED_TO_SCHOOL"
  | "CREDITS_NOT_ASSIGNED_TO_SCHOOL"
  | "CREDITS_ALREADY_ASSIGNED_TO_EVIDENCE"
  | "CREDIT_ALREADY_ASSIGNED_TO_EVIDENCES"
  | "CREDITS_NOT_ASSIGNED_TO_EVIDENCE"
  | "CREDIT_ASSIGNED_TO_SCHOOLS"
  | "USER_NOT_FOUND"
  | "USER_NOT_FOUND_BY"
  | "INVITE_NOT_ACCEPTED"
  | "INVALID_INVITE_CODE"
  | "INVITE_CODE_EXPIRED"
  | "INVITE_ARCHIVED"
  | "INVITE_NOT_SENT"
  | "OLD_PASSWORD"
  | "INCORRECT_PASSWORD"
  | "SAME_PASSWORD"
  | "INVALID_RECOVERY_CODE"
  | "RECOVERY_CODE_EXPIRED"
  | "ACCOUNT_EXPIRED"
  | "ACCOUNT_SUSPENDED"
  | "ACCOUNT_ARCHIVED"
  | "COURSE_NOT_FOUND"
  | "COURSES_NOT_FOUND"
  | "COURSE_DUPLICATION"
  | "EXPERIENCE_DUPLICATION"
  | "COURSES_ALREADY_ASSIGNED_TO_LEARNER"
  | "COURSE_ASSIGNED_TO_LEARNERS"
  | "COURSES_NOT_ASSIGNED_TO_LEARNER"
  | "COURSES_MUST_BELONG_TO_SAME_SCHOOL_AS_LEARNER"
  | "FILE_IS_EMPTY"
  | "FILE_IS_NOT_READABLE"
  | "FILE_IS_NOT_READABLE_DESCRIPTION"
  | "INVALID_FILE_STORAGE"
  | "FILE_TOO_LARGE"
  | "FILE_WAS_NOT_UPLOADED"
  | "INVALID_USER_ROLE"
  | "INVALID_USER_SCHOOL"
  | "INVALID_USER_STATUS_DESCRIPTION"
  | "INVALID_USER_STATUS"
  | "INVALID_SCHOOL_STATUS"
  | "INVALID_SCHOOL_TYPE"
  | "LEARNER_NOT_FOUND"
  | "LEARNER_NOT_FOUND_BY"
  | "LEARNERS_NOT_FOUND"
  | "EDUCATOR_NOT_FOUND"
  | "INVALID_CAPTCHA"
  | "INVALID_ACCESS_CODE"
  | "ROLE_CHANGE_FORBIDDEN"
  | "UNEXPECTED_ROLE"
  | "EVIDENCE_NOT_FOUND"
  | "EVIDENCES_NOT_FOUND"
  | "EVIDENCE_AUTHORIZED_REVIEWER_NOT_FOUND"
  | "EVIDENCE_IMAGE_NOT_FOUND"
  | "EVIDENCE_ALREADY_ASSIGNED_TO_CREDIT"
  | "EVIDENCE_ALREADY_ASSIGNED_TO_COURSE"
  | "EVIDENCE_REACHED_MAXIMUM_COURSE_COUNT"
  | "EVIDENCE_NOT_ASSIGNED_TO_CREDIT"
  | "EVIDENCE_NOT_ASSIGNED_TO_COURSE"
  | "EVIDENCE_STATUS_CHANGE_FORBIDDEN_DESCRIPTION"
  | "EVIDENCE_COPY_FORBIDDEN"
  | "EVIDENCE_ALREADY_COPIED"
  | "EVIDENCE_IN_REVISION"
  | "EVIDENCE_NOT_COPY"
  | "EVIDENCE_COMMENT_NOT_FOUND"
  | "STATUS_CHANGE_FORBIDDEN_DESCRIPTION"
  | "STATUS_CHANGE_FORBIDDEN"
  | "SCHOOL_PROFILE_SUBSECTIONS_WAS_NOT_FOUND"
  | "SCHOOL_PROFILE_SUBSECTION_WAS_NOT_FOUND"
  | "ARCHIVE_INVITATIONS_FORBIDDEN"
  | "PASSWORD_RECOVERY_ERROR"
  | "LEARNER_REVISION_RECORD_NOT_FOUND"
  | "INVALID_LEARNER_REVISION_STATUS"
  | "JSON_PARSE_ERROR"
  | "TRANSCRIPT_NOT_FOUND"
  | "TRANSCRIPTS_NOT_FOUND"
  | "TRANSCRIPT_PUBLISH_DISABLED_FOR_SCHOOL"
  | "TRANSCRIPT_PUBLISH_ALREADY_ENABLED"
  | "LEARNING_RECORD_NOT_FOUND"
  | "LEARNING_RECORDS_NOT_FOUND"
  | "LEARNING_RECORD_PUBLISH_DISABLED_FOR_SCHOOL"
  | "LEARNING_RECORD_PUBLISH_ALREADY_ENABLED"
  | "MCA_SHARING_DISABLED_FOR_SCHOOL"
  | "MCA_LIST_DISABLED_FOR_SCHOOL"
  | "SCHOOL_MUST_BE_EXCLUDED_FROM_LIBRARY_WHILE_LIBRARY_IS_HIDDEN"
  | "SCHOOL_NOT_ACTIVE"
  | "COLLEGE_NOT_FOUND"
  | "COLLEGES_NOT_FOUND"
  | "COLLEGES_ALREADY_ASSIGNED_TO_LEARNER"
  | "COLLEGES_NOT_ASSIGNED_TO_LEARNER"
  | "SALESFORCE_LOGIN_ERROR"
  | "CLEVER_SIGNIN_ERROR"
  | "GOOGLE_SIGNIN_ERROR"
  | "LTI_SIGNIN_ERROR"
  | "LTI_SIGNIN_ROLE_FORBIDDEN"
  | "LTI_SIGNIN_SCHOOL_FORBIDDEN"
  | "USER_SCHOOL_NOT_FOUND"
  | "USER_SCHOOL_ALREADY_EXISTS"
  | "MULTI_SCHOOL_USER_ROLE_CHANGE_ERROR"
  | "COULD_NOT_DELETE_LAST_USER_SCHOOL"
  | "LABELS_PREDICTION_ERROR"
  | "LABEL_NOT_FOUND"
  | "LABELS_NOT_FOUND"
  | "LABELS_DUPLICATION"
  | "LABEL_ALREADY_ASSIGNED_TO_CREDIT"
  | "LABEL_NOT_ASSIGNED_TO_CREDIT"
  | "LEARNERS_ALREADY_ASSIGNED_TO_EDUCATOR"
  | "LEARNERS_NOT_ASSIGNED_TO_EDUCATOR"
  | "EDUCATOR_CAN_ADVISE_ALL_LEARNERS"
  | "EDUCATOR_CAN_EVALUATE_ALL_CREDITS"
  | "CREDIT_AREAS_NOT_ASSIGNED_TO_EDUCATOR"
  | "CREDIT_AREAS_ALREADY_ASSIGNED_TO_EDUCATOR"
  | "DURATION_UNIT_MUST_BE_SPECIFIED"
  | "LEARNER_SELF_AUDIT_DISABLED"
  | "COMMUNITY_FOLDER_NOT_FOUND"
  | "COMMUNITY_FOLDER_INVALID"
  | "COMMUNITY_FILE_NOT_FOUND"
  | "COMMUNITY_GROUP_NOT_FOUND"
  | "INVALID_COMMUNITY_GROUP"
  | "COMMUNITY_FOLDER_IS_NOT_EMPTY"
  | "COMMUNITY_MEMBERS_ALREADY_IN_GROUP"
  | "COMMUNITY_MEMBERS_NOT_FOUND_IN_GROUP"
  | "INVALID_COMMUNITY_MEMBER_STATUS"
  | "INVALID_COMMUNITY_GROUP_TYPE"
  | "COMMUNITY_POST_NOT_FOUND"
  | "COMMUNITY_COMMENT_NOT_FOUND"
  | "COMMUNITY_COMMENT_HAS_CHILDREN"
  | "WRONG_AUTHENTICATION_TOKEN"
  | "INVALID_TOKEN"
  | "COMMUNITY_ATTACHMENTS_NOT_FOUND"
  | "COMMUNITY_ATTACHMENTS_ALREADY_ASSIGNED"
  | "CONVERSATION_NOT_FOUND"
  | "MESSAGE_NOT_FOUND"
  | "COMMUNITY_LIKE_NOT_FOUND"
  | "COMMUNITY_LIKE_ALREADY_EXISTS"
  | "COMMUNITY_FILES_ALREADY_ASSIGNED"
  | "DIGEST_SUBSCRIBE_CODE_NOT_FOUND"
  | "GROW_CREDIT_AREA_NOT_FOUND"
  | "GROW_CREDIT_AREA_ALREADY_ASSOCIATED_WITH_FILE"
  | "GROW_CREDIT_AREA_NOT_ASSOCIATED_WITH_FILE"
  | "GROW_CREDIT_NOT_FOUND"
  | "GROW_CREDIT_ALREADY_ASSOCIATED_WITH_FILE"
  | "GROW_CREDIT_NOT_ASSOCIATED_WITH_FILE"
  | "ADULTS_ONLY_ALLOWED"
  | "SHOW_LEARNER_COLLEGES_DISABLED_FOR_GROUP"
  | "ERROR_COPYING_COMMUNITY_FILES"
  | "ERROR_COPYING_COMMUNITY_FOLDERS"
  | "FILE_ALREADY_IN_FAVORITES"
  | "FILE_NOT_IN_FAVORITES"
  | "COLLEGE_NUDGE_NOT_FOUND"
  | "NUDGE_REACTION_NOT_FOUND"
  | "NUDGE_REACTION_ALREADY_EXISTS"
  | "GROW_FILE_ALREADY_EXISTS"
  | "GROW_FILE_NOT_FOUND"
  | "EVENT_NOT_FOUND"
  | "USER_EVENT_ALREADY_EXISTS"
  | "USER_EVENTS_NOT_FOUND"
  | "MCA_MODEL_NOT_EMPTY"
  | "FILE_UPLOAD_ERROR"
  | "USER_EVENT_NOT_FOUND"
  | "LEARNER_PROFILE_REVISION_NOT_FOUND"
  | "USER_ASSOCIATED_WITH_MULTIPLE_ACCOUNT_MEMBERS";

export type Educator = User & {
  canAdviseAllLearners: boolean;
  canEvaluateAllCredits: boolean;
};

export enum LearnerCoursesStatus {
  REQUESTED = "REQUESTED",
  COMPLETED = "COMPLETED",
}

export const MTCAdminRoles = [UserRole.ROLE_SUPER_ADMIN, UserRole.ROLE_SUPPORT_ADMIN];
export const adminRoles = [...MTCAdminRoles, UserRole.ROLE_SCHOOL_ADMIN];
export const schoolStaff = [UserRole.ROLE_SCHOOL_ADMIN, UserRole.ROLE_EDUCATOR];
export const educatorStaff = [UserRole.ROLE_EDUCATOR, UserRole.ROLE_LEARNER];
export const MTCTeamRoles = [UserRole.ROLE_SUPER_ADMIN, UserRole.ROLE_SUPPORT_ADMIN, UserRole.ROLE_TEAMMATE];

export enum TranscriptVersion {
  DRAFT = "draft",
  PUBLISHED = "published",
}

export enum NudgeReaction {
  TO_COLLEGES = "TO_COLLEGES",
  UPDATED = "UPDATED",
}

export enum CollegeOutreachStatus {
  PENDING = "PENDING",
  INITIATED = "INITIATED",
  CONNECTED = "CONNECTED",
  UNKNOWN = "UNKNOWN",
}

export type PublishedDocumentStatus = "success" | "warning" | "error" | "never published";

export type ValidatedDocument = {
  id: number;
  firstName: string;
  lastName: string;
  preferredName: string | null;
  photoUrl: string | null;
  userStatus: UserStatus;
  status: PublishedDocumentStatus;
  message: Nullable<string>;
};

export enum PublishedReportStatus {
  success = "success",
  warning = "warning",
  error = "error",
}

export type ValidatedReport = Pick<Learner, "id" | "firstName" | "lastName" | "preferredName" | "photoUrl"> & {
  status: PublishedReportStatus;
  message: string;
  id: number;
  userStatus: Learner["status"];
};

export type PriorityGrowCredit = GrowCredit & Required<Pick<GrowCredit, "priorityPosition">>;
