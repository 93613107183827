import _ from "lodash";
import { createSlice, createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { MCARecieved, MCARemoved } from "./sharedMCAActions";
import { creditAreaAdded, creditAreaRemoved } from "./creditAreasSlice";

const MCAsAdapter = createEntityAdapter();

const initialState = MCAsAdapter.getInitialState();

export const STATE_KEY = "MCAs";

const MCAsSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    MCAUpdated: {
      reducer: MCAsAdapter.updateOne,
      prepare: ({ id, ...changes }) => ({
        payload: {
          id,
          changes: _.pick(changes, ["title", "description", "publisher", "isShared", "typesEnabled"]),
        },
      }),
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(MCARecieved, (state, action) => {
        const { MCAs } = action.payload.entities;
        MCAsAdapter.addMany(state, MCAs);
      })
      .addCase(MCARemoved, MCAsAdapter.removeAll)
      .addCase(creditAreaAdded, (state, action) => {
        const { id, mcaId } = action.payload;
        if (state.entities[mcaId]) {
          state.entities[mcaId].areas.push(id);
        }
      })
      .addCase(creditAreaRemoved, (state, action) => {
        _.forEach(state.entities, (MCA) => {
          const { areas } = MCA;
          const index = _.indexOf(areas, action.payload);
          if (index !== -1) {
            areas.splice(index, 1);
          }
        });
      }),
});

export const { MCAUpdated } = MCAsSlice.actions;

const { selectIds, selectEntities: selectMCAEntities } = MCAsAdapter.getSelectors((state) => state.MCAs);

export const selectMCAById = (id) =>
  createSelector(
    (state) => selectMCAEntities(state),
    (MCAs) => MCAs[id]
  );

export const selectMCAId = createSelector(selectIds, ([id]) => id);

export default MCAsSlice.reducer;
