import { styled } from "@mui/material/styles";

import { AppBar, Link, Paper as MuiPaper, TablePagination } from "@mui/material";
import { setSpacing } from "../../styledComponents/actions";

// Auth
export const StyledAuth = styled(MuiPaper)`
  padding: ${setSpacing(6)};
  width: 100%;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${setSpacing(10)};
  }
`;

export const drawerWidth = 66;
export const activityWidth = 74;

export const Root = styled("div")`
  display: flex;
  width: 100vw;
  min-height: 100vh;
  max-width: 100%;
`;

export const AppContent = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const MainContent = styled("div")`
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing(8, 10)};

  box-shadow: none;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 0;
  }

  > .pagination-wrapper {
    min-height: 100%;
  }
`;

export const SkipLink = styled(Link)`
  position: absolute;
  z-index: 10000;
  left: 180px;
  white-space: nowrap;
  top: -1000px;
  text-align: center;
  padding: 10px 15px;
  background: ${(props) => props.theme.palette.primary.dark};
  color: white;

  &:focus {
    top: 12px;
  }
`;

export const Main = styled("main")`
  max-width: 100vw;
  flex: 1 1 0;
  padding-left: ${drawerWidth}px;
  padding-top: 64px;
  margin-top: -64px;
  display: flex;
  flex-direction: column;
  ${"" /* overflow-x: hidden; */}
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding-left: 0;
    padding-right: 0;
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    padding-left: 0;
  }
`;

// Header
export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  paddingLeft: theme.spacing(15),
  background: theme.palette.common.white,
  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
  maxHeight: "64px",
}));

// Pagination
export const StyledPagination = styled(TablePagination)`
  ${(props) => props.theme.breakpoints.down("sm")} {
    .MuiTablePagination-toolbar {
      flex-wrap: wrap;
    }
  }
` as typeof TablePagination;
