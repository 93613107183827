import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

export const Field = styled(TextField)`
  input,
  textarea,
  .MuiSelect-select {
    font: 500 0.875rem ${({ theme }) => theme.typography.fontFamily};

    &::placeholder {
      color: ${({ theme }) => theme.palette.text.secondary};
      font-weight: 400;
      opacity: 1;
    }
  }

  .MuiInputLabel-root,
  fieldset {
    font-size: 0.875rem;
  }

  .MuiSelect-selectMenu {
    font-size: 0.875rem;
    font-weight: 500;
  }

  .MuiOutlinedInput-multiline {
    padding: 18.5px 14px;
  }

  .MuiInputBase-input,
  .MuiInputBase-formControl,
  .MuiInputBase-input:focus {
    background: ${({ theme }) => theme.palette.common.white};

    &.Mui-disabled {
      background: ${({ theme }) => theme.palette.action.disabledBackground};
    }
  }

  .Mui-disabled {
    input,
    textarea,
    .MuiInputBase-formControl,
    .MuiSelect-selectMenu {
      background: ${({ theme }) => theme.palette.action.disabledBackground};
    }
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    pointer-events: all;
  }
`;
