import React from "react";
import _ from "lodash";
import { Box, LinearProgress } from "@mui/material";
import { ErrorDisplay } from "./ErrorDisplay";
import { dispatchErrorPopup, dispatchPopup } from "redux/actions/popupActions";
import { ValidationError } from "yup";
import FormError from "services/formError";
import { mapApiError } from "components/form/actions";
import { FormErrors, FormValues, FormElements, FormProps, FormState } from "./types";

const mapApiErrorToYupError = (apiError: FormError, dataKeys: string[]) => {
  const errors: FormErrors = {};
  const errorData = apiError.response.data;

  const normalizedApiError = mapApiError(errorData);

  if (!_.has(dataKeys, normalizedApiError.fieldName)) {
    normalizedApiError.fieldName = "response";
  }

  const responseErrorData = normalizedApiError.data || errorData.details || {};

  if (Array.isArray(responseErrorData.errors)) {
    responseErrorData.errors.forEach((error) => {
      const fieldsErrors = _.omit(error, "id");
      _.forIn(fieldsErrors, (value) => {
        const path = value.path.replaceAll(".[", "[");
        errors[path] = _.pick(value, ["tag", "data"]);
      });
    });
  } else {
    _.set(errors, normalizedApiError.fieldName, {
      tag: normalizedApiError.tag,
      data: responseErrorData,
    });
  }
  return errors;
};

class Form<TValues extends FormValues = FormValues, TResponse = unknown> extends React.Component<
  FormProps<TValues, TResponse>,
  FormState<TValues>
> {
  private readonly formRef: React.RefObject<HTMLFormElement>;

  constructor(props: FormProps<TValues, TResponse>) {
    super(props);
    this.formRef = React.createRef();
  }

  state: FormState<TValues> = {
    data: _.cloneDeep(this.props.defaultData),
    errors: this.props.initialErrors || ({} as FormErrors),
    waitingResponse: false,
    fields: [],
  };

  componentDidMount() {
    this.setFormFields();

    if (this.props.noFocus) return;
    setTimeout(() => this.focusInput(), 0);
  }

  setFormFields = () => {
    const fields = this.formRef.current?.getElementsByClassName("MuiInputBase-input");
    if (fields) {
      this.setState({
        fields: [...(fields as HTMLCollectionOf<FormElements>)],
      });
    }
  };

  focusInput = (id = 0) => {
    // TODO old comment - need to check if it's not outdated
    // this recursion is needed for instances like login
    // in which we need to hide and disable first field
    // to please Firefox password manager

    const target = this.state.fields[id];
    if (!target) return;

    if (!target?.disabled) {
      target.focus();
      return;
    }

    this.focusInput(id + 1);
  };

  resetForm = (nextState?: Partial<FormState<TValues>>) => {
    this.setState({
      data: nextState?.data ?? this.props.defaultData,
      errors: nextState?.errors ?? ({} as FormErrors),
    });
  };

  // TODO use more meaningful name?
  clearErrors = (e: React.ChangeEvent<FormElements>) => {
    const errors = this.checkErrors(e.target.name);

    const { errors: prevErrors } = this.state;
    if (_.isEqual(errors, prevErrors)) return;

    this.setState({ errors });
  };

  checkErrors = (name: string, data?: TValues) => {
    const { errors: prevErrors } = this.state;
    const errors = { ...prevErrors };

    if (errors[name]) delete errors[name];

    if (!data) return errors;

    const { validationSchema } = this.props;

    if (validationSchema) {
      try {
        validationSchema.validateSyncAt(name, data);
      } catch (e) {
        if (e instanceof ValidationError) {
          _.set(errors, name, e.message);
        }
      }
    }

    return errors;
  };

  clearResponseErrors = () => {
    const { errors: prevErrors } = this.state;
    const errors = { ...prevErrors };
    if (errors.response) delete errors.response;
    this.setState({ errors });
  };

  handleChanges = async (e: { target: { name: string; value: unknown } }, shouldCheckErrors?: boolean) => {
    const { name, value } = e.target;
    const { data: prevData } = this.state;
    const data = { ...prevData };

    if (_.isEqual(data[name], value)) return;

    _.set(data, name, value);

    if (shouldCheckErrors) {
      const errors = this.checkErrors(name, data);
      this.setState({ data, errors });
    } else {
      this.setState({ data });
    }
  };

  handleArrayChanges = ({ name, value, errors: newErrors }: { name: string; value: unknown; errors: FormErrors }) => {
    const { data, errors } = this.state;

    if (value) _.set(data, name, value);

    this.setState({ data, errors: newErrors || errors });
  };

  focusOnError = () => {
    const { fields, errors } = this.state;

    const firstFieldWithError = fields.find((field) => errors[field.name]);
    if (firstFieldWithError) setTimeout(() => firstFieldWithError.focus(), 0);
  };

  onSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    e?.stopPropagation();

    const { data } = this.state;

    let newErrors = {};
    const { validationSchema } = this.props;
    if (validationSchema) {
      try {
        validationSchema.validateSync(data, { abortEarly: false });
      } catch (error) {
        if (error instanceof ValidationError) {
          newErrors = error.inner.reduce(
            (acc, { path, message }) => ({ ...acc, [path as keyof TValues]: message }),
            {}
          );
        } else if (error instanceof Error) {
          console.log(`An unexpected error occurred while validating: ${error.message}`);
        }
      }
    }

    if (!_.isEmpty(newErrors)) {
      this.setState({ errors: newErrors }, () => this.focusOnError());
      return;
    }
    this.emitRequest();
  };

  emitRequest = () => {
    // if form have some sort of request in props, we:
    // - stop all work in it (by disabling all fields);
    // - set preloader that will be working until request is over;
    // - handle all redundant request operations like try-catches and error processing.
    // After request is over we unlock form and look at requests result.
    // If result positive we pass true for displaying result message.
    // If not - we set errors and additional error data

    const { request, clearOnSuccess, onSuccessTag } = this.props;
    const { data } = this.state;

    let promiseOrUndefined: ReturnType<typeof request>;
    try {
      promiseOrUndefined = request(data);
      // Bail if it's sync, consumer can use it to do sync submit
      if (promiseOrUndefined === undefined) {
        return;
      }
    } catch (error) {
      throw error;
    }

    this.setState({ waitingResponse: true }, () => {
      Promise.resolve<TResponse>(promiseOrUndefined as Promise<TResponse>)
        .then((response) => {
          if (onSuccessTag)
            dispatchPopup("success", {
              tag: onSuccessTag,
              additionalData: { ...data, additionalData: response },
            });

          if (clearOnSuccess) {
            this.resetForm();
          }

          if (this.props.handleResult) {
            this.props.handleResult(data, response);
          }

          return response;
        })
        .catch((_responseError) => {
          if (_responseError instanceof FormError) {
            const errors = mapApiErrorToYupError(_responseError, _.keys(this.props.validationSchema));
            this.setState({ errors }, () => this.focusOnError());
          }
        })
        .catch(dispatchErrorPopup)
        .finally(() => {
          this.setState({ waitingResponse: false });
        });
    });
  };

  render() {
    const { waitingResponse, errors } = this.state;
    const { style, className, children } = this.props;
    const formClasses = className ? `form ${className}` : "form";

    return (
      <form className={formClasses} onSubmit={this.onSubmit} style={style} noValidate ref={this.formRef}>
        {children(this)}
        {waitingResponse && (
          <Box mt={4} ml={4} mr={4} my={2}>
            <LinearProgress />
          </Box>
        )}
        {errors.response && <ErrorDisplay error={errors.response} fontSize={14} />}
      </form>
    );
  }
}

export { Form };
