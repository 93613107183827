import _ from "lodash";
import BaseService from "services/baseService";
import FetchService from "services/fetchService";
import {
  Event,
  EventCategory,
  EventType,
  OffsetBasedListResponse,
  SystemActivityAction,
  SystemActivityCategory,
} from "services/types";

type SystemActivity = {
  userId: number;
  schoolId: number | null;
  userEmail: string;
  category: SystemActivityCategory;
  action: SystemActivityAction;
  details: string;
};

export type GetEventsArgs = {
  page?: number;
  limit?: number;
  typeItem?: EventCategory[];
  eventType?: EventType[];
  excludeOwnEvents?: boolean;
  initiatorId?: number;
  schoolId: number;
  learnerId?: number;
  courseId?: number;
  collegeId?: number;
  creditId?: number;
  evidenceId?: number;
  dateFrom?: string;
  dateTo?: string;
};

type GetSystemActivitiesArgs = {
  // default 1
  page?: number;
  // default 10
  limit?: number;
  sort?: string | null;
  filter?: string | null;
  dateTo?: string;
  dateFrom?: string;
  category?: SystemActivityCategory;
  action?: SystemActivityAction;
};

class EventsService extends BaseService {
  servicePath = "events";

  getEvents(params: GetEventsArgs) {
    return FetchService.get<OffsetBasedListResponse<Event>>({
      url: this.getUrl(),
      data: _.pick(params, [
        "page",
        "limit",
        "typeItem",
        "eventType",
        "excludeOwnEvents",
        "initiatorId",
        "schoolId",
        "learnerId",
        "courseId",
        "collegeId",
        "creditId",
        "evidenceId",
        "dateFrom",
        "dateTo",
      ]),
    });
  }

  getSystemActivities(params: GetSystemActivitiesArgs) {
    const { category, action } = params;
    return FetchService.get<OffsetBasedListResponse<SystemActivity>>({
      url: this.getUrl("sysactivities"),
      data: {
        ...params,
        category: category ? [category] : undefined,
        action: action ? [action] : undefined,
      },
    });
  }

  getFilters() {
    return FetchService.get<{ categories: SystemActivityCategory[]; actions: SystemActivityAction[] }>({
      url: this.getUrl("sysactivities/filters"),
    });
  }
}

export default new EventsService();
