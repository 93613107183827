import _ from "lodash";
import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import growService from "services/growService";
import { dispatchErrorPopup, dispatchPopup } from "redux/actions/popupActions";
import { creditAreasReceived, creditAreasRemoved } from "redux/slices/sharedMCAActions";
import { archiveGrowArea, restoreGrowArea } from "redux/slices/creditAreasSlice";
import { linkSchoolGrowCredits, unlinkSchoolGrowCredit } from "redux/slices/schoolGrowEvidenceSlice";
import growSchoolService from "services/growSchoolService";
import { GrowCredit, GrowCreditStatus, GrowEvidence } from "services/types";
import { RootState } from "redux/store";

type GrowCreditEntities = { [key: number]: GrowCredit };

const creditsAdapter = createEntityAdapter<GrowCredit>({
  sortComparer: (a, b) => a.position - b.position,
});

const creditFields = [
  "title",
  "description",
  "type",
  "position",
  "archivedAt",
  "status",
  "evidences",
  "priorityPosition",
];

export const STATE_KEY = "growCredits";

export const archiveGrowCredit = createAsyncThunk(
  `${STATE_KEY}/growCreditArchived`,
  async ({ growCreditId }: { growCreditId: number }) => {
    try {
      await growService.archiveGrowCredit({ growCreditId });
      dispatchPopup("success", {
        tag: "formResults.CREDIT_ARCHIVED",
      });
      return { growCreditId };
    } catch (error) {
      dispatchErrorPopup(error);
    }
  }
);

export const restoreGrowCredit = createAsyncThunk(
  `${STATE_KEY}/growCreditRestored`,
  async ({ growCreditId, growAreaId }: { growCreditId: number; growAreaId: number }) => {
    try {
      await growService.restoreGrowCredit({ growCreditId });
      dispatchPopup("success", {
        tag: "formResults.CREDIT_RESTORED",
      });
      return { growCreditId, growAreaId };
    } catch (error) {
      dispatchErrorPopup(error);
    }
  }
);

export const createPriorityCredit = createAsyncThunk(
  `${STATE_KEY}/createPriorityCredit`,
  async ({ schoolId, credit }: { schoolId: number; credit: GrowCredit }) => {
    try {
      const result = await growSchoolService.createPriorityGrowCredit({ schoolId, growCreditId: credit.id });
      dispatchPopup("success", {
        tag: "formResults.priorityCredits.PRIORITY_CREDIT_CREATED",
      });
      return { id: credit.id, priorityPosition: result.priorityPosition };
    } catch (err) {
      dispatchErrorPopup(err);
    }
  }
);

export const removePriorityCredit = createAsyncThunk(
  `${STATE_KEY}/removePriorityCredit`,
  async ({ schoolId, growCreditId }: { schoolId: number; growCreditId: number }) => {
    try {
      await growSchoolService.deletePriorityGrowCredit({ schoolId, growCreditId });
      dispatchPopup("success", {
        tag: "formResults.priorityCredits.PRIORITY_CREDIT_REMOVED",
      });
      return { id: growCreditId };
    } catch (err) {
      dispatchErrorPopup(err);
    }
  }
);

export const priorityGrowCreditsRepositioned = createAsyncThunk(
  `${STATE_KEY}/priorityGrowCreditsRepositioned`,
  async (
    {
      schoolId,
      newPositions,
      oldPositions,
    }: {
      schoolId: number;
      newPositions: { id: number; position: number }[];
      oldPositions: { id: number; position: number }[];
    },
    { dispatch }
  ) => {
    try {
      dispatch(priorityCreditsRepositioned({ positions: newPositions }));
      await growSchoolService.setPriorityGrowCreditPositions({
        schoolId,
        positions: newPositions.map((position) => ({ position: position.position, growCreditId: position.id })),
      });
    } catch (err) {
      dispatchErrorPopup(err);
      dispatch(priorityCreditsRepositioned({ positions: oldPositions }));
    }
  }
);

const growCreditsSlice = createSlice({
  name: STATE_KEY,
  initialState: creditsAdapter.getInitialState(),
  reducers: {
    creditStatusUpdated(state, action: PayloadAction<{ id: number; status: GrowCreditStatus }>) {
      const { id, status } = action.payload;
      (state.entities as GrowCreditEntities)[id].status = status;
      if (status === GrowCreditStatus.EARNED) (state.entities as GrowCreditEntities)[id].priorityPosition = undefined;
    },
    creditAdded(state, action: PayloadAction<GrowCredit>) {
      const { id, ...details } = action.payload;
      const pickedDetails = _.pick(details, creditFields);
      creditsAdapter.addOne(state, {
        ...(pickedDetails as Required<typeof pickedDetails>),
        id,
        files: [],
      });
    },
    creditRemoved: creditsAdapter.removeOne,
    creditsRemoved: creditsAdapter.removeMany,
    creditUpdated: {
      reducer: creditsAdapter.updateOne,
      prepare: ({ id, ...changes }) => ({
        payload: {
          id,
          changes: _.pick(changes, creditFields),
        },
      }),
    },
    creditsRepositioned: {
      reducer: creditsAdapter.updateMany,
      prepare: (payload) => ({
        payload: payload.map(({ id, position }: { id: number; position: number }) => ({
          id,
          changes: { position },
        })),
      }),
    },
    priorityCreditsRepositioned(state, action) {
      const { positions } = action.payload as { positions: { id: number; position: number }[] };
      positions.forEach(({ id, position }) => {
        (state.entities as GrowCreditEntities)[id].priorityPosition = position;
      });
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(archiveGrowCredit.fulfilled, (state, action) => {
        const { growCreditId } = action.payload as { growCreditId: number };
        (state.entities as GrowCreditEntities)[growCreditId].archivedAt = new Date().toUTCString();
      })
      .addCase(restoreGrowCredit.fulfilled, (state, action) => {
        const { growCreditId } = action.payload as { growCreditId: number };
        (state.entities as GrowCreditEntities)[growCreditId].archivedAt = null;
      })
      .addCase(creditAreasReceived, (state, action) => {
        const { credits } = action.payload.entities;
        if (credits) {
          creditsAdapter.setAll(state, credits);
        }
      })
      .addCase(creditAreasRemoved, creditsAdapter.removeAll)
      .addCase(archiveGrowArea.fulfilled, (state, action) => {
        const { growCreditIds } = action.payload as { growCreditIds: number[] };
        growCreditIds.forEach((id) => {
          (state.entities as GrowCreditEntities)[id].archivedAt = new Date().toISOString();
        });
      })
      .addCase(restoreGrowArea.fulfilled, (state, action) => {
        const { growCreditIds } = action.payload as { growCreditIds: number[] };
        growCreditIds.forEach((id) => {
          (state.entities as GrowCreditEntities)[id].archivedAt = null;
        });
      })
      .addCase(unlinkSchoolGrowCredit.fulfilled, (state, action) => {
        const { evidenceId, creditId } = action.meta.arg as unknown as { evidenceId: number; creditId: number };
        _.remove((state.entities as GrowCreditEntities)[creditId].evidences, { id: evidenceId });
      })
      .addCase(linkSchoolGrowCredits.fulfilled, (state, action) => {
        const { evidenceId, title, credits } = action.meta.arg as unknown as {
          evidenceId: number;
          title: string;
          credits: GrowCredit[];
        };
        const creditIds = _.map(credits, "id");

        creditIds.forEach((creditId) => {
          const entities = state.entities as GrowCreditEntities;
          entities[creditId].evidences = _.unionBy(
            entities[creditId].evidences,
            [
              {
                id: evidenceId,
                title,
              } as GrowEvidence,
            ],
            "id"
          );
        });
      })
      .addCase(createPriorityCredit.fulfilled, (state, action) => {
        const { id, priorityPosition } = action.payload as { id: number; priorityPosition: number };
        (state.entities as GrowCreditEntities)[id].priorityPosition = priorityPosition;
      })
      .addCase(removePriorityCredit.fulfilled, (state, action) => {
        const { id } = action.payload as { id: number };
        (state.entities as GrowCreditEntities)[id].priorityPosition = undefined;
      }),
});

export const {
  creditAdded,
  creditUpdated,
  creditStatusUpdated,
  creditsRepositioned,
  priorityCreditsRepositioned,
  creditRemoved,
  creditsRemoved,
} = growCreditsSlice.actions;

export const {
  selectEntities: selectCreditEntities,
  selectById,
  selectAll: selectAllCredits,
} = creditsAdapter.getSelectors((state: RootState) => state.growCredits);

export const selectCreditById = (id: number) =>
  createSelector(
    (state: unknown) => selectById(state, id),
    (credit) => credit
  );

export const selectAreaCredits = (creditIds: number[], showArchived: boolean) => {
  return createSelector([selectCreditEntities], (creditEntities) => {
    return _(creditIds)
      .map((id) => creditEntities[id] as GrowCredit)
      .filter(({ archivedAt }) => showArchived || !archivedAt)
      .sortBy("position")
      .value();
  });
};

export const selectPriorityCredits = () =>
  createSelector([selectAllCredits], (allCredits) => {
    const credits = Object.values(allCredits);
    return _(credits)
      .filter(({ priorityPosition }) => priorityPosition !== undefined)
      .map((credit) => ({ ...credit, position: credit.priorityPosition }))
      .sortBy("priorityPosition")
      .value();
  });

export default growCreditsSlice.reducer;
