import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { normalize } from "normalizr";

import conversationsService from "services/conversationsService";
import { dispatchErrorPopup } from "redux/actions/popupActions";

export const STATE_KEY = "conversations";

const normalizeConversationsList = ({ items, count }, schema) => {
  const normalizedResponse = items.map((conversation) => {
    conversation.loadingMoreMessages = false;
    conversation.messages = [];
    conversation.page = 1;
    conversation.messageLimit = 20;

    if (conversation.latestMessage) {
      conversation.messages.push(conversation.latestMessage);
    }
    delete conversation.latestMessage;

    return conversation;
  });

  return { ...normalize(normalizedResponse, schema), count };
};

export const fetchConversations = createAsyncThunk(
  `${STATE_KEY}/fetch`,
  async ({ userId }, { extra: { Schemas }, getState }) => {
    const { limit } = getState()[STATE_KEY];
    const response = await conversationsService.getConversations({ userId, page: 1, limit });

    return normalizeConversationsList(response, [Schemas.CONVERSATION]);
  }
);

export const fetchMoreConversations = createAsyncThunk(
  `${STATE_KEY}/fetchMore`,
  async ({ userId }, { extra: { Schemas }, getState }) => {
    const { page, limit } = getState()[STATE_KEY];
    const response = await conversationsService.getConversations({ userId, page: page + 1, limit });

    return normalizeConversationsList(response, [Schemas.CONVERSATION]);
  }
);

export const fetchConversationById = createAsyncThunk(
  `${STATE_KEY}/fetchById`,
  async ({ conversationId }, { extra: { Schemas }, getState }) => {
    const { entities } = getState()[STATE_KEY];
    const { messageLimit } = entities[conversationId] || {};
    const response = await conversationsService.getConversation({ conversationId, page: 1, limit: messageLimit });
    return { ...normalize(response.items, Schemas.CONVERSATION), count: response.count };
  }
);

export const fetchMoreMessages = createAsyncThunk(
  `${STATE_KEY}/fetchMoreMessages`,
  async ({ conversationId }, { extra: { Schemas }, getState, rejectWithValue }) => {
    try {
      const { entities } = getState()[STATE_KEY];
      const { page, messageLimit } = entities[conversationId] || {};
      const response = await conversationsService.getConversation({
        conversationId,
        page: page + 1,
        limit: messageLimit,
      });
      return { ...normalize(response.items, Schemas.CONVERSATION), count: response.count };
    } catch (error) {
      dispatchErrorPopup(error);
      return rejectWithValue(error);
    }
  }
);

export const createConversation = createAsyncThunk(
  `${STATE_KEY}/createConversation`,
  async ({ subject, participants }, { extra: { Schemas }, rejectWithValue }) => {
    try {
      let normalizedSubject = subject;
      if (!normalizedSubject) {
        normalizedSubject = participants
          .map((user) => `${user.firstName}`)
          .join(", ")
          .slice(0, 255);
      }

      const response = await conversationsService.createConversation({
        subject: normalizedSubject,
        userIds: _.map(participants, "id"),
      });

      return normalize(response, Schemas.CONVERSATION);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
