import Translate from "react-translate-component";

export const getHelperText = (error, name, helperText, helperTextData) => {
  if (error) {
    return <Translate content={`errorsList.${error.tag}`} with={error.data} />;
  }

  if (helperText) {
    const helperLabel = name.replace(/\..*\[\d+\]/, "");
    return <Translate content={`helperText.${helperLabel}`} with={helperTextData} />;
  }

  return null;
};
