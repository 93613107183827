import * as Sentry from "@sentry/react";

const { REACT_APP_SENTRY_DNS } = process.env;

const options = {
  dsn: REACT_APP_SENTRY_DNS,
  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    "Non-Error exception captured",
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
  integrations:
    process.env.NODE_ENV === "development"
      ? [
          new Sentry.Replay({
            blockAllMedia: false,
            maskFn: (text) => (text ? text[0] + "*".repeat(text.length - 1) : text),
          }),
        ]
      : undefined,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
};

function initSentry() {
  Sentry.init(options);
}

export { initSentry };
