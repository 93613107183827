import { useEffect, useState } from "react";

/**
 * React sensor hook that tracks dimensions of the browser window.
 *
 * @example
 * import {useWindowSize} from 'react-use';
 *
 * const Demo = () => {
 *  const {width, height} = useWindowSize();
 *
 *  return (
 *    <div>
 *      <div>width: {width}</div>
 *      <div>height: {height}</div>
 *    </div>
 *  );
 *};
 */
export const useWindowSize = () => {
  const [state, setState] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handler = () => {
      setState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handler);

    return () => {
      window.removeEventListener("resize", handler);
    };
  }, []);

  return state;
};
