import { useCallback, useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import { useTypedSelector } from "hooks";

const { REACT_APP_GA_ID } = process.env;
if (REACT_APP_GA_ID) {
  ReactGA.initialize(REACT_APP_GA_ID, { debug: false });
}

export const useGATracking = () => {
  const userId = useTypedSelector((state) => state.account.id);
  const { search, pathname } = useLocation();

  const trackPage = useCallback(
    (page: string) => {
      ReactGA.set({
        page,
        userId,
      });
      ReactGA.pageview(page);
    },
    [userId]
  );

  useEffect(() => {
    if (REACT_APP_GA_ID) {
      const page = pathname + search;
      trackPage(page);
    }
  }, [pathname, search, trackPage]);
};
