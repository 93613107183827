import _ from "lodash";
import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { MCARecieved, MCARemoved } from "./sharedMCAActions";
import { creditLabelRemoved } from "./creditLabelsSlice";
import { dispatchErrorPopup, dispatchPopup } from "redux/actions/popupActions";
import learningScaleService from "services/learningScaleService";
import {
  addStepToLearningScale,
  archiveLearningScaleStep,
  learningScaleStepsRepositioned,
  unarchiveLearningScaleStep,
  updateLearningScaleStep,
} from "redux/slices/learningScalesSlice";

const creditsAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.position - b.position,
});

const initialState = creditsAdapter.getInitialState({ error: null });

const creditFields = ["title", "description", "type", "position", "archivedAt", "status", "evidences"];

export const STATE_KEY = "credits";

export const editCreditStepDescription = createAsyncThunk(
  `${STATE_KEY}/editCreditStepDescription`,
  async ({ stepId, description, creditId }) => {
    try {
      await learningScaleService.updateCreditStepDescription({ stepId, creditId, description });
      dispatchPopup("success", { tag: "formResults.CREDIT_LEVEL_DESCRIPTION_UPDATED" });
      return { stepId, creditId, description };
    } catch (error) {
      dispatchErrorPopup(error);
    }
  }
);

export const editCreditStepDescriptions = createAsyncThunk(
  `${STATE_KEY}/editCreditStepDescriptions`,
  async ({ descriptions, learningScaleId }) => {
    try {
      await learningScaleService.updateCreditStepDescriptions({ descriptions, learningScaleId });
      dispatchPopup("success", { tag: "formResults.CREDIT_LEVEL_DESCRIPTIONS_UPDATED" });
      return { descriptions };
    } catch (error) {
      dispatchErrorPopup(error);
    }
  }
);

export const addStepsToCredit = createAsyncThunk(
  `${STATE_KEY}/addStepsToCredit`,
  async ({ learningScale, creditId }) => {
    try {
      const creditSteps = await learningScaleService.addStepsToCredit({ learningScaleId: learningScale.id, creditId });

      dispatchPopup("success", { tag: "formResults.LEVELS_ADDED_TO_CREDIT" });

      return { creditSteps, creditId };
    } catch (error) {
      dispatchErrorPopup(error);
    }
  }
);

const creditsSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    creditAdded(state, action) {
      const { id, ...details } = action.payload;
      creditsAdapter.addOne(state, {
        id,
        labels: [],
        files: [],
        creditSteps: [],
        ..._.pick(details, creditFields),
      });
    },
    creditRemoved: creditsAdapter.removeOne,
    creditsRemoved: creditsAdapter.removeMany,
    creditsUpdated: {
      reducer: creditsAdapter.updateMany,
      prepare: (payload) => ({
        payload: payload.map(({ id, ...changes }) => ({
          id,
          changes: _.pick(changes, creditFields),
        })),
      }),
    },
    creditUpdated: {
      reducer: creditsAdapter.updateOne,
      prepare: ({ id, ...changes }) => ({
        payload: {
          id,
          changes: _.pick(changes, creditFields),
        },
      }),
    },
    creditsRepositioned: {
      reducer: creditsAdapter.updateMany,
      prepare: (payload) => ({
        payload: payload.map(({ id, position }) => ({
          id,
          changes: { position },
        })),
      }),
    },
    creditLabelsAssigned(state, action) {
      const { labelIds, creditId } = action.payload;
      state.entities[creditId].labels = labelIds;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(creditLabelRemoved, (state, action) => {
        _.forEach(state.entities, ({ labels }) => {
          _.remove(labels, (labelId) => labelId === action.payload);
        });
      })
      .addCase(MCARecieved, (state, action) => {
        const { credits } = action.payload.entities;
        if (credits) {
          creditsAdapter.setAll(state, credits);
        }
      })
      .addCase(MCARemoved, creditsAdapter.removeAll)
      .addCase(editCreditStepDescription.fulfilled, (state, action) => {
        const { stepId, creditId, description } = action.payload;
        const stepIndex = state.entities[creditId].creditSteps.findIndex((step) => step.stepId === stepId);
        if (stepIndex !== -1) state.entities[creditId].creditSteps[stepIndex].description = description;
      })
      .addCase(editCreditStepDescriptions.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }

        const { descriptions } = action.payload;
        descriptions.forEach(({ stepId, creditId, description }) => {
          const stepIndex = state.entities[creditId].creditSteps.findIndex((step) => step.stepId === stepId);
          if (stepIndex !== -1) state.entities[creditId].creditSteps[stepIndex].description = description;
        });
      })
      .addCase(addStepsToCredit.fulfilled, (state, action) => {
        const { creditSteps, creditId } = action.payload;
        state.entities[creditId].creditSteps = creditSteps;
      })
      .addCase(addStepToLearningScale, (state, action) => {
        const { step } = action.payload;
        const credits = Object.values(state.entities);

        credits.forEach((credit) => {
          if (credit.creditSteps.length) {
            const creditStep = { ...step, creditId: credit.id, stepId: step.id, description: null };
            credit.creditSteps.push(creditStep);
          }
        });
      })
      .addCase(updateLearningScaleStep.fulfilled, (state, action) => {
        const { stepId, title } = action.payload;
        const credits = Object.values(state.entities);

        credits.forEach((credit) => {
          if (credit.creditSteps.length) {
            const stepIndex = credit.creditSteps.findIndex((step) => step.stepId === stepId);
            if (stepIndex !== -1) credit.creditSteps[stepIndex].title = title;
          }
        });
      })
      .addCase(learningScaleStepsRepositioned, (state, action) => {
        const { positions } = action.payload;

        const objectPositions = {};
        positions.forEach(({ id, position }) => {
          objectPositions[id] = position;
        });

        const credits = Object.values(state.entities);

        credits.forEach((credit) => {
          if (credit.creditSteps.length) {
            credit.creditSteps.forEach((step) => {
              if (objectPositions[step.stepId] !== undefined) {
                step.position = objectPositions[step.stepId];
              }
            });
          }
        });
      })
      .addMatcher(
        isAnyOf(unarchiveLearningScaleStep.fulfilled, archiveLearningScaleStep.fulfilled),
        (state, action) => {
          const { archivedAt, stepId } = action.payload;
          const credits = Object.values(state.entities);

          credits.forEach((credit) => {
            if (credit.creditSteps.length) {
              credit.creditSteps.forEach((step) => {
                if (step.stepId === stepId) step.archivedAt = archivedAt;
              });
            }
          });
        }
      ),
});

export const {
  creditRemoved,
  creditUpdated,
  creditsRepositioned,
  creditAdded,
  creditLabelsAssigned,
  creditsUpdated,
  creditsRemoved,
} = creditsSlice.actions;

export const {
  selectEntities: selectCreditEntities,
  selectById,
  selectAll: selectAllCredits,
} = creditsAdapter.getSelectors((state) => state.credits);

export const selectCreditById = (id) =>
  createSelector(
    (state) => selectById(state, id),
    (credit) => credit
  );

export const selectAreaCredits = (creditIds, showArchived) =>
  createSelector([selectCreditEntities], (creditEntities) => {
    return _(creditIds)
      .map((id) => creditEntities[id])
      .filter(({ archivedAt }) => showArchived || !archivedAt)
      .sortBy("position")
      .value();
  });

export default creditsSlice.reducer;
