import _ from "lodash";
import {
  SET_EVIDENCES,
  REMOVE_EVIDENCES,
  UPDATE_EVIDENCE,
  UPDATE_EVIDENCE_IMAGE,
  DELETE_EVIDENCE_IMAGE,
  ADD_EVIDENCE_IMAGE,
  SET_EVIDENCE_CREDIT,
  SET_EVIDENCE_CREDITS,
  DELETE_EVIDENCE_CREDIT,
  UPDATE_EVIDENCE_CREDIT,
  ADD_EVIDENCE_COMMENT,
  UPDATE_EVIDENCE_COMMENT,
  DELETE_EVIDENCE_COMMENT,
  INCLUDE_IN_TRANSCRIPT_EVIDENCE_COMMENT,
  DELETE_EVIDENCE_COURSE_ASSIGNMENT,
  ASSIGN_COURSE_TO_EVIDENCE,
  UPDATE_EVIDENCE_COURSE_DETAILS,
} from "../constants";

const initialEvidences = [];

export default (state = initialEvidences, { type, payload }) => {
  switch (type) {
    case SET_EVIDENCES:
      return [...payload.evidence];

    case REMOVE_EVIDENCES:
      return initialEvidences;

    case UPDATE_EVIDENCE:
      return state.map((evidence) => (evidence.id === payload.id ? { ...evidence, ...payload.details } : evidence));

    case ADD_EVIDENCE_IMAGE:
      return state.map((evidence) => {
        if (evidence.id === payload.id) {
          return { ...evidence, images: evidence.images.concat(payload.image) };
        }
        return evidence;
      });

    case UPDATE_EVIDENCE_IMAGE:
      return state.map((evidence) => {
        if (evidence.id === payload.id) {
          return {
            ...evidence,
            images: evidence.images.map((image) =>
              image.id === payload.imageId ? { ...image, title: payload.title } : image
            ),
          };
        }
        return evidence;
      });

    case DELETE_EVIDENCE_IMAGE:
      return state.map((evidence) => {
        if (evidence.id === payload.id) {
          return {
            ...evidence,
            images: evidence.images.filter((image) => image.id !== payload.imageId),
          };
        }
        return evidence;
      });

    case SET_EVIDENCE_CREDIT:
      return state.map((evidence) => {
        if (evidence.id === payload.id) {
          const updatedCredits = evidence.credits.map((credit) => {
            if (credit.id === payload.credit.id) {
              return payload.credit;
            }
            return credit;
          });
          return { ...evidence, credits: updatedCredits };
        }
        return evidence;
      });

    case SET_EVIDENCE_CREDITS:
      return state.map((evidence) => {
        if (evidence.id === payload.id) {
          return { ...evidence, credits: [...evidence.credits, ...payload.credits] };
        }
        return evidence;
      });

    case DELETE_EVIDENCE_CREDIT:
      return state.map((evidence) => {
        if (evidence.id === payload.id) {
          return {
            ...evidence,
            isFeatured: payload.creditHasPriority ? false : evidence.isFeatured,
            credits: evidence.credits.filter((credit) => credit.id !== payload.creditId),
          };
        }
        return evidence;
      });

    case UPDATE_EVIDENCE_CREDIT:
      return state.map((evidence) => {
        if (evidence.id === payload.id) {
          return {
            ...evidence,
            credits: evidence.credits.map((credit) => {
              if (credit.id === payload.creditId) {
                return { ...credit, showInTranscript: payload.changes.showInTranscript };
              } else return { ...credit, showInTranscript: false };
            }),
          };
        }
        return evidence;
      });

    case ADD_EVIDENCE_COMMENT:
      return state.map((evidence) => {
        if (evidence.id === payload.id) {
          return { ...evidence, comments: [payload.comment].concat(evidence.comments) };
        }
        return evidence;
      });

    case UPDATE_EVIDENCE_COMMENT:
      return state.map((evidence) => {
        if (evidence.id === payload.id) {
          return {
            ...evidence,
            comments: evidence.comments.map((comment) => {
              if (comment.id === payload.commentId) {
                return { ...comment, ...payload.comment };
              }

              return comment;
            }),
          };
        }
        return evidence;
      });

    case INCLUDE_IN_TRANSCRIPT_EVIDENCE_COMMENT:
      return state.map((evidence) => {
        if (evidence.id === payload.id) {
          return {
            ...evidence,
            comments: evidence.comments.map((comment) => {
              if (comment.id !== payload.commentId && comment.inTranscript) {
                return { ...comment, inTranscript: false };
              }

              if (comment.id === payload.commentId) {
                return { ...comment, inTranscript: true };
              }

              return comment;
            }),
          };
        }
        return evidence;
      });

    case DELETE_EVIDENCE_COMMENT:
      return state.map((evidence) => {
        if (evidence.id === payload.id) {
          return {
            ...evidence,
            comments: evidence.comments.filter((comment) => comment.id !== payload.commentId),
          };
        }
        return evidence;
      });

    case ASSIGN_COURSE_TO_EVIDENCE:
      return state.map((evidence) => {
        if (evidence.id === payload.id) {
          return {
            ...evidence,
            courses: _.orderBy([...evidence.courses, payload.course], "id", "desc"),
          };
        }
        return evidence;
      });

    case UPDATE_EVIDENCE_COURSE_DETAILS:
      return state.map((evidence) => {
        if (evidence.id === payload.id) {
          return {
            ...evidence,
            courses: evidence.courses.map((course) =>
              course.id === payload.courseId ? { ...course, ...payload.changes } : course
            ),
          };
        }
        return evidence;
      });

    case DELETE_EVIDENCE_COURSE_ASSIGNMENT:
      return state.map((evidence) => {
        if (evidence.id === payload.id) {
          return {
            ...evidence,
            courses: evidence.courses.filter((course) => course.id !== payload.courseId),
          };
        }
        return evidence;
      });

    default:
      return state;
  }
};
