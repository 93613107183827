import { styled } from "@mui/material/styles";
import { Card as MuiCard, CardActions, CardMedia as MuiCardMedia, IconButton } from "@mui/material";
import { setSpacing, setTransition } from "./actions";
import archivedCreditArea from "svg/archivedCreditArea.svg";

export const DefaultCard = styled(MuiCard)`
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);

  > .MuiCardActionArea-root {
    height: 100%;
  }

  > .MuiCardContent-root:last-child {
    padding: ${setSpacing(4)};
  }
`;

export const DefaultActiveCard = styled(DefaultCard, { shouldForwardProp: (propName) => propName !== "stretched" })<{
  stretched?: boolean;
}>`
  height: ${(props) => (props.stretched ? "100%" : "auto")};

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
  &.isArchived {
    background-image: url(${archivedCreditArea});
    background-repeat: repeat-y;
  }
`;

export const StretchedCard = styled(DefaultCard)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CardLayout = styled("div")`
  position: relative;
  padding: ${setSpacing(3)} ${setSpacing(4)};
  box-sizing: border-box;
`;

export const StyledCardContent = styled(CardLayout)`
  flex: 1;
`;
export const StyledCardFooter = styled(CardActions)`
  padding: ${setSpacing(1.5)} ${setSpacing(2)};
`;

// Card media components
export const SchoolPhoto = styled(MuiCardMedia)`
  width: 100%;
  height: ${setSpacing(40)};

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.theme.palette.grey[300]};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  svg {
    width: auto;
    height: ${setSpacing(13)};
  }
`;

// Chips components
// Status chips

export const ExpandButton = styled(IconButton)`
  transition: ${setTransition()};
  &.expandOpen {
    transform: rotate(180deg);
  }
`;

export const ResponsiveCenteredImage = styled("img")`
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
