import { useEffect, useState } from "react";
import { Field } from "../../styledComponents/StyledForms";

import { getHelperText, getLabel, handleSubmit, parseValue, handleFieldData } from "../actions";
import counterpart from "counterpart";
import { TODO_ANY } from "types";

export const TextareaField = ({ InputProps, inputProps = {}, color, focused, ...formProps }: TODO_ANY) => {
  const {
    id,
    type,
    label,
    name,
    value,
    required,
    error,
    hideLabel,
    disabled,
    helperText,
    marginDisabled,
    handleChanges,
    clearErrors,
    submit,
    formName,
    minRows = 2,
    maxRows,
    placeholderI18n,
    placeholder,
    arrayId,
    helperTextData,
    ...otherProps
  } = handleFieldData(formProps);
  const [currentValue, setCurrentValue] = useState(value || "");

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleField = async (e: KeyboardEvent) => {
    const target = e.target as HTMLTextAreaElement | HTMLInputElement;
    const parsedValue = parseValue(target.value);
    return handleChanges({ target: { name: target.name, value: parsedValue, id, rowIndex: arrayId } });
  };

  inputProps.onKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode !== 13) handleSubmit(e, handleField, submit);
  };

  const fullFieldName = `${formName}.${name}`;

  return (
    <Field
      multiline
      minRows={minRows}
      maxRows={maxRows}
      id={id}
      type={type}
      name={name}
      value={currentValue}
      label={getLabel(name, label, hideLabel)}
      helperText={getHelperText(error, fullFieldName, helperText, helperTextData)}
      placeholder={placeholderI18n ? counterpart.translate(placeholderI18n) : placeholder}
      error={Boolean(error)}
      disabled={disabled}
      required={required}
      onChange={(e) => {
        clearErrors && clearErrors(e);
        setCurrentValue(e.target.value);
      }}
      onBlur={handleField}
      inputProps={inputProps}
      margin={marginDisabled ? "none" : "normal"}
      variant="outlined"
      fullWidth
      color={color}
      focused={focused}
      InputProps={InputProps}
      {...otherProps}
    />
  );
};
