/* eslint-disable  @typescript-eslint/no-explicit-any */
import { QueryClient, UseQueryOptions, UseMutationOptions } from "react-query";
import FormError from "services/formError";

export const isInProduction = process.env.NODE_ENV === "production";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // useErrorBoundary:true,
      // mark queries as stale after 20 seconds
      // staleTime: 1000 * 20,
      retry: false,
      refetchOnWindowFocus: isInProduction,
      notifyOnChangeProps: "tracked",
    },
  },
});

export type ExtractFnReturnType<FnType extends (...args: any) => any> = Awaited<ReturnType<FnType>>;

export type QueryConfig<QueryFnType extends (...args: any) => any> = {
  config?: Omit<UseQueryOptions<ExtractFnReturnType<QueryFnType>, FormError>, "queryKey" | "queryFn">;
};

export type MutationConfig<MutationFnType extends (...args: any) => any> = UseMutationOptions<
  ExtractFnReturnType<MutationFnType>,
  FormError,
  Parameters<MutationFnType>[0]
>;
