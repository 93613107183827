// modal constants
export const SET_MODAL = "SET_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const REMOVE_MODAL_CONTENT = "REMOVE_MODAL_CONTENT";

export const SET_UPD_FLAG = "SET_UPD_FLAG";
export const REMOVE_UPD_FLAG = "REMOVE_UPD_FLAG";

// Learner Transcript
export const SET_TRANSCRIPT = "SET_TRANSCRIPT";
export const UPDATE_CREDITS = "UPDATE_CREDITS";
export const UPDATE_TRANSCRIPT = "UPDATE_TRANSCRIPT";
export const REMOVE_TRANSCRIPT = "REMOVE_TRANSCRIPT";

// Popup
export const SHOW_POPUP = "SHOW_POPUP";
export const HIDE_POPUP = "HIDE_POPUP";

export const START = "_START";
export const SUCCESS = "_SUCCESS";
export const FAIL = "_FAIL";

// Evidence
export const SET_EVIDENCES = "SET_EVIDENCES";
export const REMOVE_EVIDENCES = "REMOVE_EVIDENCES";
export const UPDATE_EVIDENCE = "UPDATE_EVIDENCE";

export const UPDATE_EVIDENCE_IMAGE = "UPDATE_EVIDENCE_IMAGE";
export const DELETE_EVIDENCE_IMAGE = "DELETE_EVIDENCE_IMAGE";
export const ADD_EVIDENCE_IMAGE = "ADD_EVIDENCE_IMAGE";

export const SET_EVIDENCE_CREDIT = "SET_EVIDENCE_CREDIT";
export const SET_EVIDENCE_CREDITS = "SET_EVIDENCE_CREDITS";
export const DELETE_EVIDENCE_CREDIT = "DELETE_EVIDENCE_CREDIT";
export const UPDATE_EVIDENCE_CREDIT = "UPDATE_EVIDENCE_CREDIT";

export const ADD_EVIDENCE_COMMENT = "ADD_EVIDENCE_COMMENT";
export const UPDATE_EVIDENCE_COMMENT = "UPDATE_EVIDENCE_COMMENT";
export const DELETE_EVIDENCE_COMMENT = "DELETE_EVIDENCE_COMMENT";
export const INCLUDE_IN_TRANSCRIPT_EVIDENCE_COMMENT = "INCLUDE_IN_TRANSCRIPT_EVIDENCE_COMMENT";
export const ASSIGN_COURSE_TO_EVIDENCE = "ASSIGN_COURSE_TO_EVIDENCE";
export const DELETE_EVIDENCE_COURSE_ASSIGNMENT = "DELETE_EVIDENCE_COURSE_ASSIGNMENT";
export const UPDATE_EVIDENCE_COURSE_DETAILS = "UPDATE_EVIDENCE_COURSE_DETAILS";
