import _ from "lodash";
import { createAsyncThunk, createEntityAdapter, createSlice, createSelector } from "@reduxjs/toolkit";
import conversationsService from "services/conversationsService";
import { selectAllMessages, sendMessage } from "./messagesSlice";
import {
  createConversation,
  fetchConversationById,
  fetchConversations,
  fetchMoreConversations,
  fetchMoreMessages,
  STATE_KEY,
} from "./sharedConversationsActions";
import { dispatchErrorPopup } from "redux/actions/popupActions";
import { closeModal } from "redux/actions/modalActions";

export { STATE_KEY };

export const updateConversation = createAsyncThunk(
  `${STATE_KEY}/updateConversation`,
  async ({ conversationId, subject, users }, { rejectWithValue }) => {
    try {
      const userIds = _.map(users, "id");
      await conversationsService.editConversation(conversationId, { subject, userIds });

      closeModal();

      return {
        id: conversationId,
        changes: { subject, participants: userIds },
      };
    } catch (error) {
      dispatchErrorPopup(error);
      return rejectWithValue(error);
    }
  }
);

export const leaveConversation = createAsyncThunk(
  `${STATE_KEY}/leaveConversation`,
  async (conversationId, { getState, rejectWithValue }) => {
    try {
      const { account, conversations } = getState();
      const { participants: participantIds } = conversations.entities[conversationId];
      const userIds = _.without(participantIds, account.id);

      await conversationsService.editConversation(conversationId, { userIds });
      return conversationId;
    } catch (error) {
      dispatchErrorPopup(error);
      return rejectWithValue(error);
    }
  }
);

export const fetchUnreadConversationsCount = createAsyncThunk(`${STATE_KEY}/fetchUnreadCount`, async () => {
  return conversationsService.getUnreadConversationsCount();
});

const conversationsAdapter = createEntityAdapter();

const initialState = conversationsAdapter.getInitialState({
  status: "idle",
  error: null,
  loading: false,
  loadingMore: false,
  conversationStatus: "idle",
  conversationError: null,
  unreadCount: 0,
  page: 1,
  limit: 20,
  total: 0,
});

const conversationsSlice = createSlice({
  name: STATE_KEY,
  initialState,
  extraReducers: (builder) =>
    builder
      .addCase(fetchUnreadConversationsCount.fulfilled, (state, action) => {
        state.unreadCount = action.payload;
      })
      .addCase(fetchConversations.pending, (state) => {
        state.conversationStatus = "loading";
      })
      .addCase(fetchConversations.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        const { entities, count } = action.payload;
        const { conversations } = entities;
        if (conversations) {
          conversationsAdapter.upsertMany(state, conversations);
          state.total = count.allItemsWithoutFilters;
          state.page = 1;
        }
      })
      .addCase(fetchConversations.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.code;
      })
      .addCase(fetchMoreConversations.pending, (state) => {
        state.loadingMore = true;
      })
      .addCase(fetchMoreConversations.fulfilled, (state, action) => {
        const { entities, count } = action.payload;
        const { conversations } = entities;
        if (conversations) {
          state.page += 1;
          conversationsAdapter.upsertMany(state, conversations);
          state.total = count.allItemsWithoutFilters;
        }
        state.loadingMore = false;
      })
      .addCase(fetchMoreConversations.rejected, (state, action) => {
        state.loadingMore = false;
        state.error = action.error.code;
      })
      .addCase(fetchConversationById.pending, (state) => {
        state.conversationStatus = "loading";
      })
      .addCase(fetchConversationById.fulfilled, (state, action) => {
        const { payload, meta } = action;
        const { conversationId } = meta.arg;
        const { hasUnreadMessages } = state.entities[conversationId] || {};

        state.conversationStatus = "succeeded";
        state.conversationError = null;

        conversationsAdapter.upsertMany(state, payload.entities.conversations);
        if (hasUnreadMessages) {
          state.entities[conversationId].hasUnreadMessages = false;
          if (state.unreadCount > 0) {
            state.unreadCount -= 1;
          }
        }
        state.entities[conversationId].page = 1;
        state.entities[conversationId].messagesStatus = "succeeded";
        state.entities[conversationId].total = payload.count.allItemsWithoutFilters;
      })
      .addCase(fetchConversationById.rejected, (state, action) => {
        state.conversationStatus = "failed";
        state.conversationError = action.error.code;
      })
      .addCase(fetchMoreMessages.pending, (state, action) => {
        const { conversationId } = action.meta.arg;
        state.entities[conversationId].messagesStatus = "loading";
      })
      .addCase(fetchMoreMessages.fulfilled, (state, action) => {
        const { payload, meta } = action;
        const { conversationId } = meta.arg;
        const { messages } = state.entities[conversationId];
        const { conversations } = payload.entities;

        state.conversationStatus = "succeeded";
        state.conversationError = null;

        conversationsAdapter.upsertMany(state, conversations);

        state.entities[conversationId].page += 1;
        state.entities[conversationId].messagesStatus = "succeeded";
        state.entities[conversationId].messagesError = null;
        state.entities[conversationId].messages = _.union(messages, conversations[conversationId].messages);
        state.entities[conversationId].total = payload.count.allItemsWithoutFilters;
      })
      .addCase(fetchMoreMessages.rejected, (state, action) => {
        const { conversationId } = action.meta.arg;
        state.entities[conversationId].messagesError = action.payload.code;
        state.entities[conversationId].messagesStatus = "failed";
      })
      .addCase(updateConversation.fulfilled, conversationsAdapter.updateOne)
      .addCase(createConversation.fulfilled, (state, action) => {
        conversationsAdapter.upsertMany(state, action.payload.entities.conversations);
        state.total += 1;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        const { conversationId } = action.meta.arg;
        const messageId = action.payload.id;
        state.entities[conversationId].messages.unshift(messageId);
      })
      .addCase(leaveConversation.fulfilled, (state, action) => {
        conversationsAdapter.removeOne(state, action.meta.arg);
        state.total -= 1;
      }),
});

export const selectConversationsSlice = (state) => state[STATE_KEY];

export const { selectById: selectConversationById, selectAll } =
  conversationsAdapter.getSelectors(selectConversationsSlice);

export const selectConversationsSortedByLatestMessage = createSelector(
  [selectAll, selectAllMessages],
  (conversations, messages) => {
    const denormalizedConversation = conversations.map((conversation) => ({
      ...conversation,
      messages: conversation.messages.map((messageId) => messages[messageId]),
    }));

    // sort by latest added message or by latest conversation, newest at the top
    return _.orderBy(
      denormalizedConversation,
      [
        ({ messages, createdAt }) => {
          const latestMessageDate = _.maxBy(messages, ({ createdAt }) => new Date(createdAt));
          return latestMessageDate?.createdAt || createdAt;
        },
      ],
      "desc"
    );
  }
);
export const selectUnreadConversationCount = (state) => state[STATE_KEY].unreadCount;

export default conversationsSlice.reducer;
