import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem } from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { StyledSchoolMenu } from "./styles";
import { activeSchoolChanged } from "redux/slices/accountSlice";

function ChangeCurrentSchoolMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { schools, schoolId } = useSelector((state) => state.account);

  const handleSchoolSelect = (school) => {
    dispatch(activeSchoolChanged({ schoolId: school.id, navigate }));
  };

  const currentSchool = schools.find((school) => school.id === schoolId);

  return (
    <StyledSchoolMenu
      id="schoolName"
      select
      variant="outlined"
      onChange={({ target }) => handleSchoolSelect(target.value)}
      value={currentSchool}
      SelectProps={{ IconComponent: ExpandMoreIcon }}
      data-test-id="changeSchool"
    >
      {schools.map((school) => (
        <MenuItem key={school.id} value={school}>
          {school.name}
        </MenuItem>
      ))}
    </StyledSchoolMenu>
  );
}

export { ChangeCurrentSchoolMenu };
