import { MenuItem, Table, TableBody, TableHead, TableRow, Select, Checkbox, TableCell } from "@mui/material";
import Translate from "react-translate-component";
import React from "react";
import { Caption } from "components/typography";
import { StyledCollegeNudgePopupTable } from "features/CollegeNudges/components/styles";
import { LearnerCollegeStatus } from "services/types";

export const CollegeNudgePopupTable = ({ items = [], onChange }) => {
  const statusOptions = Object.values(LearnerCollegeStatus).map((el) => (
    <MenuItem key={el} value={el}>
      <Translate content={`learnerCollegeStatus.${el}`} />
    </MenuItem>
  ));

  const tableKeys = [
    {
      key: "name",
      tag: "formLabels.name",
    },
    {
      key: "status",
      tag: "formLabels.status",
      handleData: ({ id, status }) => (
        <Select
          value={status}
          onChange={({ target }) => onChange({ id, status: target.value })}
          inputProps={{ "aria-label": "Without label" }}
          displayEmpty
          size="small"
          variant="standard"
          MenuProps={{ disableScrollLock: true }}
        >
          {statusOptions}
        </Select>
      ),
    },
    {
      key: "accepted",
      tag: "formLabels.accepted",
      handleData: ({ id, accepted }) => (
        <Checkbox
          checked={accepted}
          size="small"
          onChange={({ target }) => onChange({ id, accepted: target.checked })}
        />
      ),
      params: { align: "center", style: { minWidth: "80px" } },
    },
  ];

  return (
    <StyledCollegeNudgePopupTable>
      <Table aria-label="college applications" size="small" stickyHeader>
        <TableHead>
          <TableRow>
            {tableKeys.map((el) => (
              <TableCell key={el.key} {...el.params}>
                <Caption content={el.tag} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
            <TableRow key={row.id}>
              {tableKeys.map((column) => (
                <TableCell key={`cell-${row.id}-${column.key}`} scope="row" {...column.params}>
                  {column.handleData ? column.handleData(row) : row[column.key]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledCollegeNudgePopupTable>
  );
};
