import { H1 } from "../../typography";
import { TranslateProps } from "react-translate-component";
import { TypographyProps } from "@mui/material";

type ModalTitleProps = {
  tag: string;
  additionalData?: TranslateProps["with"];
  align?: TypographyProps["align"];
  mb?: number;
};

export const ModalTitle = ({ tag, additionalData, mb = 7, align = "center" }: ModalTitleProps) => (
  <H1 id="simple-dialog-title" align={align} content={tag} with={additionalData} mb={mb} />
);
