import { styled } from "@mui/material/styles";
import Translate, { TranslateProps } from "react-translate-component";
import { Box, Typography as MuiTypography, TypographyProps as MuiTypographyProps } from "@mui/material";
import React from "react";

type TypographyProps = MuiTypographyProps & {
  component?: React.ElementType;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  isBold?: boolean;
  startIconMargin?: number;
};

type TextProps =
  | (Pick<TranslateProps, "content" | "with"> & { text?: never })
  | { text: React.ReactNode; content?: never; with?: never }
  | ({ children: React.ReactNode; text?: never } & Partial<Pick<TranslateProps, "content" | "with">>);

type BodyTypographyProps = TypographyProps & TextProps;

type MultilineTrimmedTextProps = BodyTypographyProps & {
  lines?: number;
  maxHeight?: number;
};

const IconWrapper = styled(Box)`
  svg {
    vertical-align: middle;
  }
`;

export const Typography = ({
  content,
  with: additionalData,
  text,
  startIcon,
  endIcon,
  children,
  startIconMargin,
  ...restProps
}: BodyTypographyProps) => (
  <MuiTypography {...restProps}>
    {startIcon && (
      <IconWrapper mr={startIconMargin || 2} component="span">
        {startIcon}
      </IconWrapper>
    )}
    {children || (text || text === 0 ? text : <Translate content={content || ""} with={additionalData} />)}
    {endIcon && (
      <IconWrapper ml={2} component="span">
        {endIcon}
      </IconWrapper>
    )}
  </MuiTypography>
);

export const H1 = (props: BodyTypographyProps) => <Typography component="h1" variant="h1" {...props} />;
export const H2 = (props: BodyTypographyProps) => <Typography component="h2" variant="h2" {...props} />;
export const H3 = (props: BodyTypographyProps) => <Typography component="h3" variant="h3" {...props} />;
export const H4 = (props: BodyTypographyProps) => <Typography component="h4" variant="h4" {...props} />;
export const H5 = (props: BodyTypographyProps) => <Typography component="h5" variant="h5" {...props} />;
export const H6 = (props: BodyTypographyProps) => <Typography component="h6" variant="h6" {...props} />;

export const BodyTypography = ({ component = "div", isBold, style, ...props }: BodyTypographyProps) => (
  <Typography
    {...props}
    component={component}
    style={{
      fontWeight: isBold ? 500 : 400,
      whiteSpace: props.noWrap ? "nowrap" : "pre-wrap",
      wordWrap: "break-word",
      ...style,
    }}
  />
);

export const Body1 = (props: BodyTypographyProps) => <BodyTypography {...props} variant="body1" />;
export const Body2 = (props: BodyTypographyProps) => <BodyTypography {...props} variant="body2" />;
export const Caption = (props: BodyTypographyProps) => <BodyTypography {...props} variant="caption" />;

const StyledMultilineTrimmedText = styled(Box)<{ lines: number; maxHeight: number }>`
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: ${(props) => props.lines};
  -webkit-box-orient: vertical;
  max-height: ${(props) => props.maxHeight}px;
`;

export const MultilineTrimmedText = ({ lines = 2, maxHeight = 40, ...props }: MultilineTrimmedTextProps) => {
  return (
    <StyledMultilineTrimmedText lines={lines} maxHeight={maxHeight}>
      <BodyTypography {...props} />
    </StyledMultilineTrimmedText>
  );
};
