import { useDispatch, useSelector } from "react-redux";
import { hidePopup } from "redux/actions/popupActions";
import { IconButton, Snackbar } from "@mui/material";
import { CloseIcon } from "svg";
import { StyledAlert } from "./styles";
import counterpart from "counterpart";

/**
 * According to https://m2.material.io/components/snackbars#behavior
 * min time 4s, max time 10s
 * @param text
 * @returns {number}
 */
const getAutoHideDurationTime = (text) => {
  const textLength = text.length;

  if (textLength >= 250) {
    return 10000;
  }

  if (textLength >= 200) {
    return 8000;
  }

  if (textLength >= 150) {
    return 6000;
  }

  return 4000;
};

const Notification = (props) => {
  const dispatch = useDispatch();
  const popup = useSelector((state) => state.popup);
  const { popupType, content, open } = popup;

  if (!open) return null;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(hidePopup());
  };

  const { tag, additionalData } = content;

  const text = counterpart.translate(tag, additionalData);

  return (
    <Snackbar
      sx={{ maxWidth: 800 }}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      autoHideDuration={getAutoHideDurationTime(text)}
      onClose={handleClose}
      transitionDuration={400}
      action={
        <IconButton aria-label={counterpart.translate("buttons.closePopup")}>
          <CloseIcon />
        </IconButton>
      }
      {...props}
    >
      <StyledAlert variant="filled" severity={popupType} onClose={handleClose} data-test-id={popupType}>
        {text}
      </StyledAlert>
    </Snackbar>
  );
};

export { Notification };
