import BaseService from "services/baseService";
import FetchService from "services/fetchService";

class SystemService extends BaseService {
  checkServerStatus() {
    return FetchService.get({
      url: this.getBaseUrl(),
    });
  }
}

export default new SystemService();
