/* eslint-disable */
// ! Need to rewrite a lot to enable eslint
import { Component } from "react";
import counterpart from "counterpart";
import clsx from "clsx";

import { StyledSearchBar, StyledSearchBarInput, StyledSearchBarIcon } from "../styles";
import { SearchIcon, RemoveIcon } from "svg";
import { Typography } from "../../typography";
import { visuallyHidden } from "@mui/utils";

class SearchInput extends Component {
  state = {
    value: this.props.value || "",
    timeout: 0,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value === this.props.value) return;
    this.setState({ value: this.props.value });
  }

  onChange = (e) => {
    const { value } = e.target;
    const prevTimeout = this.state.timeout;

    if (prevTimeout) clearTimeout(prevTimeout);

    const timeout = setTimeout(() => {
      this.props.onChange(value);
    }, 250);

    this.setState({ value, timeout });
  };

  handleSubmit = () => {
    const { timeout: prevTimeout, value } = this.state;

    if (prevTimeout) clearTimeout(prevTimeout);
    this.props.onChange(value);
  };

  handleClearClick = (e) => {
    e.preventDefault();
    if (!this.state.value) return;
    this.clearSearch();
  };

  handleKeyup = (e) => {
    if (e.key === "Escape") {
      this.clearSearch();
    }
  };

  clearSearch() {
    this.setState({ value: "", timeout: 0 }, () => this.props.onChange(this.state.value));
  }

  render() {
    const { placeholder = "Search", autoFocus } = this.props;
    const { value = "" } = this.state;

    return (
      <StyledSearchBar>
        <Typography style={visuallyHidden} content="buttons.search" />
        <StyledSearchBarIcon onClick={this.handleSubmit} aria-label={counterpart.translate("buttons.search")}>
          <SearchIcon fontSize="small" />
        </StyledSearchBarIcon>
        <StyledSearchBarInput
          name="search"
          placeholder={placeholder}
          value={value}
          onChange={this.onChange}
          onKeyUp={this.handleKeyup}
          autoFocus={autoFocus}
        />
        <StyledSearchBarIcon
          onClick={this.handleClearClick}
          className={clsx({ active: value, clear: true })}
          tabIndex={value ? 0 : -1}
          aria-label={counterpart.translate("buttons.clearSearch")}
        >
          <RemoveIcon fontSize="small" />
        </StyledSearchBarIcon>
      </StyledSearchBar>
    );
  }
}

export { SearchInput };
